import React, { useState } from "react";
import {
  faStore,
  faTruck,
  faBox,
  faPercent,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import css style sheets
import "./BBCardDeepDives.css";

export default function BBJobsToBeDone({
  chosenKnowledgeBaseItem,
  handleAddToBuildDeck,
}) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <img src={chosenKnowledgeBaseItem.imageUrl} className='imageStyling' />

      <h2 className='cardDeepDiveTitle'>Step Description</h2>
      <div className='cardDeepDiveCard'>
        <p>{chosenKnowledgeBaseItem.cardDescription}</p>
        <div
          onClick={() =>
            handleAddToBuildDeck(
              chosenKnowledgeBaseItem,
              chosenKnowledgeBaseItem.cardDescription,
              "description"
            )
          }
          className='addToDeckPlusButton'
        >
          +
        </div>
      </div>
      <h2 className='cardDeepDiveTitle'>How Might We Questions (HMW)</h2>
      {chosenKnowledgeBaseItem.HMW.map((item, index) => (
        <div className='cardDeepDiveCard'>
          <p>{item}</p>
          <div
            className='addToDeckPlusButton'
            onClick={() =>
              handleAddToBuildDeck(chosenKnowledgeBaseItem, item, "HMW", index)
            }
          >
            +
          </div>
        </div>
      ))}
    </div>
  );
}
