// import image assets

import HowRAGWorks from "../../assets/AiCapabilities/HowRAGWorks.jpg";
import HowLLMsWork from "../../assets/AiCapabilities/HowLLMsWork.jpg";
import HowAgentsWork from "../../assets/AiCapabilities/HowAgentsWork.png";
import HowLipSyncingWorks from "../../assets/AiCapabilities/HowLipSyncingWorks.png";
import AICreativeWriting from "../../assets/AiCapabilities/AICreativeWriting.png";
import AIFineTuning from "../../assets/AiCapabilities/AIFineTuning.png";
import AIVision from "../../assets/AiCapabilities/AIVision.png";

import retailer5 from "../../assets/webpKB/retailer5.webp";
import retailer6 from "../../assets/webpKB/retailer6.webp";
import retailer7 from "../../assets/webpKB/retailer7.webp";
import retailer8 from "../../assets/webpKB/retailer8.webp";
import retailer9 from "../../assets/webpKB/retailer9.webp";
import retailer10 from "../../assets/webpKB/retailer10.webp";

const aiCapabilitiesIntro = {
  title: "Generative AI Capabilities",
  description: [
    "If we want to create added value for grocery retailers and food wholesalers, we have to understand their ambition. Take a look at the biggest retailers and wholesalers in our major target countries and try to identify how our Cheez-It brand can create added value to help them achieve their ambitions.",
  ],
};

const aiCapabilities = [
  {
    cardTitle: "How LLMs Like GPT Work",
    cardType: "Text-To-Text",
    cardSubTitle: "How do LLMs like ChatGPT work?",
    imageUrl: HowLLMsWork,
    deepDiveTopics: [
      {
        topic: "Technology Recap",
        subTopics: [
          "Monopoly is a popular board game centered around money management, real estate, and strategic investment. The objective of the game is to remain financially solvent while forcing opponents into bankruptcy by buying and developing pieces of property.",
        ],
      },
      {
        topic: "Technology Tools",
        subTopics: [
          "1.Setup: Each player starts with a fixed amount of money. The game board consists of spaces, each representing properties, utilities, taxes, and various events (like Chance and Community Chest cards).",
          "2. Gameplay: Players take turns rolling two six-sided dice to move around the board, buying or trading properties, and developing them with houses and hotels. Rent is collected from opponents who land on the player’s properties, and the amount varies depending on how much the property is developed.",
          "3. Bankruptcy: Players can go bankrupt if they cannot pay their debts, either to other players or to the Bank, and must sell off assets or mortgage properties. If they cannot cover the debt even then, they are out of the game.",
        ],
      },
      {
        topic: "Game Dynamics",
        subTopics: [
          "1.Trading: Players can trade properties among themselves at negotiated prices, which adds a layer of strategy and negotiation.",
          "2. Chance and Community Chest: These cards can either benefit the player (like receiving money or free properties) or cause setbacks (like paying fines or going to jail).",
          "3. Jail: Players can be sent to jail and must either pay a fine, use a “Get Out of Jail Free” card, or roll doubles on the dice to escape.",
        ],
      },
      {
        topic: "Game Objectives",
        subTopics: [
          "1.Monopolize: Owning all the properties in a single color group allows a player to build houses and hotels, which greatly increases the rent other players must pay when they land there.",
          "2.Manage Assets: Players must balance the development of properties with having enough cash on hand to pay rent and fines.",
          "3.Strategic Investment: Deciding where and when to invest, either by buying or upgrading properties or holding cash to remain solvent when landing on opponents’ properties.",
        ],
      },
    ],
  },
];

export { aiCapabilitiesIntro, aiCapabilities };
