import React, { useState, useEffect, useRef } from "react";
import { auth, db, storage } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

//import bootstrap components
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

// import fontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faFilter,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";

// import image assets
import placeholderImageNeeds from "../../assets/ImagePlaceholder.png";
import { use } from "i18next";
import { set } from "lodash";

export default function BBSolutionSharingModal({
  solutionsData,
  setSolutionsData,
  solutionModalShow,
  setSolutionModalShow,
  playersData,
  buildDeckContent,
  setBuildDeckVisible,
  solutionModalFromBuildDeck,
  customTheme,
}) {
  const [newSolution, setNewSolution] = useState({
    topic: {
      customerExperience: false,
      internalWorkflow: false,
    },
    ideaTitle: "",
    ideaDescription: "",
    trl: "",
  });
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [errorIsLoadingSuggestions, setErrorIsLoadingSuggestions] =
    useState(false);
  const textAreaRef = useRef(null);

  // prop type validations

  /* BlackboxCISolutionSharingModal.propTypes = {
    solutionsData: PropTypes.array.isRequired,
    // ... other props validation
  };*/

  // handle functions
  let apiContextData = [];

  const handleOpenAIRequest = async (e) => {
    e.preventDefault();
    buildDeckContent.map((item) => {
      if (item.empty === false && item.type === "HMW") {
        apiContextData.push(
          `Please make sure your idea tries to addresses the following HMW: ${item.subTitle}`
        );
      }
    });
    const configuration = new Configuration({
      organization: "org-h1bJK5iOzVXDfN6bVEer5gaP",
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    try {
      const openai = new OpenAIApi(configuration);
      const finalMessage = `

      We are a board game company with the goal of creating innovative and engaging board games. We are looking for new ideas that can help us achieve this goal. Can leverage Generative AI technology to do so?
      
      please provide me a response in JSON format with the following keys and values:

          {
            "ideaTitle": "${newSolution.ideaTitle}",  /* keep this title as is in your response*/  
            "ideaDescription": "${newSolution.ideaDescription}",
            "trl": "${newSolution.trl}",
          }

          Make sure that the ideaDescription gives a clear explanation why the idea has the title "${newSolution.ideaTitle}" . If the title is an empty string, please generate a creative title for me.

          Furthermore, in the trl part, clearly describe which technologies you can use in order to achieve the idea. 

          Here's an example:

          {
            "ideaTitle": "GenAI to make Monopoly More Dynamic",  
            "ideaDescription": "<p>Use LLMs like OpenAI's GPT to make a board game like monopoly less repetitive by incorporating dynamic house prices based on fictional events.</p>",
            "trl": "<p><ul><li>Let's use OPenAI's vision model to analyse the version of Monopoly.</li><li>Let's use OpenAI's GPT-4 model to create fictional news events and the effect it has on house prizes and rent.</li><li>Let's use Dall-e to create a relevant photo for each news event.</li></ul> All data is of-the-shelf and can be tested with a POC in a matter of weeks.</p>",
          }

          !important!!: ${apiContextData}

          also, whenever I have ...  in my text, make sure to complete the sentence. 

          Also, whenever I have (such as ...) in my text, make sure to add a list of relevant examples.

          Furthermore, whenever I have (e.g. ...) in my text, make sure to add a list of relevant examples.

          Finally, very Important. Always return me a completed JSON object without any additional text. The "ideaDescription" and "trl" keys should have a value of at least 50 characters. The ideaTitle should at least have 8 characters. You should meet these requirments, otherwise I will not be able to submit your idea. 
            `;
      const completion = await openai.createChatCompletion({
        model: "gpt-4-turbo-preview",
        messages: [{ role: "user", content: finalMessage }],
      });
      const AIresponse = completion.data.choices[0].message.content;
      const jsonObjectOfAIresponse = JSON.parse(AIresponse);
      console.log(
        "message sent",
        finalMessage,
        "jsonObjectOfAIresponse",
        jsonObjectOfAIresponse
      );

      setTimeout(() => {
        setNewSolution((prevState) => ({
          ...prevState,
          ideaTitle: jsonObjectOfAIresponse.ideaTitle,
        }));

        setTimeout(() => {
          setNewSolution((prevState) => ({
            ...prevState,
            ideaDescription: jsonObjectOfAIresponse.ideaDescription,
          }));

          setTimeout(() => {
            setNewSolution((prevState) => ({
              ...prevState,
              trl: jsonObjectOfAIresponse.trl,
            }));
          }, 100); // waits 0.1 second before executing the third update
        }, 100); // waits 0.1 second before executing the second update
      }, 100); // waits 0.1 second before executing the first update
      setIsLoadingSuggestions(false);
    } catch (error) {
      console.error("Error:", error);
      setErrorIsLoadingSuggestions(true);
      setIsLoadingSuggestions(false);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
    }
  };

  const handleTopicChange = (event) => {
    setNewSolution((prevState) => ({
      ...prevState,
      topic: {
        ...prevState.topic,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleSolutionInputChange = (event) => {
    setNewSolution({ ...newSolution, [event.target.name]: event.target.value });
  };

  const handleQuillChange = (content, fieldName) => {
    setNewSolution({ ...newSolution, [fieldName]: content });
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [imageFileToUpload, setImageFileToUpload] = useState("");

  const handleSolutionSubmit = async () => {
    let imageFileURL = placeholderImageNeeds; // Default to the current image value.

    // First, check if there's a selected file and upload it to Firebase Storage.
    if (selectedFile) {
      const storageRef = ref(storage, `solutionsImages/${selectedFile.name}`);
      setUploading(true);

      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress updates if needed
        },
        (error) => {
          setUploading(false);
          console.error("Error uploading file:", error);
        },
        async () => {
          try {
            imageFileURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUploading(false);

            // Create and push solutionData after the file upload is complete
            const userId = auth.currentUser.uid;
            let solutionData = {};

            if (
              solutionModalFromBuildDeck &&
              buildDeckContent.some((item) => item.empty === false)
            ) {
              solutionData = {
                ...newSolution,
                image: imageFileURL, // Set the image URL here.
                userId,
                inspiration: buildDeckContent.filter(
                  (item) => item.empty === false
                ),
                argumentsVisible: false,
                creationDate: new Date(),
              };
            } else {
              solutionData = {
                ...newSolution,
                image: imageFileURL, // Set the image URL here.
                userId,
                argumentsVisible: false,
                creationDate: new Date(),
              };
            }

            // Push to Firebase
            let newDocRef = null;
            try {
              const solutionsCollection = collection(db, "solutions");
              newDocRef = await addDoc(solutionsCollection, solutionData);
            } catch (e) {
              console.log(
                "error message when trying to store solution",
                e.message
              );
            }

            if (newDocRef) {
              // Get the ID of the newly created solution
              const solutionId = newDocRef.id;

              // Add the solutionId to the solutionData object
              const completeSolutionData = {
                ...solutionData,
                solutionId: solutionId,
              };

              // Update local state with the complete data
              if (Array.isArray(solutionsData)) {
                setSolutionsData([...solutionsData, completeSolutionData]);
              } else {
                console.error("solutionsData is not an array:", solutionsData);
                setSolutionsData([completeSolutionData]); // Default to just the new solution data if the existing data is not iterable.
              }
            }

            setSelectedFile(null);
            setSolutionModalShow(false);
            setBuildDeckVisible(false);
          } catch (error) {
            setUploading(false);
            console.error("Error getting download URL:", error);
          }
        }
      );
    } else {
      // Create and push solutionData without file upload
      const userId = auth.currentUser.uid;
      let solutionData = {};

      if (
        solutionModalFromBuildDeck &&
        buildDeckContent.some((item) => item.empty === false)
      ) {
        solutionData = {
          ...newSolution,
          image: imageFileURL, // Set the image URL here.
          userId,
          inspiration: buildDeckContent.filter((item) => item.empty === false),
          argumentsVisible: false,
          creationDate: new Date(),
        };
      } else {
        solutionData = {
          ...newSolution,
          image: imageFileURL, // Set the image URL here.
          userId,
          argumentsVisible: false,
          creationDate: new Date(),
        };
      }

      // Push to Firebase
      let newDocRef = null;
      try {
        const solutionsCollection = collection(db, "solutions");
        newDocRef = await addDoc(solutionsCollection, solutionData);
      } catch (e) {
        console.log("error message when trying to store solution", e.message);
      }

      if (newDocRef) {
        // Get the ID of the newly created solution
        const solutionId = newDocRef.id;

        // Add the solutionId to the solutionData object
        const completeSolutionData = {
          ...solutionData,
          solutionId: solutionId,
        };

        // Update local state with the complete data
        if (Array.isArray(solutionsData)) {
          setSolutionsData([...solutionsData, completeSolutionData]);
        } else {
          console.error("solutionsData is not an array:", solutionsData);
          setSolutionsData([completeSolutionData]); // Default to just the new solution data if the existing data is not iterable.
        }
      }

      setSolutionModalShow(false);
      setBuildDeckVisible(false);
    }
  };

  const getAudiences = () => {
    const audiences = solutionsData.map((item) => item.audience);
    // Convert challenges array to Set to get unique values, then convert it back to array
    const uniqueAudiences = [...new Set(audiences)];
    return uniqueAudiences;
  };

  return (
    <Modal
      show={solutionModalShow}
      onHide={() => setSolutionModalShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='BlackboxCISolutions-shareSolutionsModal'
    >
      <Modal.Header
        closeButton
        className={
          customTheme
            ? "BlackboxCISolutions-shareSolutionsModal-header customTheme"
            : "BlackboxCISolutions-shareSolutionsModal-header"
        }
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Share Your Idea
        </Modal.Title>
        <Button
          className='randomSolutionGeneratorButton'
          onClick={handleOpenAIRequest}
          disabled={isLoadingSuggestions}
        >
          {isLoadingSuggestions ? (
            <Spinner className='loadingSpinner' animation='border' size='sm' />
          ) : (
            <FontAwesomeIcon
              className='randomSolutionGeneratorButtonIcon'
              icon={faRobot}
            />
          )}{" "}
          <span>Auto-Complete</span>
        </Button>
      </Modal.Header>
      <Modal.Body className='BlackboxCISolutions-shareSolutionsModal-body'>
        <Form>
          {solutionModalFromBuildDeck &&
            buildDeckContent.some((item) => item.empty === false) && (
              <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection'>
                <h4>Build on:</h4>
                <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items'>
                  {buildDeckContent
                    .filter((item) => item.empty === false)
                    .map((item, index) => (
                      <div className='BlackboxCISolutions-shareSolutionsModal-body-inspiredBySection-items-item'>
                        <img src={item.imageUrl} />
                        <div>{item.title}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Relevant Challenge(s)
            </Form.Label>
            <div className='BlackboxCISolutions-shareSolutionsModal-body-topics'>
              <Form.Check
                inline
                label='Customer Experience'
                name='customerExperience'
                type='checkbox'
                checked={newSolution.topic.marketing}
                onChange={handleTopicChange}
                className='customCheckbox'
              />
              <Form.Check
                inline
                label='Internal Workflow'
                name='internalWorkflow'
                type='checkbox'
                checked={newSolution.topic.product}
                onChange={handleTopicChange}
                className='customCheckbox'
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              Idea Title
            </Form.Label>
            <Form.Control
              ref={textAreaRef}
              as='textarea'
              placeholder='provide a title for your idea.'
              name='ideaTitle'
              onChange={handleSolutionInputChange}
              value={newSolution.ideaTitle}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={1}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              💡 Idea Description
            </Form.Label>
            <ReactQuill
              ref={textAreaRef}
              as='textarea'
              placeholder='shortly describe your idea...'
              name='ideaDescription'
              onChange={(content) =>
                handleQuillChange(content, "ideaDescription")
              }
              value={newSolution.ideaDescription}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={3}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              🎯 Technology Readiness Level (TRL)
            </Form.Label>
            <ReactQuill
              ref={textAreaRef}
              as='textarea'
              placeholder='shortly describe why this idea is technologically feasible...'
              name='trl'
              onChange={(content) => handleQuillChange(content, "trl")}
              value={newSolution.trl}
              className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
              rows={3}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
              📷 Idea Image
            </Form.Label>
            <div
              {...getRootProps()}
              className='image-dropperContainer idea-sharingModal'
            >
              <input {...getInputProps()} />
              <Button className='image-dropperContainer-chooseFileButton'>
                Choose Image
              </Button>
              {selectedFile ? (
                <div className='image-dropperContainer-image-drop-zone'>
                  {selectedFile.path}
                </div>
              ) : (
                <div className='image-dropperContainer-image-drop-zone'>
                  or drop an image here...
                </div>
              )}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        className='BlackboxCISolutions-shareSolutionsModal-footer'
        style={{ background: "rgb(34,34,42)" }}
      >
        {errorIsLoadingSuggestions ? (
          <span style={{ color: "red", marginRight: "50px" }}>
            Something went wrong with the inspiration, please try again
          </span>
        ) : (
          <span></span>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSolutionSubmit(e);
          }}
          className='BlackboxCISolutions-shareSolutionsModal-footer-submitButton'
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
