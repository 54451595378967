import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./DonutChart.css";

const DonutChart = ({
  journeyTouchpoints,
  selectedStage,
  setSelectedStage,
}) => {
  const ref = useRef();

  const drawChart = () => {
    const width = 300;
    const height = 300;
    const outerRadius = Math.min(width, height) / 2.2;
    const innerRadius = outerRadius * 0.7;

    d3.select(ref.current).select("svg").remove();

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    d3.select(ref.current)
      .select("svg")
      .style("cursor", "pointer")
      .on("click", () => {
        setSelectedStage(null);
      });

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const pie = d3.pie().value((d) => 1);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const g = svg
      .selectAll(".arc")
      .data(pie(journeyTouchpoints))
      .enter()
      .append("g")
      .attr("class", "arc");

    g.append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => color(i))
      .style("opacity", (d) => (selectedStage === d.data.stage ? 1 : 0.5))
      .style("filter", (d) =>
        selectedStage === d.data.stage
          ? "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7))"
          : null
      )
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        event.stopPropagation();
        setSelectedStage(d.data.stage === selectedStage ? null : d.data.stage);
      });

    const rotations = [35, 90, -32, 32, -90, 150]; // Set the rotation for each title
    const offsets = [-22, 35, 25, 15, -20, 25]; // Set the offset for each title

    g.append("text")
      .attr("transform", (d, i) => {
        const rotation = rotations[i];
        const midAngle = (d.startAngle + d.endAngle) / 2;
        const x = arc.centroid(d)[0] + offsets[i] * Math.cos(midAngle);
        const y = arc.centroid(d)[1] + offsets[i] * Math.sin(midAngle);
        return `translate(${x}, ${y}) rotate(${rotation}) rotate(${
          rotation > 90 ? -180 : 0
        })`;
      })
      .style("font-size", "12px")
      .style("text-anchor", (d, i) => (rotations[i] < 90 ? "start" : "end"))
      .attr("dy", ".35em")
      .style("opacity", (d) => (selectedStage === d.data.stage ? 1 : 0.3))
      .style("font-weight", (d) =>
        selectedStage === d.data.stage ? "600" : "300"
      )
      .style("cursor", "pointer")
      .text((d) => d.data.stage)
      .on("click", (event, d) => {
        event.stopPropagation();
        setSelectedStage(d.data.stage === selectedStage ? null : d.data.stage);
      });
  };

  const midAngle = (d) => {
    return (d.startAngle + d.endAngle) / 2;
  };

  useEffect(() => {
    drawChart();
  }, [selectedStage, journeyTouchpoints]);

  return <div ref={ref}></div>;
};

export default DonutChart;
