import "./App.css";

import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Website from "./publicWebPages/Website";
import HowToPage from "./publicWebPages/HowToPage";
import BBTool from "./privateWebPages/pages/BBTool";
import BlackboxLandingPage from "./publicWebPages/BlackboxLandingPage";
import BlackboxRegisterPage from "./publicWebPages/BlackboxRegisterPage";
import BlackboxLoginPage from "./publicWebPages/BlackboxLoginPage";
import UploadDataToFirebase from "./privateWebPages/knowledgeBaseData/uploadDataToFirebase";
import Case1 from "./publicWebPages/cases/Case1";
import ReferenceCaseFramework from "./referenceCases/ReferenceCaseFramework";
import { britvic } from "./referenceCases/refCaseData/RefCaseDataBritvic.js";
import { mattel } from "./referenceCases/refCaseData/RefCaseDataMattel.js";
import {
  AGBarrCompanyName,
  AGBarrChallengeDefinition,
  AGBarrEvaluationCriteria,
  AGBarrTouchpoints,
  AGBarrGenAiUseCases,
  AGBarrQuiz,
  AGBarrUserValidationResults,
  AGBarrPocFeatures,
} from "./referenceCases/refCaseData/RefCaseDataAGBarr.js";

import { useAuthContext } from "./hooks/useAuthContext";

Sentry.init({
  dsn: "https://a1f59311216c953bdcd2494648ba2cbd@o4505941261025280.ingest.sentry.io/4505941262991360",
  integrations: [
    new Integrations.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/], // Adjust this to your needs
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, // Adjust the sample rate as needed
  replaysOnErrorSampleRate: 1.0, // Adjust the sample rate as needed
});

function PrivateRoute({ children }) {
  const { user } = useAuthContext();
  if (user && user.emailVerified) {
    return children;
  }
  return <Navigate to='/blackbox/login' />;
}

function App() {
  const { user, authIsReady } = useAuthContext();

  return (
    <div className='App'>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <Website />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/howto'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <HowToPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/blackbox'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <BlackboxLandingPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/blackbox/login'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <BlackboxLoginPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/login'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <BlackboxLoginPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/register'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <BlackboxRegisterPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/blackbox/register'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <BlackboxRegisterPage />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/case1'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <Case1 />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/115783546/Britvic'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <ReferenceCaseFramework
                    companyNameData={britvic.companyName}
                    challengeDefinitionData={britvic.challengeDefinition}
                    evaluationCriteriaData={britvic.evaluationCriteria}
                    touchpointsData={britvic.journeyTouchpoints}
                    genAiUseCasesData={britvic.genAiUseCases}
                    quizData={britvic.quiz}
                    pocFeaturesData={britvic.pocFeatures}
                    userValidationResultsData={britvic.userValidationResults}
                  />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/1167678411/Mattel'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <ReferenceCaseFramework
                    companyNameData={mattel.companyName}
                    challengeDefinitionData={mattel.challengeDefinition}
                    evaluationCriteriaData={mattel.evaluationCriteria}
                    touchpointsData={mattel.journeyTouchpoints}
                    genAiUseCasesData={mattel.genAiUseCases}
                    quizData={mattel.quiz}
                    pocFeaturesData={mattel.pocFeatures}
                    userValidationResultsData={mattel.userValidationResults}
                  />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/134734226/AGBarr'
              element={
                <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                  <ReferenceCaseFramework
                    companyNameData={AGBarrCompanyName}
                    challengeDefinitionData={AGBarrChallengeDefinition}
                    evaluationCriteriaData={AGBarrEvaluationCriteria}
                    touchpointsData={AGBarrTouchpoints}
                    genAiUseCasesData={AGBarrGenAiUseCases}
                    quizData={AGBarrQuiz}
                    pocFeaturesData={AGBarrPocFeatures}
                    userValidationResultsData={AGBarrUserValidationResults}
                  />
                </Sentry.ErrorBoundary>
              }
            />
            <Route
              path='/ideationplatform'
              element={
                <PrivateRoute>
                  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                    <BBTool content='BlackboxHomePage' />{" "}
                    {/* See BBTool in privateWebPages/pages folder   */}
                  </Sentry.ErrorBoundary>
                </PrivateRoute>
              }
            />
            <Route
              path='/uploadDataToFirebase'
              element={
                <PrivateRoute>
                  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
                    <UploadDataToFirebase />
                  </Sentry.ErrorBoundary>
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
