// import visuals
import GenAIUseCase from "../../assets/GenAIUseCase.jpg";

const industryUseCasesIntro = {
  title: "Industry Usecases",
  description: [
    "We don't have to reinvent the wheel. Perhaps there might be interesting usecases already persued by some of our competitors or companies in other industries that might also be relevant for our business. Let's take a look at some of these usecases we've spotted in the press.",
  ],
};

const industryUseCases = [
  {
    cardTitle: "Use Case 1",
    imageUrl: GenAIUseCase,
    useCaseIndustry: "Food & Beverages",
    useCaseDescription: "This is a description of the use case.",
  },
];

export { industryUseCasesIntro, industryUseCases };
