import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { auth, db, storage } from "../../firebase/config";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./BBPlayerView.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSave, faCaretRight } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(ArcElement, Tooltip, Legend);

const BlackboxPlayerPage = ({
  currentUserId,
  playersData,
  solutionsData,
  argumentsData,
  solutionsVotesData,
}) => {
  const [isProfileEditMode, setIsProfileEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editProfilePictureError, setEditProfilePictureError] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);

  useEffect(() => {
    console.log(solutionsVotesData);
  }, []);

  // Find the relevant data of the current player based on the provided userId
  const currentPlayer = playersData.find(
    (player) => player.userUid === currentUserId
  );
  const [newPlayerImageURL, setNewPlayerImageURL] = useState(
    currentPlayer.playerImageURL
  );
  const solutionsCountCurrentPlayer = solutionsData.filter(
    (solution) => solution.userId === currentUserId
  ).length;
  const solutionsCountAllPlayers = solutionsData.length;

  const needsVotesCountCurrentPlayer = solutionsVotesData.filter(
    (vote) => vote.userId === currentUserId
  ).length;
  const needsVotesCountAllPlayers = solutionsVotesData.length;
  const greenVotesCount = solutionsVotesData.filter(
    (item) => item.vote === "agree" && item.userId === currentUserId
  ).length;
  const redVotesCount = solutionsVotesData.filter(
    (item) => item.vote === "disagree" && item.userId === currentUserId
  ).length;

  const argumentsCountCurrentPlayer = argumentsData.filter(
    (vote) => vote.userId === currentUserId
  ).length;
  const argumentsCountAllPlayers = argumentsData.length;

  // function to prepare data to be used to create needs barchart
  const getNeedsCreatedChartData = (
    dataItem1,
    dataItem2,
    colorDataItem1 = "rgba(205,32,38,1)",
    colorDataItem2 = "rgba(247, 168,0,1)",
    label1 = "Yours",
    label2 = "Others"
  ) => {
    return {
      labels: [label1, label2],
      datasets: [
        {
          data: [dataItem1, dataItem2], // example data
          backgroundColor: [colorDataItem1, colorDataItem2],
          hoverBackgroundColor: [colorDataItem1, colorDataItem2],
          borderColor: "rgba(0,0,0,0)",
        },
      ],
    };
  };

  const handleProfileChange = () => {
    setIsProfileEditMode(!isProfileEditMode);
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handlePlayerChangeSubmit = async () => {
    let imageFileURL = currentPlayer.playerImageURL;

    try {
      if (selectedFile) {
        // Check if the file extension is either .jpg or .png
        console.log(selectedFile.path.split(".").pop().toLowerCase());
        const validExtensions = [".jpg", ".png", ".jpeg", ".webp"];
        const fileExtension =
          "." + selectedFile.path.split(".").pop().toLowerCase();

        if (!validExtensions.includes(fileExtension)) {
          setEditProfilePictureError(
            "Invalid file format. Please upload a .webp, .jpg, .jpeg, or .png file."
          );
          setIsProfileEditMode(!isProfileEditMode);
          return; // Exit the function to avoid further processing
        }

        const storageRef = storage.ref();
        const fileRef = storageRef.child(`playerImages/${selectedFile.name}`);
        setImageUploading(true);

        try {
          await fileRef.put(selectedFile);
          imageFileURL = await fileRef.getDownloadURL(); // Directly assign the file URL.
          setNewPlayerImageURL(imageFileURL);

          // Update the current player's image URL within the players collection of the Firestore database
          const playerDocRef = db
            .collection("players")
            .doc(currentPlayer.playerId); // the player id is set in blackboxTool.js to be playerId
          await playerDocRef.update({
            playerImageURL: imageFileURL,
          });

          setImageUploading(false);
        } catch (error) {
          setImageUploading(false);
          console.error("Error updating player image:", error.message);
          return; // Exit if there's an error to prevent further execution.
        }

        setIsProfileEditMode(!isProfileEditMode);
      } else {
        setEditProfilePictureError("Please select a file");
        setIsProfileEditMode(!isProfileEditMode);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className='BlackboxPlayerPage'>
      <h1 className='BlackboxPlayerPage-title'>Player Page</h1>
      <div className='BlackboxPlayerPage-content'>
        <div className='BlackboxPlayerPage-content-row'>
          {currentPlayer && !isProfileEditMode && (
            <div className='player-info-card playerView'>
              <Button>
                <FontAwesomeIcon icon={faPen} onClick={handleProfileChange} />
              </Button>
              <img
                src={newPlayerImageURL}
                alt={currentPlayer.playerName}
                className='player-image'
              />
              <h2>{currentPlayer.playerName}</h2>
            </div>
          )}
          {currentPlayer && isProfileEditMode && (
            <div className='change-solutionImage-interface playerProfile'>
              <div {...getRootProps()} className='image-dropperContainer'>
                <input {...getInputProps()} />
                <Button className='image-dropperContainer-chooseFileButton'>
                  Choose File
                </Button>
                {selectedFile ? (
                  <div className='image-dropperContainer-image-drop-zone'>
                    {selectedFile.path}
                  </div>
                ) : (
                  <div className='image-dropperContainer-image-drop-zone'>
                    or drop a file here...
                  </div>
                )}
              </div>
              <Button
                onClick={handlePlayerChangeSubmit}
                className='image-dropperContainer-saveButton'
              >
                {!imageUploading && <FontAwesomeIcon icon={faSave} />}
                {imageUploading && <Spinner className='SpinnerSymbol' />}
              </Button>
            </div>
          )}
          <div className='player-info-card ideasShared'>
            <div className='player-info-card-title'>
              <h2>Ideas Created</h2>
            </div>
            <div className='player-info-card-graph'>
              <Doughnut
                className='player-info-card-graph-doughnut-chart'
                data={getNeedsCreatedChartData(
                  solutionsCountCurrentPlayer,
                  solutionsCountAllPlayers - solutionsCountCurrentPlayer
                )}
                options={{
                  cutout: "80%",
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <h1>{solutionsCountCurrentPlayer}</h1>
            </div>
            <div className='player-info-card-legend'>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(205,32,38)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Your Ideas
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {solutionsCountCurrentPlayer}
                </div>
              </div>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(247, 168,0)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Other Ideas
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {solutionsCountAllPlayers - solutionsCountCurrentPlayer}
                </div>
              </div>
            </div>
          </div>
          <div className='player-info-card argumentsShared'>
            <div className='player-info-card-title'>
              <h2>Arguments Shared</h2>
            </div>
            <div className='player-info-card-graph'>
              <Doughnut
                className='player-info-card-graph-doughnut-chart'
                data={getNeedsCreatedChartData(
                  argumentsCountCurrentPlayer,
                  argumentsCountAllPlayers - argumentsCountCurrentPlayer
                )}
                options={{
                  cutout: "80%",
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <h1>{argumentsCountCurrentPlayer}</h1>
            </div>
            <div className='player-info-card-legend'>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(205,32,38)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Your Arguments
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {argumentsCountCurrentPlayer}
                </div>
              </div>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(247, 168,0)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Other Arguments
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {argumentsCountAllPlayers - argumentsCountCurrentPlayer}
                </div>
              </div>
            </div>
          </div>
          <div className='player-info-card votesShared'>
            <div className='player-info-card-title'>
              <h2>Votes Shared</h2>
            </div>
            <div className='player-info-card-graph'>
              <Doughnut
                className='player-info-card-graph-doughnut-chart'
                data={getNeedsCreatedChartData(
                  needsVotesCountCurrentPlayer,
                  needsVotesCountAllPlayers - needsVotesCountCurrentPlayer
                )}
                options={{
                  cutout: "80%",
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <h1>{needsVotesCountCurrentPlayer}</h1>
            </div>
            <div className='player-info-card-legend'>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(205,32,38)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Your Votes
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {needsVotesCountCurrentPlayer}
                </div>
              </div>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(247, 168,0)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Other Votes
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {needsVotesCountAllPlayers - needsVotesCountCurrentPlayer}
                </div>
              </div>
            </div>
          </div>
          <div className='player-info-card votesReceived'>
            <div className='player-info-card-title'>
              <h2>Votes Received</h2>
            </div>
            <div className='player-info-card-graph'>
              <Doughnut
                className='player-info-card-graph-doughnut-chart'
                data={getNeedsCreatedChartData(
                  greenVotesCount,
                  redVotesCount,
                  "green",
                  "red",
                  "Green Votes Received",
                  "Red Votes Received"
                )}
                options={{
                  cutout: "80%",
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
              <h1>{greenVotesCount + redVotesCount}</h1>
            </div>
            <div className='player-info-card-legend'>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "green" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Your Upvotes Received
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {greenVotesCount}
                </div>
              </div>
              <div className='player-info-card-legend-row'>
                <div
                  className='player-info-card-legend-row-itemColor'
                  style={{ background: "rgb(205,32,38)" }}
                ></div>
                <div className='player-info-card-legend-row-itemText'>
                  Your Downvotes Received
                </div>
                <div className='player-info-card-legend-row-itemPercentage'>
                  {redVotesCount}
                </div>
              </div>
            </div>
          </div>
          <div className='player-info-card VideosAndNewsletters'>
            <div className='player-info-card-title'>
              <h2>Videos & Newsletters</h2>
              <ul>
                <li>
                  <a
                    href='https://firebasestorage.googleapis.com/v0/b/ci-growthhack.appspot.com/o/MissionBrief_Cheez-ItGrowthHack.mp4?alt=media&token=bdbde677-622d-4324-ba3a-b71e24ad2087'
                    target='_blank'
                    style={{ color: "white", fontSize: "14px" }}
                  >
                    Video - Mission Briefing
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackboxPlayerPage;
