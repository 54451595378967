import React, { useState } from "react";
import {
  faStore,
  faTruck,
  faBox,
  faPercent,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import css style sheets
import "./BBKnowledgeBaseRetailerItem.css";

const storeTypes = [
  { name: "Hyper", icon: faStore },
  { name: "Super", icon: faStore },
  { name: "Discount", icon: faPercent },
  { name: "Convenience", icon: faShoppingBasket },
  { name: "Online", icon: faTruck },
  { name: "Wholesaler", icon: faBox },
];

export default function BlackboxCIKnowledgeBaseRetailerItem({
  chosenKnowledgeBaseItem,
}) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow'>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={`image of ${chosenKnowledgeBaseItem.cardTitle}`}
            className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image'
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn'>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-title'>
            2022 Sales:
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item'>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-title'>
              All
            </div>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-data'>
              € {chosenKnowledgeBaseItem.totalCountryRevenueInBillion} B
            </div>
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item'>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-title'>
              {`number of EU stores`}
            </div>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-data'>
              {chosenKnowledgeBaseItem.numberOfCountryStores}
            </div>
          </div>
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-storeTypeRow'>
        {storeTypes.map((storeType, storeTypeIndex) => (
          <div
            className={
              Object.values(
                chosenKnowledgeBaseItem.retailerType[storeTypeIndex]
              )[0]
                ? "BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard"
                : "BlackboxCIKnowledgeBaseItem-storeTypeRow-storeTypeCard inactive"
            }
            key={storeTypeIndex}
          >
            <FontAwesomeIcon icon={storeType.icon} className='retailerIcon' />
            <div>{storeType.name}</div>
          </div>
        ))}
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard description'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Description
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content description'>
          {chosenKnowledgeBaseItem.description.map((description, index) => (
            <div key={index}>{description}</div>
          ))}
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard companyMotto'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Company Motto
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content motto'>
          {chosenKnowledgeBaseItem.companyMotto.map((motto, index) => (
            <div key={index}>{motto}</div>
          ))}
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard targetCustomers'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Main Target Customers
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content targetCustomers'>
          <ul>
            {chosenKnowledgeBaseItem.mainTargetCustomers.map(
              (targetCustomer, index) => (
                <li key={index}>{targetCustomer}</li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard strategicPriorities'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Strategic Priorities
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content strategicPriorities'>
          {chosenKnowledgeBaseItem.strategicPriorities.map(
            (priority, index) => (
              <div>
                <div>{priority.title}</div>
                <ul>
                  {priority.description.map((bulletPoint, indexBulletPoint) => (
                    <li key={indexBulletPoint}>{bulletPoint}</li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard europeanPresence'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          {`Presence within Europe`}
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content europeanPresence'>
          <ul>
            {chosenKnowledgeBaseItem.EuropeanPresenceBeyondCountry.map(
              (country, index) => (
                <li key={index}>{country}</li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className='BlackboxCIKnowledgeBaseItem-infoCard sources'>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
          Data Sources
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-infoCard-content sources'>
          <ul>
            {chosenKnowledgeBaseItem.sources.map((source, index) => (
              <li key={index}>
                <a href={source.url} target='_blank'>
                  {source.link}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
