import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const MasonryLayout = ({ children, gap, columnProp }) => {
  const [columnWrapper, setColumnWrapper] = useState({});
  const [result, setResult] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to calculate the number of columns based on the window width
  const calculateColumns = () => {
    if (windowWidth < 900) {
      return 1;
    } else if (windowWidth < 1200) {
      return 2;
    } else {
      return columnProp || 3;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = calculateColumns(); // Determine the number of columns

  useEffect(() => {
    const newColumnWrapper = {};
    for (let i = 0; i < columns; i++) {
      newColumnWrapper[`column${i}`] = [];
    }
    for (let i = 0; i < children.length; i++) {
      const columnIndex = i % columns;
      newColumnWrapper[`column${columnIndex}`].push(
        <div key={`column${columnIndex}-item${i}`}>{children[i]}</div>
      );
    }
    setColumnWrapper(newColumnWrapper);
  }, [children, columns]);

  useEffect(() => {
    const newResult = [];
    for (let i = 0; i < columns; i++) {
      newResult.push(
        <div
          key={`column${i}`}
          style={{
            marginLeft: i > 0 ? gap : 0,
            flex: 1,
          }}
        >
          {columnWrapper[`column${i}`]}
        </div>
      );
    }
    setResult(newResult);
  }, [columnWrapper, columns]);

  return <div style={{ display: "flex" }}>{result}</div>;
};

MasonryLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  gap: PropTypes.number,
};

MasonryLayout.defaultProps = {
  gap: 20,
};

export default MasonryLayout;
