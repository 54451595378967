import React from "react";
import "./IntroductionPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function IntroductionPage() {
  return (
    <div className='IntroductionPage'>
      <p>
        We offer your organization a superpower.
        <br />
        The power to quickly <b>learn about cutting-edge AI technology,</b>
        <br />
        <b>identify the most relevant use-cases</b>,{" "}
        <b>build promising proof-of-concepts</b>
        <br className='DesktopBreak' /> and{" "}
        <b>run disciplined business experiments</b>, before making any
        unnecessary and expensive commitments.
      </p>
      <Link
        className='roundScrollButton'
        to={{ pathname: "/", hash: "#howToAssistant" }}
        aria-label='Scroll to next section'
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </Link>
    </div>
  );
}

export default IntroductionPage;
