// LandingPage.js
import React, { useEffect } from "react";
import BlackboxFeatures from "./BlackboxFeatures";
import Footer from "./Footer";
import "./BlackboxLandingPage.css";
import BlackboxAstronautVisual from "../assets/webp/BlackboxAstronautVisual.webp";
import blackboxLogoWhite from "../assets/howToFly_logo_forBlackBackground.png";
import BlackboxValueDescription from "./BlackboxValueDescription";
import ExperimentDetails from "../assets/BlackboxVisual_ExperimentDetails.png";
import BlackboxVisual from "../assets/webp/BlackBoxVisual_noLogo.webp";
import BlackboxFlowDiagram from "../assets/BlackboxFlowDiagram.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const introduction = {
  title: "Unlock & leverage the full creativity of your entire workforce",
  text1:
    "By combining generative AI, crowdsourcing, and gamification techniques, in one online platform, we allow you to quickly unlock and leverage the full creativity, experience, and knowledge of your entire workforce.",
  text2:
    "The result is a custom trained, ever improving digital twin of your collective intelligence, which can be used to identify the most promising use cases for AI at the right time.",
  image: BlackboxFlowDiagram,
};

const features = [
  {
    number: "01.",
    title: "Empower Every Employee",
    text: "Our Blackbox Ideation Platform empowers every employee in a cost-efficient way. Allowing you to identify the most relevant AI use cases that can improve their work and / or improve customer experience.",
    image: ExperimentDetails,
  },
  {
    number: "02.",
    title: "Wider Collaboration",
    text: "Our platform breaks departmental silos, bringing diverse perspectives together for richer, more creative ideas.",
    image: ExperimentDetails,
  },
  {
    number: "03.",
    title: "Safe Space for Creativity",
    text: "With anonymous nicknames and fun elements, Blackbox stimulates creative contributions from even the most reserved employees.",
    image: ExperimentDetails,
  },
  {
    number: "04.",
    title: "Beyond On-the-Spot Creativity",
    text: "Move beyond the pressure of on-the-spot thinking. Blackbox allows for thoughtful, ongoing development of ideas at your own pace.",
    image: ExperimentDetails,
  },
  {
    number: "05.",
    title: "Smarter Over Time",
    text: "The Blackbox AI-engine learns and improves with each use, making brainstorming sessions more effective and insightful as time goes on.",
    image: ExperimentDetails,
  },
];

const TileComponent = ({ number, title, text }) => {
  return (
    <div className='features-section-tile'>
      <div className='features-section-tile-number'>{number}</div>
      <div className='features-section-tile-title'>{title}</div>
      <div className='features-section-tile-description'>{text}</div>
    </div>
  );
};

const BlackboxLandingPage = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className='landing-page' id='BlackboxLandingPage'>
      <div className='BlackboxLandingPage-navbar'>
        <div className='BlackboxLandingPage-navbar-logo'>
          <Link to={{ pathname: "/", hash: "" }}>
            <img src={blackboxLogoWhite} alt='Blackbox logo' />
          </Link>
        </div>
        <div className='BlackboxLandingPage-navbar-links'>
          <Link
            className='BlackboxLandingPage-navbar-links-howtoflyButton'
            to={{ pathname: "/", hash: "" }}
          >
            HowToFly
          </Link>
          <Link
            className='BlackboxLandingPage-navbar-links-loginButton'
            to={{ pathname: "/blackbox/login", hash: "" }}
          >
            login
          </Link>
        </div>
      </div>
      <section className='introduction'>
        <div className='introduction-topHalf'>
          <div className='introduction-topHalf-heroImage'>
            <img
              src={BlackboxAstronautVisual}
              alt='Visual of Blackbox Astronaut'
            />
          </div>
          <div className='introduction-topHalf-overlayText'>
            <h1>BLACKBOX</h1>
            <p>
              <i>
                Identify your comapny's most promising use case for generative
                AI <br />
                with our AI-Enhanced, Gamified Ideation Platform
              </i>
            </p>
            <a
              className='introduction-topHalf-overlayText-button'
              href='https://calendly.com/howtofly'
            >
              Request a demo
            </a>
          </div>
        </div>
      </section>

      <section className='overview-section'>
        <BlackboxValueDescription
          title={introduction.title}
          text1={introduction.text1}
          text2={introduction.text2}
          text3={introduction.text3}
          text4={introduction.text4}
          image={introduction.image}
          normalOrder={2 % 2 === 0 ? true : false}
        />
      </section>

      <section className='features-section'>
        <h2 className='features-section-mainTitle'>
          <span className='features-section-mainTitle-5ways'>5 ways</span>
          <br />
          it goes beyond traditional brainstorms
          <br />
        </h2>
        {features.map((feature, index) => (
          <TileComponent
            key={index}
            number={feature.number}
            title={feature.title}
            text={feature.text}
          />
        ))}
      </section>
      <section className='contact-section'>
        <p className='contact-section-contactText'>Ready to Fly with AI?</p>
        <a
          className='contact-section-contactButton-link'
          href='mailto:gilles@howtofly.ai'
        >
          <button className='contact-section-contactButton'>
            CONTACT US NOW
          </button>
        </a>
      </section>
      <Footer
        className='BlackboxLandingPage-footer'
        backgroundColor='rgba(0,0,0,0.2)'
      />
    </div>
  );
};

export default BlackboxLandingPage;
