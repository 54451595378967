// import visuals
import Role1 from "../../assets/Roles/Role1.webp";
import Role2 from "../../assets/Roles/Role2.webp";
import Role3 from "../../assets/Roles/Role3.webp";
import Role4 from "../../assets/Roles/Role4.webp";
import Role5 from "../../assets/Roles/Role5.webp";
import Role6 from "../../assets/Roles/Role6.webp";

const roleDescriptionIntro = {
  title: "Role Descriptions",
  description: [
    "If we want to optimize our internal workflows by optimally leveriging Generative AI, we need to understand all activities and roles within our organization. This will help us to identify the right use cases and identify the right AI technology and capabilities to support these roles.",
  ],
};

const roleDescriptions = [
  {
    cardTitle: "Board Game Designer",
    role: "Board Game Designer",
    imageUrl: Role1,
    mainActivities: [
      {
        activityTitle: "Game Concept Development",
        activityDescription:
          "Develop original game concepts, including theme, mechanics, and gameplay to create engaging board games.",
      },
      {
        activityTitle: "Prototyping and Testing",
        activityDescription:
          "Build prototypes and conduct play-testing sessions to refine game rules and mechanics.",
      },
      {
        activityTitle: "Art Direction",
        activityDescription:
          "Collaborate with artists and graphic designers to create the visual elements of the game, such as board layout, cards, and characters.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Balancing Complexity and Playability",
        painPointDescription:
          "Struggling to create games that are both deep in strategy and accessible to new players.",
      },
      {
        painPointTitle: "Meeting Production Deadlines",
        painPointDescription:
          "Managing the timeline to ensure timely completion of game development phases can be challenging.",
      },
      {
        painPointTitle: "Intellectual Property Concerns",
        painPointDescription:
          "Ensuring that new game ideas do not infringe on existing games' copyrights and trademarks.",
      },
    ],
  },
  {
    cardTitle: "Marketing Manager",
    imageUrl: Role2,
    role: "Marketing Manager",
    mainActivities: [
      {
        activityTitle: "Campaign Development",
        activityDescription:
          "Create and manage marketing campaigns to promote new and existing board games.",
      },
      {
        activityTitle: "Market Research",
        activityDescription:
          "Conduct market research to understand consumer trends and preferences in the board game industry.",
      },
      {
        activityTitle: "Social Media Management",
        activityDescription:
          "Manage social media platforms to engage with the community, promote products, and gather customer feedback.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Ad Budget Constraints",
        painPointDescription:
          "Allocating sufficient funds to marketing efforts without overspending can be a delicate balance.",
      },
      {
        painPointTitle: "Evolving Digital Marketing Trends",
        painPointDescription:
          "Keeping up with rapidly changing digital marketing strategies and platforms is a continuous challenge.",
      },
      {
        painPointTitle: "Measurement of Campaign Effectiveness",
        painPointDescription:
          "Determining the effectiveness of marketing campaigns and adjusting strategies accordingly.",
      },
    ],
  },
  {
    cardTitle: "Game Illustrator",
    imageUrl: Role3,
    role: "Game Illustrator",
    mainActivities: [
      {
        activityTitle: "Art Creation",
        activityDescription:
          "Design and illustrate the artistic components of board games, including characters, environments, and icons.",
      },
      {
        activityTitle: "Collaborative Design",
        activityDescription:
          "Work closely with game designers to ensure that the visual elements complement the game mechanics and storytelling.",
      },
      {
        activityTitle: "Asset Optimization",
        activityDescription:
          "Optimize artistic assets for production, ensuring they meet technical requirements for printing and manufacturing.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Artistic Consistency",
        painPointDescription:
          "Maintaining a consistent artistic style throughout the game's various elements can be challenging.",
      },
      {
        painPointTitle: "Meeting Deadlines",
        painPointDescription:
          "Completing artwork within tight production schedules often requires significant time management.",
      },
      {
        painPointTitle: "Creative Differences",
        painPointDescription:
          "Navigating creative differences between team members to reach a cohesive end product.",
      },
    ],
  },
  {
    cardTitle: "Production Manager",
    role: "Production Manager",
    imageUrl: Role4,
    mainActivities: [
      {
        activityTitle: "Manufacturing Oversight",
        activityDescription:
          "Oversee the manufacturing process of board games, ensuring products meet quality standards and specifications.",
      },
      {
        activityTitle: "Supplier Management",
        activityDescription:
          "Manage relationships with suppliers to ensure timely delivery of materials and components.",
      },
      {
        activityTitle: "Cost Management",
        activityDescription:
          "Control production costs to keep the final product price competitive in the market.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Supply Chain Disruptions",
        painPointDescription:
          "Dealing with unexpected disruptions in the supply chain that can delay production.",
      },
      {
        painPointTitle: "Quality Control Issues",
        painPointDescription:
          "Ensuring that all components of the game meet the high standards required for customer satisfaction.",
      },
      {
        painPointTitle: "Environmental Regulations",
        painPointDescription:
          "Adhering to environmental regulations and standards in the production process.",
      },
    ],
  },
  {
    cardTitle: "Customer Service Representative",
    role: "Customer Service Representative",
    imageUrl: Role5,
    mainActivities: [
      {
        activityTitle: "Customer Support",
        activityDescription:
          "Handle inquiries and issues reported by customers, providing timely and effective solutions.",
      },
      {
        activityTitle: "Feedback Collection",
        activityDescription:
          "Gather and analyze customer feedback to inform future game developments and improvements.",
      },
      {
        activityTitle: "Product Knowledge",
        activityDescription:
          "Maintain in-depth knowledge of the company’s product line to provide accurate information to customers.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "High Volume of Inquiries",
        painPointDescription:
          "Managing a high volume of inquiries, especially following new releases or during promotional periods.",
      },
      {
        painPointTitle: "Resolving Complex Issues",
        painPointDescription:
          "Dealing with complex customer issues that require extensive investigation or escalation.",
      },
      {
        painPointTitle: "Maintaining Customer Satisfaction",
        painPointDescription:
          "Ensuring customer satisfaction while adhering to company policies, which can sometimes conflict.",
      },
    ],
  },
  {
    cardTitle: "Logistics Coordinator",
    role: "Logistics Coordinator",
    imageUrl: Role6,
    mainActivities: [
      {
        activityTitle: "Inventory Management",
        activityDescription:
          "Manage inventory levels to ensure sufficient stock is available to meet customer and retail demands.",
      },
      {
        activityTitle: "Distribution Planning",
        activityDescription:
          "Plan and coordinate the distribution of board games to various retail and direct customers globally.",
      },
      {
        activityTitle: "Shipping Coordination",
        activityDescription:
          "Coordinate with shipping companies to ensure timely and cost-effective delivery of products.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Shipping Delays",
        painPointDescription:
          "Handling delays in shipping that can lead to customer dissatisfaction and stock shortages.",
      },
      {
        painPointTitle: "International Logistics",
        painPointDescription:
          "Navigating international logistics, including customs and import/export regulations.",
      },
      {
        painPointTitle: "Cost Optimization",
        painPointDescription:
          "Keeping shipping and handling costs low while maintaining efficient delivery timelines.",
      },
    ],
  },
];

export { roleDescriptionIntro, roleDescriptions };
