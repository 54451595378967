import React, { useState } from "react";
import { Button } from "react-bootstrap";

// import css style sheets
import "./BBKnowledgeBaseIndustryUseCase.css";

export default function BBKnowledgeBaseCompanySKU({ chosenKnowledgeBaseItem }) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <p>{chosenKnowledgeBaseItem.useCaseIndustry}</p>
      <h1>{chosenKnowledgeBaseItem.cardTitle}</h1>
      <div className='BlackboxCIKnowledgeBaseItem-imageAndOptionButtonRow'>
        <div className='BlackboxCIKnowledgeBaseItem-imageContainer'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={chosenKnowledgeBaseItem.cardTitle}
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-buttonContainer'>
          <Button className='active'>Use Case Description</Button>
          <Button>Technologies Used</Button>
          <Button>Benefits GenAI Brings</Button>
        </div>
      </div>
      <div className='targetCustomersCards'>
        <div className='targetCustomerCard'>
          <p>{chosenKnowledgeBaseItem.useCaseDescription}</p>
          <p>
            Source:{" "}
            <a href='https://sporza.be'>{chosenKnowledgeBaseItem.cardTitle}</a>
          </p>
          <div className='addToDeckCircleButton'></div>
        </div>
      </div>
    </div>
  );
}
