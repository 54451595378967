import React, { useState, useEffect } from "react";
import axios from "axios";

//import firebase database
import { auth, db } from "../../firebase/config";

// import classnale library
import classNames from "classnames";

// sanitize html
import DOMPurify from "dompurify";

// import libraries for pdf download
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Bootstrap components
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

// Import styles
import "./BBToolHeader.css";

// Import assets
import Avatar from "../../assets/Avatar.png";
import deck0 from "../../assets/Deck0.png";
import deck1 from "../../assets/Deck1.png";
import deck2 from "../../assets/Deck2.png";
import deck3 from "../../assets/Deck3.png";

// Import icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faRobot,
  faPaperPlane,
  faQuestion,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

export default function BlackboxToolHeader({
  visibleContent,
  menuActive,
  setMenuActive,
  setBuildDeckVisible,
  setPageInfoModalShow,
  buildDeckContent,
  customTheme,
}) {
  const [askMeAnythingInput, setAskMeAnythingInput] = useState("");
  const [askMeAnythingResponse, setAskMeAnythingResponse] = useState("");
  const [aiResponse, setAiResponse] = useState(null);
  const [askMeAnythingModalShow, setAskMeAnythingModalShow] = useState(false);
  const [deckIcon, setDeckIcon] = useState(deck0);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (buildDeckContent.filter((item) => item.empty === false).length === 0) {
      setDeckIcon(deck0);
    }
    if (buildDeckContent.filter((item) => item.empty === false).length === 1) {
      setDeckIcon(deck1);
    }
    if (buildDeckContent.filter((item) => item.empty === false).length === 2) {
      setDeckIcon(deck2);
    }
    if (buildDeckContent.filter((item) => item.empty === false).length === 3) {
      setDeckIcon(deck3);
    }
  }, [buildDeckContent]);

  const downloadPDF = async () => {
    try {
      const elementToExport = document.getElementById("pdfExportTest");
      const canvas = await html2canvas(elementToExport);
      const imgData = canvas.toDataURL("image/png");

      // Calculate the ratio to fit the image within the PDF page
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight); // Add image with calculated width and height
      pdf.save("download.pdf");
    } catch (error) {
      console.error("Error occurred while generating PDF: ", error);
    }
  };

  const handleAskMeAnythingInputChange = (e) => {
    setAskMeAnythingInput(e.target.value);
  };

  const handleAskMeAnythingSubmit = async (e) => {
    e.preventDefault();
    setAskMeAnythingResponse(
      `<div><p>Wait 10-30 seconds, I'm currently thinking and will answer you soon!</p><p>Be aware, that the answer will be AI-gnerated and might not be 100% accurate. Simply see it as a starting point or inspiration.</p><p>If you prefer a human generated answer, just mail to <a href="mailto: info@cigrowthhack.com">info@cigrowthhack.com</a> and we'll get back to you as soon as possible.</p></div>`
    );
    setAskMeAnythingModalShow(true);
    try {
      const res = await axios.post(
        "https://cheezit-chat-i4licugc4a-ew.a.run.app/generate_response",
        {
          question: `${askMeAnythingInput} Please return your answer in an easy to read HTML format.`,
        }
      );
      const data = res.data;
      setAskMeAnythingResponse(data.response);
      setAiResponse(data.response);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
      }
    }
  };

  useEffect(() => {
    if (aiResponse) {
      handleAskMeAnythingRequestLog();
    }
  }, [aiResponse]);

  const handleAskMeAnythingRequestLog = async () => {
    const userId = auth.currentUser.uid;
    const askMeAnythingRequestLog = {
      askMeAnythingQuestion: askMeAnythingInput,
      askMeAnythingResponse: aiResponse,
      userId,
    };

    // Push to Firebase
    try {
      console.log("request to store chat conversation is sent");
      await db.collection("askMeAnythingRequests").add(askMeAnythingRequestLog);
    } catch (e) {
      console.log(
        "error message when trying to store the askMeAnythingRequest",
        e.message
      );
    }
  };

  const MenuOpenButton = ({ setMenuActive }) => (
    <button className='MenuOpenButton' onClick={() => setMenuActive(true)}>
      <FontAwesomeIcon icon={faBars} />
    </button>
  );

  return (
    <div
      className={classNames("BlackboxTool-content-header", {
        menuActive: menuActive,
        menuInActive: !menuActive,
      })}
    >
      {!menuActive && (
        <div className='BlackboxTool-content-header-menuButton'>
          <MenuOpenButton setMenuActive={setMenuActive} />
        </div>
      )}
      {visibleContent !== "BlackboxHomePage" && (
        <form
          className={
            menuActive
              ? "BlackboxTool-content-header-searchBar menuActive"
              : "BlackboxTool-content-header-searchBar"
          }
          onSubmit={handleAskMeAnythingSubmit}
        >
          <input
            type='text'
            placeholder='Ask me anything...'
            className='BlackboxTool-content-header-searchBar-inputField'
            onChange={handleAskMeAnythingInputChange}
          />
          <FontAwesomeIcon
            icon={faRobot}
            className='BlackboxTool-content-header-searchBar-searchIcon'
          />
          <button
            type='submit'
            className='BlackboxTool-content-header-searchBar-submitButton'
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className='BlackboxTool-content-header-searchBar-submitButton-submitIcon'
              style={{ fontSize: "0.7rem" }}
            />
          </button>
        </form>
      )}
      <div
        className={
          visibleContent !== "BlackboxHomePage"
            ? "BlackboxTool-content-header-buildDeckAndQuestionMark"
            : "BlackboxTool-content-header-buildDeckAndQuestionMark moveToRightWhenOnHomePage"
        }
      >
        <div onClick={() => setBuildDeckVisible(true)}>
          <img src={deckIcon} />
        </div>
        <div onClick={() => setPageInfoModalShow(true)}>
          <FontAwesomeIcon icon={faQuestion} className='questionMarkIcon' />
        </div>
      </div>
      <Modal
        show={askMeAnythingModalShow}
        onHide={() => setAskMeAnythingModalShow(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='BlackboxAskMeAnything-responseModal wide-modal'
        id='pdfExportTest'
      >
        <Modal.Header
          closeButton
          className='BlackboxAskMeAnything-responseModal-header'
        >
          <Modal.Title
            style={{ fontSize: "20px" }}
            id='contained-modal-title-vcenter'
          >
            {askMeAnythingInput}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='BlackboxAskMeAnything-responseModal-body'>
          <p
            className='BlackboxAskMeAnything-responseModal-body-response'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(askMeAnythingResponse),
            }}
          ></p>
        </Modal.Body>
        <Modal.Footer className='BlackboxAskMeAnything-responseModal-footer'>
          <Button
            className='BlackboxAskMeAnything-responseModal-footer-closeButton'
            onClick={() => setAskMeAnythingModalShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
