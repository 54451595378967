import OpenAIGPT4 from "../../assets/OpenAIGPT4.png";
import LangChain from "../../assets/LangChain.png";
import Whisper from "../../assets/webp/WhisperLogo.webp";
import Serper from "../../assets/SerperLogo.png";
import ElevenLabs from "../../assets/ElevenLabsLogo.png";
import BeautifulSoup from "../../assets/webp/BeautifulSoupLogo.webp";
import Concept1 from "../../assets/webp/Concept1.webp";
import Concept2 from "../../assets/webp/Concept2.webp";
import Concept3 from "../../assets/webp/Concept3.webp";
import ImagePlaceholder from "../../assets/webp/ImagePlaceholder.webp";
import AGBarrOperations from "../../assets/webp/BritvicOperations.webp";
import Challenge2_AGBarr from "../../assets/Challenge2_AGBarr.jpg";
import Challenge1_AGBarr from "../../assets/webp/Challenge1_AGBarr.webp";
import { act } from "react-dom/test-utils";

const AGBarrCompanyName = "AG Barr";

const AGBarrChallengeDefinition = {
  title: "Challenge Definition",
  description: `Identify Generative AI use case that can elevate the experience of AG Barr's Consumers & Shoppers, enhance the collaboration with Grocery Retailers and OOH Channels, and augment the capabilities of all AG Barr's employees.`,
  focusAreas: [
    {
      title: "Elevate the experience of Consumers & Shoppers",
      description:
        "Investigate how generative AI could create highly personalized marketing campaigns that cater to the unique tastes and preferences of AG Barr's diverse consumer base, leveraging data from various touchpoints. This could involve customizing beverage recommendations, promotional events, or interactive brand experiences that align with current lifestyle trends and the distinctive heritage of AG Barr’s brands like Irn-Bru and Rubicon.",
      imageUrl: Challenge1_AGBarr,
    },
    {
      title: "Enhance collaboration with Grocery Retailers and OOH Channels",
      description:
        "Explore ways in which generative AI can strengthen partnerships between AG Barr and grocery retailers/out-of-home (OOH) channels, optimizing supply chain efficiency and product placement strategies. This could include predictive analytics for demand forecasting, automated inventory management, and the generation of insights to support co-marketing initiatives that adapt to changing consumer preferences for soft drinks.",
      imageUrl: Challenge2_AGBarr,
    },
    {
      title: "Augment the capabilities of AG Barr's Employees",
      description: `Delve into opportunities for generative AI to enhance the productivity and creativity of AG Barr's workforce. This may involve automating routine tasks to free up time for strategic thinking, utilizing AI for talent acquisition and development, or deploying AI tools to foster innovation in product development, ensuring AG Barr continues to lead with exciting new flavors and beverages that meet evolving consumer expectations.`,
      imageUrl: AGBarrOperations,
    },
  ],
};

const AGBarrEvaluationCriteria = [
  {
    title: "Technology Readiness Level (TRL)",
    description: `Ideas should leverage current or near-future AI capabilities, should be scalable and should hold the potential for integration into existing systems.`,
  },
  {
    title: "Positive Net Margin Impact",
    description: `This assesses how the idea could positively impact ${AGBarrCompanyName}'s financial health by either allowing ${AGBarrCompanyName} to increase its sales prices or reduce its costs.`,
  },
  {
    title: "Positive Turnover Impact",
    description: `This measures the expected effect of the idea on increasing ${AGBarrCompanyName}'s sales volume. It examines market expansion potential, customer engagement strategies, and the capacity of the idea to attract and retain customers, thereby positively influencing the company's sales turnover.`,
  },
];

const AGBarrTouchpoints = [
  {
    stage: "Awareness",
    touchpoints: [
      {
        title: "TV Commercials",
        description: `Customers might see commercials for ${AGBarrCompanyName} beverages while watching their favorite shows.`,
      },
      {
        title: "Online Ads",
        description: `Customers might see online ads for ${AGBarrCompanyName} beverages while browsing the internet or using social media.`,
      },
      {
        title: "In-Store Displays",
        description: `Customers might notice ${AGBarrCompanyName} beverages while shopping in supermarkets and convenience stores.`,
      },
      {
        title: "Sponsorships",
        description: `${AGBarrCompanyName} might sponsor events, sports teams, or TV shows, increasing brand visibility.`,
      },
      {
        title: "Product Placement",
        description: `${AGBarrCompanyName} beverages might be featured in movies, TV shows, or influencer content, showcasing the products in a natural setting.`,
      },
      {
        title: "Social Media Influencers",
        description: `Influencers might promote ${AGBarrCompanyName} beverages on social media, reaching a large and engaged audience.`,
      },
      {
        title: "Email Marketing",
        description: `Customers who have subscribed to ${AGBarrCompanyName}'s email newsletter might receive information about new beverages.`,
      },
      {
        title: "Word of Mouth",
        description: `Customers might hear about ${AGBarrCompanyName} beverages from friends, family, or colleagues.`,
      },
      {
        title: "Public Relations",
        description: `Positive news stories about ${AGBarrCompanyName} or its beverages can raise brand awareness.`,
      },
      {
        title: "Online Search",
        description: `Customers might find ${AGBarrCompanyName} beverages while searching for related keywords on search engines.`,
      },
      {
        title: "Online Recommendations",
        description: `${AGBarrCompanyName} can optimize product recommendations on e-commerce platforms to increase visibility and sales.`,
      },
    ],
  },
  {
    stage: "Consideration",
    touchpoints: [
      {
        title: "Product Reviews",
        description: `Customers might read online reviews about ${AGBarrCompanyName} beverages to learn more about their taste and quality.`,
      },
      {
        title: "Product Comparisons",
        description: `Customers might compare ${AGBarrCompanyName} beverages with those of competitors to decide which product best meets their preferences.`,
      },
      {
        title: "Company Website",
        description: `Customers might visit ${AGBarrCompanyName}'s website to learn more about the company and its range of beverages.`,
      },
      {
        title: "Social Media Pages",
        description: `Customers might visit ${AGBarrCompanyName}'s social media pages to see product announcements, read customer comments, and engage with the brand.`,
      },
      {
        title: "Customer Service Interactions",
        description: `Customers might contact ${AGBarrCompanyName}'s customer service to ask questions about beverages.`,
      },
      {
        title: "In-Store Interactions",
        description: `Customers might taste ${AGBarrCompanyName} beverages in stores or at promotional events, experiencing their flavors firsthand.`,
      },
      {
        title: "Email Marketing",
        description: `Customers might receive emails from ${AGBarrCompanyName} with detailed beverage information, special offers, or customer testimonials.`,
      },
      {
        title: "Blogs and Articles",
        description: `Customers might read blogs or articles about ${AGBarrCompanyName} beverages, providing them with more in-depth information.`,
      },
      {
        title: "Product Demonstration Videos",
        description: `Customers might watch videos showcasing ${AGBarrCompanyName} beverages, including mixing tutorials or taste tests.`,
      },
      {
        title: "Forums and Discussion Boards",
        description: `Customers might participate in online forums or discussion boards where they can learn more about ${AGBarrCompanyName} beverages from other customers.`,
      },
    ],
  },
  {
    stage: "Purchase",
    touchpoints: [
      {
        title: "Online Store",
        description: `Customers might purchase ${AGBarrCompanyName} beverages directly from the company's online store.`,
      },
      {
        title: "Physical Stores",
        description: `Customers might purchase ${AGBarrCompanyName} beverages from supermarkets, convenience stores, and specialty stores.`,
      },
      {
        title: "E-commerce Websites",
        description: `Customers might purchase ${AGBarrCompanyName} beverages from e-commerce websites like Amazon or direct from grocery delivery services.`,
      },
      {
        title: "Mobile Apps",
        description: `Customers might purchase ${AGBarrCompanyName} beverages through mobile shopping apps from grocery retailers.`,
      },
      {
        title: "Customer Service Interactions",
        description: `Customers might purchase ${AGBarrCompanyName} beverages through interactions with customer service representatives, either in-store, online, or over the phone.`,
      },
      {
        title: "Email Marketing",
        description: `Customers might purchase ${AGBarrCompanyName} beverages after receiving a promotional email with a special offer or discount.`,
      },
      {
        title: "Social Media Shopping",
        description: `Customers might purchase ${AGBarrCompanyName} beverages directly through social media platforms that offer shopping features (e.g., Instagram Shopping).`,
      },
      {
        title: "Subscription Services",
        description: `Customers might purchase ${AGBarrCompanyName} beverages through a subscription service that delivers their favorite drinks on a regular basis.`,
      },
      {
        title: "In-Store Promotions",
        description: `Customers might purchase ${AGBarrCompanyName} beverages after seeing in-store promotions or discounts.`,
      },
      {
        title: "Point of Sale Displays",
        description: `Customers might make impulse purchases of ${AGBarrCompanyName} beverages after seeing them at the checkout area in a store.`,
      },
    ],
  },
  {
    stage: "Use",
    touchpoints: [
      {
        title: "Product Use",
        description: `Customers interact with ${AGBarrCompanyName} beverages directly as they enjoy the drinks.`,
      },
      {
        title: "User Manuals",
        description: `Customers might refer to instructions or recipes provided by ${AGBarrCompanyName} to enjoy their beverages in the best way possible.`,
      },
      {
        title: "Customer Support",
        description: `Customers might contact customer support for assistance with product inquiries or to provide feedback on the beverages.`,
      },
      {
        title: "Product Updates",
        description: `Customers might receive information about new flavors, limited editions, or product improvements.`,
      },
      {
        title: "Online Tutorials",
        description: `Customers might watch online tutorials or instructional videos on how to mix cocktails or mocktails using ${AGBarrCompanyName} products.`,
      },
      {
        title: "Community Forums",
        description: `Customers might participate in community forums where they can share recipes, tips for beverage enjoyment, and connect with other ${AGBarrCompanyName} enthusiasts.`,
      },
      {
        title: "Social Media Engagement",
        description: `Customers might engage with ${AGBarrCompanyName} on social media, sharing their experiences with the beverages, posting pictures, and interacting with the brand.`,
      },
      {
        title: "Email Communications",
        description: `Customers might receive emails from ${AGBarrCompanyName} with recipes, tips for enjoying the beverages, or suggestions for other products.`,
      },
      {
        title: "Accessories and Add-Ons",
        description:
          "Customers might purchase accessories or related products, like branded glasses or cocktail kits, enhancing their beverage experience.",
      },
      {
        title: "Product Packaging",
        description:
          "Customers might interact with the product packaging, which can influence their experience and perception of the beverage.",
      },
    ],
  },
  {
    stage: "Loyalty",
    touchpoints: [
      {
        title: "Repeat Purchases",
        description: `Customers might make repeat purchases of ${AGBarrCompanyName} beverages, demonstrating their loyalty to the brand.`,
      },
      {
        title: "Customer Reviews",
        description: `Loyal customers might write positive reviews of ${AGBarrCompanyName} beverages, sharing their experiences with other potential customers.`,
      },
      {
        title: "Word of Mouth",
        description: `Loyal customers might recommend ${AGBarrCompanyName} beverages to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.`,
      },
      {
        title: "Social Media Advocacy",
        description: `Loyal customers might share their positive experiences with ${AGBarrCompanyName} beverages on social media, acting as brand advocates.`,
      },
      {
        title: "Customer Loyalty Programs",
        description: `Customers might participate in ${AGBarrCompanyName}'s customer loyalty programs, earning rewards for their purchases and engagement with the brand.`,
      },
      {
        title: "Email Marketing",
        description: `Loyal customers might engage with ${AGBarrCompanyName}'s email marketing campaigns, staying informed about new products, promotions, and events.`,
      },
      {
        title: "Community Engagement",
        description: `Loyal customers might participate in ${AGBarrCompanyName}'s online communities, engaging with other customers and the brand.`,
      },
      {
        title: "Customer Surveys",
        description: `Loyal customers might participate in customer surveys, providing valuable feedback to help ${AGBarrCompanyName} improve its products and services.`,
      },
      {
        title: "Brand Events",
        description: `Loyal customers might attend ${AGBarrCompanyName}'s brand events, such as product launches, tastings, or workshops.`,
      },
      {
        title: "Personalized Marketing",
        description: `Loyal customers might receive personalized marketing communications from ${AGBarrCompanyName}, recognizing their loyalty and providing tailored offers and recommendations.`,
      },
    ],
  },
  {
    stage: "Advocacy",
    touchpoints: [
      {
        title: "Word of Mouth",
        description: `Customers who have become advocates might actively recommend ${AGBarrCompanyName} beverages to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.`,
      },
      {
        title: "Social Media Advocacy",
        description: `Advocates might share their positive experiences with ${AGBarrCompanyName} beverages on social media, acting as brand ambassadors and influencing their followers.`,
      },
      {
        title: "Customer Reviews",
        description: `Advocates might write glowing reviews of ${AGBarrCompanyName} beverages on e-commerce websites, review sites, and forums, helping to build trust and credibility forthe brand.`,
      },
      {
        title: "Customer Testimonials",
        description: `Advocates might provide testimonials for ${AGBarrCompanyName}, sharing their stories and experiences with the brand's beverages.`,
      },
      {
        title: "Brand Events",
        description: `Advocates might attend and participate in ${AGBarrCompanyName}'s brand events, such as product launches, tastings, or workshops, and share their experiences on social media.`,
      },
      {
        title: "User-Generated Content",
        description: `Advocates might create and share their own content related to ${AGBarrCompanyName} beverages, such as cocktail recipes, taste tests, or unboxing videos, on social media and other platforms.`,
      },
      {
        title: "Customer Referral Programs",
        description: `Advocates might participate in ${AGBarrCompanyName}'s customer referral programs, referring new customers to the brand in exchange for rewards.`,
      },
      {
        title: "Community Engagement",
        description: `Advocates might actively participate in ${AGBarrCompanyName}'s online communities, helping to answer questions, share tips and tricks, and engage with other customers.`,
      },
      {
        title: "Brand Partnerships",
        description: `Advocates might partner with ${AGBarrCompanyName} on collaborations, such as sponsored posts, product collaborations, or co-branded campaigns.`,
      },
      {
        title: "Customer Surveys and Feedback",
        description: `Advocates might provide valuable feedback to ${AGBarrCompanyName} through customer surveys, focus groups, or other feedback channels, helping the brand to improve its products and services.`,
      },
    ],
  },
];

const AGBarrFunctions = [
  {
    role: "Beverage Formulation Scientist",
    mainActivities: [
      {
        activityTitle: "Product Development",
        activityDescription:
          "Develop and refine beverage formulas to create new products or improve existing ones, ensuring optimal taste, stability, and compliance with food safety standards.",
      },
      {
        activityTitle: "Ingredient Research",
        activityDescription:
          "Investigate and source new ingredients that can enhance flavor profiles or offer health benefits, while maintaining cost-effectiveness.",
      },
      {
        activityTitle: "Quality Control",
        activityDescription:
          "Conduct rigorous testing and evaluation of products at various stages of development to ensure consistency and quality.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Regulatory Compliance",
        painPointDescription:
          "Navigating complex and ever-changing food and beverage regulations can be challenging and time-consuming.",
      },
      {
        painPointTitle: "Ingredient Sourcing",
        painPointDescription:
          "Finding reliable suppliers that meet quality and sustainability standards for new and exotic ingredients often poses logistical challenges.",
      },
      {
        painPointTitle: "Balancing Innovation with Tradition",
        painPointDescription:
          "Struggling to innovate within the confines of a well-established brand identity, especially one as iconic as Irn-Bru, without alienating existing customers.",
      },
    ],
  },
  {
    role: "Production Line Supervisor",
    mainActivities: [
      {
        activityTitle: "Operations Management",
        activityDescription:
          "Oversee the day-to-day operations of the production line, ensuring machinery runs smoothly and efficiently while meeting production targets.",
      },
      {
        activityTitle: "Staff Coordination",
        activityDescription:
          "Manage a team of line workers, scheduling shifts, and training staff on new procedures and safety protocols.",
      },
      {
        activityTitle: "Maintenance Oversight",
        activityDescription:
          "Coordinate regular maintenance and troubleshooting of production equipment to minimize downtime.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Equipment Downtime",
        painPointDescription:
          "Unexpected machinery breakdowns can lead to production halts, affecting output and deadlines.",
      },
      {
        painPointTitle: "Quality Control Issues",
        painPointDescription:
          "Ensuring consistent product quality across large batches can be challenging, particularly when dealing with high volumes.",
      },
      {
        painPointTitle: "Staff Retention",
        painPointDescription:
          "High turnover rates in factory settings require continuous recruitment and training, which can be time-consuming and affect continuity.",
      },
    ],
  },
  {
    role: "Digital Marketing Specialist",
    mainActivities: [
      {
        activityTitle: "Campaign Management",
        activityDescription:
          "Plan and execute digital marketing campaigns across various platforms (social media, email, web) to boost brand visibility and product sales.",
      },
      {
        activityTitle: "Content Creation",
        activityDescription:
          "Develop engaging content that resonates with different demographics, including video, blogs, and interactive media.",
      },
      {
        activityTitle: "Data Analysis",
        activityDescription:
          "Monitor and analyze campaign performance using digital analytics tools to refine marketing strategies and improve ROI.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Adapting to Platform Changes",
        painPointDescription:
          "Keeping up with frequent changes in digital platform algorithms can make it challenging to maintain campaign effectiveness.",
      },
      {
        painPointTitle: "Budget Constraints",
        painPointDescription:
          "Balancing ambitious marketing goals with limited budget resources, especially in competitive beverage markets.",
      },
      {
        painPointTitle: "ROI Justification",
        painPointDescription:
          "Demonstrating the financial impact of creative campaigns to upper management can be difficult, particularly when direct links between marketing efforts and sales are not clear.",
      },
    ],
  },
  {
    role: "Sustainability Coordinator",
    mainActivities: [
      {
        activityTitle: "Sustainability Initiatives",
        activityDescription:
          "Lead and develop company-wide sustainability projects to reduce environmental impact, focusing on areas like recycling, waste management, and energy efficiency.",
      },
      {
        activityTitle: "Stakeholder Engagement",
        activityDescription:
          "Collaborate with internal and external stakeholders to promote sustainability goals and integrate eco-friendly practices across the supply chain.",
      },
      {
        activityTitle: "Reporting and Compliance",
        activityDescription:
          "Ensure compliance with environmental regulations and prepare reports on sustainability performance for regulatory bodies and corporate communications.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Resource Allocation",
        painPointDescription:
          "Securing adequate resources and budget for sustainability projects can be difficult, especially when competing against more immediate business priorities.",
      },
      {
        painPointTitle: "Change Management",
        painPointDescription:
          "Encountering resistance to new initiatives and practices from within the organization, requiring significant efforts in education and change management strategies.",
      },
      {
        painPointTitle: "Measurement Challenges",
        painPointDescription:
          "Quantifying the impact of sustainability initiatives and presenting them in a meaningful way to stakeholders who may prioritize short-term gains over long-term environmental benefits.",
      },
    ],
  },
  {
    role: "Customer Experience Manager",
    mainActivities: [
      {
        activityTitle: "Feedback Analysis",
        activityDescription:
          "Monitor and analyze customer feedback across various channels to identify areas for improvement in product offerings and service.",
      },
      {
        activityTitle: "Customer Engagement",
        activityDescription:
          "Design and implement strategies to enhance customer engagement and loyalty, including personalized communication and reward programs.",
      },
      {
        activityTitle: "Service Improvement",
        activityDescription:
          "Work closely with sales and marketing teams to ensure that customer service practices align with the overall brand strategy and enhance customer satisfaction.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Customer Expectations",
        painPointDescription:
          "Managing increasingly high customer expectations, especially in a competitive beverage market where consumers have numerous choices.",
      },
      {
        painPointTitle: "Cross-Department Coordination",
        painPointDescription:
          "Coordinating with multiple departments to implement customer experience improvements can be challenging and time-consuming.",
      },
      {
        painPointTitle: "Data Overload",
        painPointDescription:
          "Handling large volumes of customer data and extracting actionable insights without getting overwhelmed by the sheer amount of feedback.",
      },
    ],
  },
  {
    role: "Supply Chain Analyst",
    mainActivities: [
      {
        activityTitle: "Supply Chain Optimization",
        activityDescription:
          "Analyze supply chain processes to identify inefficiencies and recommend improvements to enhance speed, reduce costs, and improve reliability.",
      },
      {
        activityTitle: "Inventory Management",
        activityDescription:
          "Monitor inventory levels across various locations to ensure optimal stock is maintained to meet production and distribution needs.",
      },
      {
        activityTitle: "Vendor Management",
        activityDescription:
          "Manage relationships with suppliers and vendors to negotiate better terms and ensure timely delivery of materials.",
      },
    ],
    painPoints: [
      {
        painPointTitle: "Supply Disruptions",
        painPointDescription:
          "Navigating the challenges of supply chain disruptions which can arise from external factors like global market fluctuations or local issues.",
      },
      {
        painPointTitle: "Complexity in Sourcing",
        painPointDescription:
          "Dealing with the complexities of sourcing ingredients from diverse geographical locations, which can involve regulatory, logistical, and quality control challenges.",
      },
      {
        painPointTitle: "Technology Integration",
        painPointDescription:
          "Implementing new supply chain technologies can be difficult, with challenges in training staff and integrating systems seamlessly into existing processes.",
      },
    ],
  },
];

const AGBarrQuiz = {
  question: `Which statement below is correct:`,
  answers: [
    {
      answer: "RAG speeds up the training process.",
      correct: false,
    },
    {
      answer: "RAG can improve the accuracy of the LLMs response.",
      correct: true,
    },
    {
      answer: "RAG helps to generate images of higher quality.",
      correct: false,
    },
    {
      answer:
        "RAG is a new programming language that speeds-up the LLMs response time.",
      correct: false,
    },
  ],
  correctAnswerDescription: `Your answer is correct, RAG (Retrieval AUgmented Generation) is a model that combines the strengths of retrieval-based and generation-based models to improve the accuracy of the response. It uses a retrieval mechanism to select relevant information and a generation mechanism to produce the final response. This approach can enhance the quality and relevance of the generated content by leveraging pre-existing knowledge and context.`,
};

const AGBarrGenAiUseCases = [
  {
    title: "Rapid Packaging Design Prototyping & A/B Testing",
    description: `Use text-to-image AI to rapidly generate new product packaging designs and labels for ${AGBarrCompanyName}'s 30+ soft drink brands, allowing faster prototyping and A/B testing of designs.`,
    desirability: 4,
    feasibility: 8,
    imageUrl: Concept1,
  },
  {
    title: `Daily inspirational podcast for ${AGBarrCompanyName} employees created automatically by GenAI`,
    description:
      "A daily podcast generator that uses webscraping tools, text-to-text, and text-to-speech AI capabilities to create inspirational content for employees based on their interests and function, including industry news, company updates, and recent research papers.",
    desirability: 6,
    feasibility: 8,
    imageUrl: Concept2,
  },
  {
    title: "Hyper-personalized beverages @ point-of-sale",
    description:
      "A vending machine capable of creating hyper-personalized beverages at the point-of-sale (e.g. at fitness centers) by using generative AI to customize flavors, ingredients, nutrients, packaging artwork based on an individual's available data (e.g. workout plan or workout performance).",
    desirability: 7,
    feasibility: 3,
    imageUrl: Concept3,
  },
  {
    title: "AI-powered Drink Recipe Suggestion & Food Pairing Tool",
    description: `Create an AI-powered drink recipe suggestion and food pairing web tool, where users input ingredients or occasions and get tailored drink,mocktail, and food-pairing ideas using ${AGBarrCompanyName} products.`,
    desirability: 3,
    feasibility: 9,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Sample distribution via AI-powered Vending Machines",
    description:
      "Vending Machine which distributes free samples when people take a picture at the vending machine. The vending machine uses AI for face recognition and fun AI image filters and stylings to share on socials.",
    desirability: 4,
    feasibility: 3,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Digital consumer twins for rapid concept validation",
    description:
      "Use GenAI synthetic consumer twins to quickly test new product, packaging concepts, flavors, and marketing campaigns with a diverse range of consumer personas.",
    desirability: 5,
    feasibility: 6,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "AI-Competitor Intelligence Briefs for Brand Strategy",
    description: `Use AI to monitor news and market reports to automatically produce executive-ready competitive intelligence briefs and summarize implications for ${AGBarrCompanyName}'s brands and strategy.`,
    desirability: 4,
    feasibility: 9,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 5,
    feasibility: 5,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 2,
    feasibility: 3,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 2,
    feasibility: 6,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 5,
    feasibility: 3,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 1,
    feasibility: 8,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 2,
    feasibility: 9,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 2,
    feasibility: 1,
    imageUrl: ImagePlaceholder,
  },
  {
    title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
    description: `Analyze social media images at scale using AI to identify ${AGBarrCompanyName} products, usage occasions, and consumer sentiment to inform marketing and product development.`,
    desirability: 1,
    feasibility: 1,
    imageUrl: ImagePlaceholder,
  },
];

const AGBarrUserValidationResults = {
  imageUrl: Concept2,
  introText: `The proof-of-concept testing for the Daily Podcast Generator was conducted with a group of 50 employees across various departments within ${AGBarrCompanyName} UK. The primary objectives were to evaluate user engagement, satisfaction, and overall experience with the generated content, tailored to their interests and job functions. The content included industry news, company updates, and summaries of recent research papers.`,
  methodologyTitle: "Methodology",
  methodologyDescription: `The testing was carried out over a two-week period, where each participant received a daily podcast every morning. The podcasts were generated using the latest web scraping tools, text-to-text, and text-to-speech AI technologies to ensure relevance and quality. Observational data was collected through digital engagement metrics, and a post-experiment survey was administered to gather feedback on the content's relevance, presentation quality, and usefulness.`,
  engagementTitle: "User Engagement",
  engagementDescription: [
    `42 out of 50 employees consistently listened to the daily podcasts, with many reporting it became a part of their morning routine.`,
    `The remaining 8 employees listened sporadically, citing preferences for text updates or the lack of time as the main reasons for lower engagement.`,
  ],
  participantsTitle: "Participants",
  participantsDescription: [
    `Introduction: Employees were briefed about the purpose of the podcast and how it was tailored to their interests.`,
    `Daily Use: Participants received podcasts via email or a preferred communication tool, designed to seamlessly integrate into their morning routine.`,
    `Feedback Collection: Throughout the testing period, participants were encouraged to provide immediate feedback on each podcast's relevance and quality.`,
    `Survey: At the end of the two weeks, a comprehensive survey collected detailed feedback on their experience.`,
  ],
  satisfactionTitle: "User Satisfaction",
  satisfactionDescription: [
    `47 out of 50 employees reported enjoying the personalized content and found the podcasts to be a valuable source of information.`,
    `Many employees appreciated the concise format and relevance to their specific job functions, with feedback highlighting the blend of industry news and company updates.`,
    `A small group suggested improvements for the way sources were cited and an improved functionality to deepdive into some topics if wanted.`,
  ],
  recommendationTitle: "Recommendations",
  recommendationIntro: "Based on the findings, it is recommended to:",
  recommendationDescription: [
    `Further personalize the podcasts by refining AI algorithms to better match employee interests and job functions.`,
    `Enhance the text-to-speech technology to improve voice quality and inflection, making the podcasts more engaging and pleasant to listen to.`,
    `Expand the content sources to include more diverse and comprehensive industry news, research summaries, and actionable insights.`,
    `Conduct further testing with a larger and more diverse group of employees to refine content delivery and engagement strategies.`,
    `Explore interactive features that allow employees to select topics of interest or skip segments, providing a more customizable experience.`,
  ],
  recommendationOutro: `Overall, the Daily Podcast Generator demonstrated significant potential in enhancing employee engagement and providing personalized, relevant content. With targeted improvements and expanded testing, this tool could become a valuable asset for companies looking to foster a more informed and connected workforce.`,
};

const AGBarrPocFeatures = [
  {
    title: "Web scraping and data extraction",
    description:
      "Ability to scrape the web and extract relevant data of news articles, research papers, and industry reports.",
    prioritization: "Must-Have",
    techStack: [Serper, BeautifulSoup],
  },
  {
    title: "Data summarization and insights generation (text-to-text)",
    description:
      "Use a fine-tuned large language model with capability to generate contextually relevant, engaging, an dinspiring podcast stories based on the extracted data and employees function and preferences.",
    prioritization: "Must-Have",
    techStack: [OpenAIGPT4, LangChain],
  },
  {
    title: "Podcast Voice (Text-to-Speech)",
    description:
      "Model that can produce a Natural-sounding, friendly voice modulation to provide the latest news and insights in a captivating, vocal way.",
    prioritization: "Must-Have",
    techStack: [Whisper, ElevenLabs],
  },
  {
    title: "Content Moderation and Safety",
    description: `System to filter inappropriate content, compliant with ${AGBarrCompanyName}'s values and company guidelines. `,
    prioritization: "Must-Have",
    techStack: [OpenAIGPT4, LangChain],
  },
];

export {
  AGBarrCompanyName,
  AGBarrChallengeDefinition,
  AGBarrEvaluationCriteria,
  AGBarrTouchpoints,
  AGBarrGenAiUseCases,
  AGBarrQuiz,
  AGBarrUserValidationResults,
  AGBarrPocFeatures,
  AGBarrFunctions,
};
