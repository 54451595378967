import React, { useState } from "react";
import {
  faStore,
  faTruck,
  faBox,
  faPercent,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import css style sheets
import "./BBCustomerOccasions.css";

export default function BBCustomerOccasions({ chosenKnowledgeBaseItem }) {
  return (
    <div className='BBCustomerOccasions'>
      <h5 className='BBCustomerOccasions-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
    </div>
  );
}
