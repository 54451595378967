import React, { useState, useRef } from "react";
import "./ChatbotPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Configuration, OpenAIApi } from "openai";
import howToFlyContextData from "../howToFlyContextData.js";

import { db } from "../firebase/config";
import { collection, addDoc } from "firebase/firestore";

import { Swiper, SwiperSlide } from "swiper/react";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

const inspirationCards = [
  {
    title: "Bespoke Jewelry Designer",
    text: "I'm a bespoke jewelry designer who designs and creates custom golden jewelry for my clients. My brand is known for its minimalistic, though edgy style.",
  },
  {
    title: "Athletic Apparel Corporation",
    text: "I'm consumer engagement manager at a Athletic apparel corporation. My role is to continuously be on the look-out for ways to engage our customers in a more personalized way.",
  },
  {
    title: "Board Game Publisher",
    text: "As innovation manager at a board game publisher, my role is to identify new trends in the board game industry and to come up with new concepts that can help us stay ahead of the competition.",
  },
  {
    title: "Healthcare Facility",
    text: "I'm director at a local network of healthcare facilities. Our role is to provide our patients with the best possible care with available resources.",
  },
  {
    title: "Energy Supplier",
    text: "As a product manager at a large energy supplier, my role is to identify new ways to engage our customers in a more personalized way.",
  },
  {
    title: "Educational Institution",
    text: "I'm a director at a large educational institution. My role is to identify new ways to augment the quality of our educational programs and to improve the student experience.",
  },
];

/* Start of ChatbotPage component --------------------------------------------------------
---------------------------------------------------------
-------------------------------------------------------
------------------------------------------------------*/

function ChatbotPage() {
  const [input, setInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [AIResponse, setAIResponse] = useState(null);
  const [submittedFavoriteUseCase, setSubmittedFavoriteUseCase] =
    useState(false);
  const [greenVotedUseCase, setGreenVotedUseCase] = useState(false);
  const inputRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [useCaseSwiperIndex, setUseCaseSwiperIndex] = useState(0);
  const [
    requestHowToUseCaseManualSubmitted,
    setRequestHowToUseCaseManualSubmitted,
  ] = useState(false);
  const handleChangeSwiperIndex = (i) => {
    setSwiperIndex(i);
  };
  const handleChangeUseCaseSwiperIndex = (i) => {
    setUseCaseSwiperIndex(i);
  };

  const resizeTextArea = (ref) => {
    ref.style.height = "inherit"; // Reset the height
    ref.style.height = `${ref.clientHeight}px`; // Set the height based on clientHeight
  };

  const uploadToFirestore = async (prompt, response) => {
    try {
      await db.collection("chatbotlogs").add({
        prompt,
        response,
        timestamp: Date(),
      });
    } catch (error) {
      console.error(
        "Something went wrong, when trying to upload the data to firestore",
        error
      );
    }
  };

  const uploadToFirestoreUseCaseCollection = async (role, useCaseResponse) => {
    const generatedUseCaseCollection = collection(
      db,
      "generatedUseCaseCollection"
    );
    try {
      const docRef = await addDoc(generatedUseCaseCollection, {
        role,
        useCaseResponse,
        timestamp: Date(),
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error(
        "Something went wrong, when trying to upload the data to firestore",
        error
      );
    }
  };

  const uploadToFirestoreUseCaseRequestCollection = async (
    role,
    useCaseResponse,
    email
  ) => {
    const useCaseRequestCollection = collection(db, "useCaseRequests");
    try {
      const docRef = await addDoc(useCaseRequestCollection, {
        role: input,
        useCaseResponse,
        timestamp: Date(),
        email: email,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error(
        "Something went wrong, when trying to upload the data to firestore",
        error
      );
    }
  };

  const handleInput = (e) => {
    setInput(e.target.value);
    resizeTextArea(e.target);
  };

  const handleEmailInput = (e) => {
    setEmailInput(e.target.value);
    resizeTextArea(e.target);
  };

  const handleInspirationCardClick = (e) => {
    const cardText = e.currentTarget.getElementsByTagName("p")[0].textContent;
    setInput(cardText);
    // After a short delay, resize the textarea
    setTimeout(() => {
      if (inputRef.current) {
        resizeTextArea(inputRef.current);
      }
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const configuration = new Configuration({
      organization: "org-h1bJK5iOzVXDfN6bVEer5gaP",
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });
    setAIResponse([
      { title: "Use Case 1", description: "Loading...", greenVoted: false },
      { title: "Use Case 2", description: "Loading...", greenVoted: false },
      { title: "Use Case 3", description: "Loading...", greenVoted: false },
      { title: "Use Case 4", description: "Loading...", greenVoted: false },
      { title: "Use Case 5", description: "Loading...", greenVoted: false },
      { title: "Use Case 6", description: "Loading...", greenVoted: false },
      { title: "Use Case 7", description: "Loading...", greenVoted: false },
      { title: "Use Case 8", description: "Loading...", greenVoted: false },
    ]);
    try {
      const openai = new OpenAIApi(configuration);
      const trainingDataStr = howToFlyContextData
        .map((data) => data.prompt + data.completion)
        .join("");
      const finalMessage =
        `Please create 8 ideas how generative AI (text-to-text, text-to-image, text-to-video, image-to-text, image-to-image, image-to-video, deep-fake, autonomous agents, etc...) can help to execute the job description below? Please try to make it very easy to ready even for less technical people. Make sure the description always starts with a very simple sentence that everyone can easily understand highlighting the value of AI in this use case. Please always return an array in JSON format of 8 objects. Each object should contain an id, a title, a description, greenVoted which should always be false, and edited which should also always be false. For example: [{"id": 1, "title": "Jewelry Designer", "description": "A custom fine-tuned text-to-image generator allowing customers to design their own bespoke jewelry.", "greenVoted":false, "edited":false},{"id": 2, "title": "Social Media Assistant", "description": "An AI assitant who proposes new posts based on jewelry trends seen on instagram", "greenVoted":false, "edited":false}]` +
        input;
      setInput("");
      const completion = await openai.createChatCompletion({
        model: "gpt-4",
        messages: [{ role: "user", content: finalMessage }],
      });
      const AIresponse = completion.data.choices[0].message.content;
      try {
        const parsedResponse = JSON.parse(AIresponse);
        setAIResponse(parsedResponse);
        console.log("parsedResponse", parsedResponse);
        await uploadToFirestoreUseCaseCollection(input, parsedResponse);
        console.log("parsedResponse was uploaded to firestore");
      } catch (error) {
        const parsedResponse = [
          {
            title: "Sorry, something went wrong",
            description:
              "Sorry but we're unable to create use cases based on your provided input. Please try again.",
            greenVoted: false,
            edited: false,
          },
        ];
        setAIResponse(parsedResponse);
        console.error(
          "Something went wrong when trying to parse the response from OpenAI"
        );
        await uploadToFirestore(input, parsedResponse);
        console.error(
          "Standard something went wrong useCase was uploaded to firestore"
        );
      }
    } catch (error) {
      console.error("Something went wrong, please try again later", error);
      setAIResponse(
        "We're very sorry. Something went wrong, please try again later"
      );
      await uploadToFirestore(
        input,
        "Something went wrong when trying to catch a response from OpenAI."
      );
    }
    setIsLoading(false);
  };

  const handleGreenVoteClick = (index) => {
    // Create a new array with updated greenVoted property
    setGreenVotedUseCase(true);
    const updatedAIResponse = AIResponse.map((item, idx) =>
      idx === index ? { ...item, greenVoted: !item.greenVoted } : item
    );
    // Update the state
    setAIResponse(updatedAIResponse);
  };

  const handleGreenVoteSubmit = async () => {
    setSubmittedFavoriteUseCase(true);
  };

  const handleSubmitRequestToReceiveHowToUseCaseManual = async (
    submittedEmailAdress
  ) => {
    await uploadToFirestoreUseCaseRequestCollection(
      input,
      AIResponse.filter((item) => item.greenVoted === true),
      submittedEmailAdress
    );
    setRequestHowToUseCaseManualSubmitted(true);
  };

  return (
    <div className='ChatbotPage' id='howToAssistant'>
      <div className='ChatbotPage-content'>
        <h1 className='ChatbotPage-title'>How To Make You Fly?</h1>
        {!AIResponse && !isLoading && !submittedFavoriteUseCase && (
          <p className='ChatbotPage-text'>
            Above are just a few use cases we turned into a Generative AI tool.{" "}
            <br />
            <br />
            Use our tool below to provide a description of your job function and
            see how AI could potentially make you fly!
          </p>
        )}
        {!AIResponse && !isLoading && !submittedFavoriteUseCase && (
          <form onSubmit={handleSubmit} className='ChatbotPage-form'>
            <textarea
              ref={inputRef}
              type='text'
              value={input}
              onChange={handleInput}
              placeholder='Type your job description below...'
            />
            <button type='submit'>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
        )}
        {!AIResponse && !isLoading && !submittedFavoriteUseCase && (
          <div className='ChatbotPage-inspiration'>
            <h1 className='ChatbotPage-inspiration-title'>
              <FontAwesomeIcon
                className='ChatbotPage-inspiration-title-icon'
                icon={faLightbulb}
              />
              Some example job descriptions
            </h1>
            <div className='ChatbotPage-inspiration-cards'>
              {inspirationCards.map((inspirationCard, cardIndex) => (
                <div
                  key={cardIndex}
                  className='ChatbotPage-inspiration-cards-card'
                  onClick={handleInspirationCardClick}
                >
                  <h5>{inspirationCard.title}</h5>
                  <p>{inspirationCard.text}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {AIResponse && isLoading && !submittedFavoriteUseCase && (
          <p className='ChatbotPage-text'>
            Our AI assistant is preparing eight custom use cases to make your
            job easier. Wait 10-30 seconds to get the results...
          </p>
        )}
        {AIResponse && !isLoading && !submittedFavoriteUseCase && (
          <p className='ChatbotPage-text'>
            Please "green vote" your favorite use case(s) and perhaps your use
            case will be prototyped in one of our upcoming experiments!
          </p>
        )}
        {AIResponse && !submittedFavoriteUseCase && (
          <div className='ChatbotPage-answers'>
            {AIResponse.map((useCase, index) => (
              <div key={index} className='ChatbotPage-answers-useCaseCard'>
                <div className='ChatbotPage-answers-useCaseCard-useCaseText'>
                  <h5 style={{ fontWeight: "800" }}>
                    {index + 1}
                    {`. `}
                    {useCase.title}
                  </h5>
                  <p>{useCase.description}</p>
                </div>
                {!isLoading && (
                  <div
                    className={
                      useCase.greenVoted === true
                        ? "ChatbotPage-answers-useCaseCard-greenVoteButton greenVoted"
                        : "ChatbotPage-answers-useCaseCard-greenVoteButton"
                    }
                    onClick={() => handleGreenVoteClick(index)}
                  >
                    {/* ... Your green vote button content ... */}
                  </div>
                )}
                {isLoading && (
                  <Spinner className='ChatbotPage-answers-useCaseCard-loadingSpinner' />
                )}
              </div>
            ))}
          </div>
        )}
        {AIResponse &&
          greenVotedUseCase &&
          submittedFavoriteUseCase &&
          !requestHowToUseCaseManualSubmitted && (
            <div className='contentBoxAfterUseCaseSubmission'>
              <p style={{ margin: "20px auto" }}>
                Thanks for submitting your favorite usecases!
              </p>
              <p>
                Interested how to turn these usecases into reality?
                <br />
                <b>Drop your email below</b> and we'll provide you with
                <b>custom-made instructions</b> to turn
                <br /> at least one of your favorite cases into a
                proof-of-concept.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <Form>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Control
                      type='email'
                      placeholder='your email'
                      style={{ height: "40px" }}
                      value={emailInput}
                      onChange={handleEmailInput}
                    ></Form.Control>
                  </Form.Group>
                </Form>
                <Button
                  type='submit'
                  style={{
                    height: "40px",
                    background: "black",
                    border: "black",
                  }}
                  onClick={() =>
                    handleSubmitRequestToReceiveHowToUseCaseManual(emailInput)
                  }
                >
                  Submit
                </Button>
              </div>
              <h4 style={{ fontWeight: "800", marginTop: "40px" }}>
                Your{" "}
                {`${
                  AIResponse.filter((item) => item.greenVoted === true).length
                }`}{" "}
                Use Cases
              </h4>
              <div
                className='ChatbotPage-selectedCases'
                style={{ marginTop: "0px", width: "100%" }}
              >
                <Swiper
                  className='ChatbotPage-selectedCases-swiper'
                  pagination={{ type: "bullets" }}
                  index={useCaseSwiperIndex}
                  onSlideChange={(swiper) => {
                    handleChangeUseCaseSwiperIndex(swiper.activeIndex);
                  }}
                  slidesPerView={1}
                  autoplay={{ delay: 10000 }}
                >
                  {AIResponse.filter((item) => item.greenVoted === true).map(
                    (useCase, cardIndex) => (
                      <SwiperSlide key={cardIndex} className='swiper-slide'>
                        <div
                          key={cardIndex}
                          className='ChatbotPage-answers-useCaseCard'
                          style={{
                            width: "86%",
                            margin: "30px auto",
                            marginBottom: "80px",
                          }}
                        >
                          <div className='ChatbotPage-answers-useCaseCard-useCaseText'>
                            <h5 style={{ fontWeight: "800" }}>
                              {cardIndex + 1}
                              {`. `}
                              {useCase.title}
                            </h5>
                            <p>{useCase.description}</p>
                          </div>
                          {!isLoading && (
                            <div
                              className={
                                useCase.greenVoted === true
                                  ? "ChatbotPage-answers-useCaseCard-greenVoteButton greenVoted"
                                  : "ChatbotPage-answers-useCaseCard-greenVoteButton"
                              }
                              style={{ cursor: "auto" }}
                            >
                              {/* ... Your green vote button content ... */}
                            </div>
                          )}
                          {isLoading && (
                            <Spinner className='ChatbotPage-answers-useCaseCard-loadingSpinner' />
                          )}
                        </div>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
            </div>
          )}
        {AIResponse &&
          submittedFavoriteUseCase &&
          requestHowToUseCaseManualSubmitted && (
            <div>
              <p
                style={{ color: "black", height: "70vh", textAlign: "center" }}
              >
                Thanks for submitting your email address. <br />
                <br />
                We'll get back to you soon with a custom-made approach to turn
                at least one of your use cases into a POC.
              </p>
            </div>
          )}
        {/* Mobile version --------------------------------------*/}
        {!AIResponse && !isLoading && !submittedFavoriteUseCase && (
          <div className='ChatbotPage-inspiration-mobile'>
            <h1 className='ChatbotPage-inspiration-title'>
              <FontAwesomeIcon
                className='ChatbotPage-inspiration-title-icon'
                icon={faLightbulb}
              />
              Some inspiration for job descriptions
            </h1>
            <div className='ChatbotPage-inspiration-mobile-cards'>
              <Swiper
                style={{ width: "100%" }}
                pagination={{ type: "bullets" }}
                index={swiperIndex}
                onSlideChange={(swiper) => {
                  handleChangeSwiperIndex(swiper.activeIndex);
                }}
                slidesPerView={1}
                autoplay={{ delay: 10000 }}
              >
                {inspirationCards.map((inspirationCard, cardIndex) => (
                  <SwiperSlide key={cardIndex} className='swiper-slide'>
                    <div
                      key={cardIndex}
                      className='ChatbotPage-inspiration-mobile-cards-card'
                      onClick={handleInspirationCardClick}
                    >
                      <h5>{inspirationCard.title}</h5>
                      <p>{inspirationCard.text}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
        {AIResponse && !submittedFavoriteUseCase && (
          <div className='ChatbotPage-answers-mobile'>
            <Swiper
              style={{ width: "100%" }}
              pagination={{ type: "bullets" }}
              index={useCaseSwiperIndex}
              onSlideChange={(swiper) => {
                handleChangeUseCaseSwiperIndex(swiper.activeIndex);
              }}
              slidesPerView={1}
              autoplay={{ delay: 10000 }}
            >
              {AIResponse.map((useCase, cardIndex) => (
                <SwiperSlide key={cardIndex} className='swiper-slide'>
                  <div
                    key={cardIndex}
                    className='ChatbotPage-answers-useCaseCard'
                  >
                    <div className='ChatbotPage-answers-useCaseCard-useCaseText'>
                      <h5 style={{ fontWeight: "800" }}>
                        {cardIndex + 1}
                        {`. `}
                        {useCase.title}
                      </h5>
                      <p style={{ marginTop: "30px" }}>{useCase.description}</p>
                    </div>
                    {!isLoading && (
                      <div
                        className={
                          useCase.greenVoted === true
                            ? "ChatbotPage-answers-useCaseCard-greenVoteButton greenVoted"
                            : "ChatbotPage-answers-useCaseCard-greenVoteButton"
                        }
                        onClick={() => handleGreenVoteClick(cardIndex)}
                      >
                        {/* ... Your green vote button content ... */}
                      </div>
                    )}
                    {isLoading && (
                      <Spinner className='ChatbotPage-answers-useCaseCard-loadingSpinner' />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
        {AIResponse &&
          AIResponse.filter((item) => item.greenVoted === true).length > 0 &&
          !submittedFavoriteUseCase && (
            <div className='submitFavoriteUseCaseButtonRow'>
              <button onClick={() => handleGreenVoteSubmit()}>Submit</button>
            </div>
          )}
      </div>
    </div>
  );
}

export default ChatbotPage;
