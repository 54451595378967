import React, { useState } from "react";
import {
  faStore,
  faTruck,
  faBox,
  faPercent,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import css style sheets
import "./BBCardDeepDives.css";

export default function BlackboxCIKnowledgeBaseRoles({
  chosenKnowledgeBaseItem,
}) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <img src={chosenKnowledgeBaseItem.imageUrl} className='imageStyling' />
      <h2 className='cardDeepDiveTitle'>Key Activities</h2>
      {chosenKnowledgeBaseItem.mainActivities.map((activity) => (
        <div className='cardDeepDiveCard'>
          <h4>{activity.activityTitle}</h4>
          <p>{activity.activityDescription}</p>
          <div className='addToDeckPlusButton'>+</div>
        </div>
      ))}
    </div>
  );
}
