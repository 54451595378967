// import images
import LosLoposMango from "../../assets/webpKB/LosLoposMango.webp";
import LosLoposCherry from "../../assets/webpKB/LosLoposCherry.webp";
import LosLoposKiwi from "../../assets/webpKB/LosLoposKiwi.webp";
import LosLoposWine from "../../assets/webpKB/LosLoposWine.webp";
import LosLoposWineLavender from "../../assets/webpKB/LosLoposWineLavender.webp";
import LosLoposWineLime from "../../assets/webpKB/LosLoposWineLime.webp";
import Monopoly from "../../assets/products/Monopoly.webp";
import Cluedo from "../../assets/products/Cluedo.jpg";
import Risk from "../../assets/products/Risk.jpg";
import GameOfLife from "../../assets/products/GameOfLife.jpg";
import Catan from "../../assets/products/Catan.jpg";
import TicketToRide from "../../assets/products/TicketToRide.jpg";
import Scrabble from "../../assets/products/Scrabble.jpg";
import Pictionary from "../../assets/products/Pictionary.jpg";
import Chess from "../../assets/products/Chess.jpg";

const companySKUsIntro = {
  title: "Our SKUs",
  description: [
    "Perhaps GenAI can help to augment the customer experience for a specific product in our portfolio. So let's have a look at the SKUs in our portfolio, their brand values, their target consumers, and the key occasions during which they are currently consumed.",
  ],
};

const companySKUs = [
  {
    cardTitle: "Boardgames",
    cardType: "SKU",
    cardSubTitle: "Monopoly",
    imageUrl: Monopoly,
    deepDiveTopics: [
      {
        topic: "Game Description",
        subTopics: [
          "Monopoly is a popular board game centered around money management, real estate, and strategic investment. The objective of the game is to remain financially solvent while forcing opponents into bankruptcy by buying and developing pieces of property.",
        ],
      },
      {
        topic: "Game Rules",
        subTopics: [
          "1.Setup: Each player starts with a fixed amount of money. The game board consists of spaces, each representing properties, utilities, taxes, and various events (like Chance and Community Chest cards).",
          "2. Gameplay: Players take turns rolling two six-sided dice to move around the board, buying or trading properties, and developing them with houses and hotels. Rent is collected from opponents who land on the player’s properties, and the amount varies depending on how much the property is developed.",
          "3. Bankruptcy: Players can go bankrupt if they cannot pay their debts, either to other players or to the Bank, and must sell off assets or mortgage properties. If they cannot cover the debt even then, they are out of the game.",
        ],
      },
      {
        topic: "Game Dynamics",
        subTopics: [
          "1.Trading: Players can trade properties among themselves at negotiated prices, which adds a layer of strategy and negotiation.",
          "2. Chance and Community Chest: These cards can either benefit the player (like receiving money or free properties) or cause setbacks (like paying fines or going to jail).",
          "3. Jail: Players can be sent to jail and must either pay a fine, use a “Get Out of Jail Free” card, or roll doubles on the dice to escape.",
        ],
      },
      {
        topic: "Game Objectives",
        subTopics: [
          "1.Monopolize: Owning all the properties in a single color group allows a player to build houses and hotels, which greatly increases the rent other players must pay when they land there.",
          "2.Manage Assets: Players must balance the development of properties with having enough cash on hand to pay rent and fines.",
          "3.Strategic Investment: Deciding where and when to invest, either by buying or upgrading properties or holding cash to remain solvent when landing on opponents’ properties.",
        ],
      },
    ],
  },
  {
    cardTitle: "Boardgames",
    cardType: "SKU",
    cardSubTitle: "Cluedo",
    imageUrl: Cluedo,
    deepDiveTopics: [
      {
        topic: "Game Description",
        subTopics: [
          "Cluedo, also known as Clue in North America, is a mystery game where players must figure out who committed the murder, with what weapon, and in which room. Players move around the game board that represents a mansion as they speculate and deduce the details of the murder.",
        ],
      },
      {
        topic: "Game Rules",
        subTopics: [
          "1. Setup: The game begins with cards representing suspects, weapons, and rooms shuffled and one of each type secretly placed in an envelope to represent the murder specifics. The remaining cards are distributed among the players.",
          "2. Gameplay: Players roll dice to move from room to room in a mansion, making suggestions about the murder details to gather information. Other players must provide one disproving card if they possess it.",
          "3. Accusations: When a player feels confident they know the contents of the envelope, they make an accusation and secretly check the cards. If they are correct, they win the game; if not, they continue playing but cannot make further suggestions.",
        ],
      },
      {
        topic: "Game Dynamics",
        subTopics: [
          "1. Deductive Reasoning: Players must use their deductive reasoning skills to interpret hints and rule out possibilities, getting closer to the correct murder scenario.",
          "2. Strategic Movement: Players choose their paths wisely to enter various rooms and make suggestions strategically to gain the most information while sometimes blocking others.",
          "3. Bluffing: Players may bluff about their suggestions to mislead others about the cards they hold.",
        ],
      },
      {
        topic: "Game Objectives",
        subTopics: [
          "1. Solve the Murder: The primary objective is to deduce the facts of the murder—suspect, weapon, and room—before anyone else does.",
          "2. Information Management: Players must manage the information they reveal through suggestions and the information they gain from others' reactions and suggestions.",
          "3. Strategic Thinking: Players must strategically plan their moves and suggestions to maximize their advantage in solving the mystery first.",
        ],
      },
    ],
  },
  {
    cardTitle: "Boardgames",
    cardType: "SKU",
    cardSubTitle: "Pictionary",
    imageUrl: Pictionary,
    deepDiveTopics: [
      {
        topic: "Game Description",
        subTopics: [
          "Pictionary is a charades-inspired word-guessing game invented by Robert Angel with graphic design by Gary Everson. It involves players drawing a word or phrase, typically a noun or a verb, that others must guess. The goal is to convey the word visually without verbal clues or drawing letters or symbols.",
        ],
      },
      {
        topic: "Game Rules",
        subTopics: [
          "1. Setup: Players divide into teams. Each team gets a piece of paper and a drawing pen. The game board is used to track progress, with each team's token advancing based on correct guesses.",
          "2. Gameplay: Teams take turns drawing and guessing. The drawing player draws a word card and has a limited time to draw while their team guesses. No letters, numbers, or verbal clues are allowed.",
          "3. Scoring: If the team guesses correctly before the timer runs out, they move their token forward on the board. The first team to reach the finish wins the game.",
        ],
      },
      {
        topic: "Game Dynamics",
        subTopics: [
          "1. Time Pressure: The timer adds a sense of urgency and can make drawing and guessing more challenging and exciting.",
          "2. Team Collaboration: Team members must work together quickly and efficiently to interpret drawings and guess the word correctly.",
          "3. Creative Expression: Players often come up with unique and creative ways to represent complex words or phrases, enhancing the fun and challenge.",
        ],
      },
      {
        topic: "Game Objectives",
        subTopics: [
          "1. Quick Thinking: Players must think quickly to depict the word or phrase visually before time runs out.",
          "2. Effective Communication: The key to success in Pictionary is the ability to communicate ideas through drawing effectively.",
          "3. Team Strategy: Teams must strategize on the best guessers and drawers, depending on the word or category, to advance on the board and ultimately win.",
        ],
      },
    ],
  },
];
export { companySKUs, companySKUsIntro };
