// import visuals
import JTBD1 from "../../assets/JTBD/JTBD1.png";
import JTBD2 from "../../assets/JTBD/JTBD2.png";
import JTBD3 from "../../assets/JTBD/JTBD3.png";
import JTBD4 from "../../assets/JTBD/JTBD4.png";
import JTBD5 from "../../assets/JTBD/JTBD5.png";
import JTBD6 from "../../assets/JTBD/JTBD6.png";

const jobsToBeDoneIntro = {
  title: "Jobs-To-Be-Done",
  description: [
    "In order to augment our customer experience, we need to fuly understand the underlying needs or 'jobs' that customers are trying to accomplish. We should look at our customers as if they 'hire' our products or services to fulfill specific jobs. By identifying and understanding these jobs, we can design solutions that are precisely tailored to meet the customers' needs more effectively.",
  ],
};

const jobsToBeDone = [
  {
    cardID: 1,
    cardTitle: "JTBD 1: Engage and Entertain",
    imageUrl: JTBD1,
    cardSubTitle: "Keeping everyone entertained, no matter the crowd",
    cardDescription:
      "Create board games that are universally engaging, offering entertainment for a variety of group sizes and dynamics, ensuring fun for all players.",
    cardRelevantJourneyPhases: {
      awareness: true,
      consideration: true,
      purchase: true,
      learning: false,
      explaining: true,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: true,
      competitiveStrategist: false,
      socialGamer: true,
      casualGamer: true,
      partyHost: true,
      traveler: false,
      dateNighter: false,
      meTimer: false,
    },
    cardOccasions: {
      party: true,
      gameNight: true,
      travel: false,
      casualGetTogethers: true,
      dateNight: false,
      familyTime: true,
      meTime: false,
      educational: false,
      competition: false,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 2,
    cardTitle: "JTBD 2: Quick Setup and Play",
    imageUrl: JTBD2,
    cardSubTitle: "From box to play in minutes",
    cardDescription:
      "Develop games that can be set up and started quickly to maximize playing time and minimize downtime, catering to impromptu game sessions.",
    cardRelevantJourneyPhases: {
      awareness: false,
      consideration: true,
      purchase: true,
      learning: true,
      explaining: true,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: true,
      competitiveStrategist: false,
      socialGamer: true,
      casualGamer: true,
      partyHost: true,
      traveler: true,
      dateNighter: false,
      meTimer: false,
    },
    cardOccasions: {
      party: true,
      gameNight: true,
      travel: true,
      casualGetTogethers: true,
      dateNight: false,
      familyTime: true,
      meTime: false,
      educational: false,
      competition: false,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 3,
    cardTitle: "JTBD 3: Strategic Depth",
    imageUrl: JTBD3,
    cardSubTitle: "Engage the mind with strategic challenges",
    cardDescription:
      "Offer games that provide a deep, strategic experience, appealing to players who enjoy thinking, planning, and tactical decision-making.",
    cardRelevantJourneyPhases: {
      awareness: true,
      consideration: true,
      purchase: true,
      learning: false,
      explaining: false,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: false,
      competitiveStrategist: true,
      socialGamer: false,
      casualGamer: false,
      partyHost: false,
      traveler: false,
      dateNighter: false,
      meTimer: true,
    },
    cardOccasions: {
      party: false,
      gameNight: true,
      travel: false,
      casualGetTogethers: false,
      dateNight: false,
      familyTime: false,
      meTime: true,
      educational: false,
      competition: true,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 4,
    cardTitle: "JTBD 4: Family Bonding",
    imageUrl: JTBD4,
    cardSubTitle: "Creating memorable family moments",
    cardDescription:
      "Produce games that are suitable for all ages, helping families spend quality time together and creating lasting memories.",
    cardRelevantJourneyPhases: {
      awareness: true,
      consideration: true,
      purchase: true,
      learning: true,
      explaining: true,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: true,
      competitiveStrategist: false,
      socialGamer: false,
      casualGamer: true,
      partyHost: false,
      traveler: false,
      dateNighter: false,
      meTimer: false,
    },
    cardOccasions: {
      party: false,
      gameNight: true,
      travel: false,
      casualGetTogethers: false,
      dateNight: false,
      familyTime: true,
      meTime: false,
      educational: true,
      competition: false,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 5,
    cardTitle: "JTBD 5: Travel-Friendly Gaming",
    imageUrl: JTBD5,
    cardSubTitle: "Fun on the go",
    cardDescription:
      "Design compact, easy-to-pack games that are ideal for travel, ensuring that gamers can enjoy their favorite games no matter where they are.",
    cardRelevantJourneyPhases: {
      awareness: true,
      consideration: true,
      purchase: true,
      learning: false,
      explaining: false,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: false,
      competitiveStrategist: false,
      socialGamer: false,
      casualGamer: true,
      partyHost: false,
      traveler: true,
      dateNighter: true,
      meTimer: false,
    },
    cardOccasions: {
      party: false,
      gameNight: false,
      travel: true,
      casualGetTogethers: false,
      dateNight: true,
      familyTime: false,
      meTime: false,
      educational: false,
      competition: false,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 6,
    cardTitle: "JTBD 6: Easy to Teach & Learn",
    imageUrl: JTBD6,
    cardSubTitle: "Making it easy for everyone to learn and play",
    cardDescription:
      "HMW make it easier for people to explain, learn and understand the rules of our games, ensuring that everyone can enjoy the playing experience?",
    cardRelevantJourneyPhases: {
      awareness: true,
      consideration: true,
      purchase: true,
      learning: true,
      explaining: true,
      playing: true,
    },
    cardRelevantPersonas: {
      familyCoordinator: false,
      competitiveStrategist: true,
      socialGamer: true,
      casualGamer: false,
      partyHost: true,
      traveler: false,
      dateNighter: true,
      meTimer: true,
    },
    cardOccasions: {
      party: true,
      gameNight: true,
      travel: false,
      casualGetTogethers: false,
      dateNight: true,
      familyTime: false,
      meTime: true,
      educational: false,
      competition: false,
    },
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
];

export { jobsToBeDoneIntro, jobsToBeDone };
