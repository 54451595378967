import TiltIcon from "../../assets/TiltIcon.png";
import TargetIcon from "../../assets/TargetIcon.png";
import TeamBrainstormIcon from "../../assets/TeamBrainstormIcon.png";
import OverviewAILandscape from "../../assets/OverviewAILandscape.png";
import OpenAIGPT4 from "../../assets/OpenAIGPT4.png";
import LangChain from "../../assets/LangChain.png";
import Whisper from "../../assets/webp/WhisperLogo.webp";
import Serper from "../../assets/SerperLogo.png";
import ElevenLabs from "../../assets/ElevenLabsLogo.png";
import BeautifulSoup from "../../assets/webp/BeautifulSoupLogo.webp";
import BritvicProducts from "../../assets/webp/BritvicProducts.webp";
import BritvicCustomerExperience from "../../assets/webp/BritvicCustomerExperience.webp";
import BritvicOperations from "../../assets/webp/BritvicOperations.webp";
import Concept1 from "../../assets/webp/Concept1.webp";
import Concept2 from "../../assets/webp/Concept2.webp";
import Concept3 from "../../assets/webp/Concept3.webp";

const britvic = {
  companyName: "Britvic",
  challengeDefinition: {
    title: "Challenge Definition",
    description:
      "Identify Generative AI use case that can elevate the experience of Britvic's Consumers & Shoppers, enhance the collaboration with Grocery Retailers and OOH Channels, and augment the capabilities of all Britvic's employees.",
    focusAreas: [
      {
        title: "Elevate the experience of Consumers & Shoppers",
        description:
          "Explore the potential of generative AI to craft highly personalized experiences that resonate with diverse occasions, need states, and touchpoints, transforming brand interactions into dynamic ecosystems that align with the latest health and wellbeing trends.",
        imageUrl: BritvicProducts,
      },
      {
        title: "Enhance collaboration with Grocery Retailers and OOH Channels",
        description:
          "Determine how generative AI can fortify Britvic's ecosystem, improving synergies with grocery retailers and out-of-home channels to capitalize on evolving consumer trends, such as health consciousness and premiumization of adult social experiences.",
        imageUrl: BritvicCustomerExperience,
      },
      {
        title: "Augment the capabilities of Britvic's Employees",
        description:
          "Identify ways in which generative AI can augment Britvic's internal capabilities, from sparking creative ideation in marketing to automating mundane tasks, thereby fostering a culture of innovation and efficiency across the company.",
        imageUrl: BritvicOperations,
      },
    ],
  },

  evaluationCriteria: [
    {
      title: "Technology Readiness Level (TRL)",
      description: `Ideas should leverage current or near-future AI capabilities, should be scalable and should hold the potential for integration into existing systems.`,
    },
    {
      title: "Positive Net Margin Impact",
      description: `This assesses how the idea could positively impact Britvic's financial health by either allowing Britvic to increase its sales prices or reduce its costs.`,
    },
    {
      title: "Positive Turnover Impact",
      description: `This measures the expected effect of the idea on increasing Britvic's sales volume. It examines market expansion potential, customer engagement strategies, and the capacity of the idea to attract and retain customers, thereby positively influencing the company's sales turnover.`,
    },
  ],
  swotAnalysis: [
    {
      title: "Strengths",
      points: [
        {
          title: "Strong Brand Portfolio",
          description:
            "Britvic has a strong portfolio of well-known beverage brands, which can help in the acceptance and adoption of AI-driven products or services.",
        },
        {
          title: "Extensive Sales Data",
          description:
            "Britvic has extensive data from years of beverage sales across multiple brands and markets, which can be used to train GenAI models.",
        },
        {
          title: "Innovative Culture",
          description:
            "Britvic has a track record of innovation in product development, which can facilitate the integration of GenAI into its operations.",
        },
      ],
    },
    {
      title: "Weaknesses",
      points: [
        {
          title: "Limited AI Expertise",
          description:
            "As a beverage company, Britvic may lack the in-house AI expertise needed to develop and implement GenAI solutions.",
        },
        {
          title: "Legacy Systems",
          description:
            "Britvic's existing IT infrastructure and systems may not be readily compatible with GenAI technologies, requiring significant upgrades.",
        },
      ],
    },
    {
      title: "Opportunities",
      points: [
        {
          title: "New Product Development",
          description:
            "GenAI can be used to generate new beverage product ideas, flavors, and packaging designs, helping Britvic to innovate in the competitive beverage market.",
        },
        {
          title: "Personalized Marketing",
          description:
            "GenAI can help Britvic to offer personalized marketing and promotional experiences, enhancing customer engagement and loyalty.",
        },
        {
          title: "Supply Chain Optimization",
          description:
            "By leveraging GenAI for demand forecasting and production planning, Britvic can optimize its supply chain and reduce costs.",
        },
      ],
    },
    {
      title: "Threats",
      points: [
        {
          title: "Regulatory Changes",
          description:
            "Changes in AI and data privacy regulations, particularly in Britvic's key markets, could impact the company's ability to use GenAI.",
        },
        {
          title: "Consumer Concerns",
          description:
            "Consumers may have concerns about the use of AI in beverage production or personalized marketing, which could affect the acceptance of AI-driven products or services.",
        },
        {
          title: "Competitive Pressure",
          description:
            "Other beverage companies who are quicker or more effective in implementing GenAI could gain a competitive advantage over Britvic.",
        },
      ],
    },
  ],
  quiz: {
    question:
      "How could Britvic potentially leverage RAG in their product development process?",
    answers: [
      {
        answer:
          "By using it to optimize their manufacturing processes and reduce waste.",
        correct: false,
      },
      {
        answer:
          "By using it to predict customer preferences and tailor their marketing strategies.",
        correct: false,
      },
      {
        answer:
          "By using it to generate new product ideas based on market trends and customer feedback.",
        correct: true,
      },
      {
        answer:
          "By using it to create personalized drinking experiences for OOH.",
        correct: false,
      },
    ],
  },
  journeyTouchpoints: [
    {
      stage: "Awareness",
      touchpoints: [
        {
          title: "TV Commercials",
          description:
            "Customers might see commercials for Britvic beverages while watching their favorite shows.",
        },
        {
          title: "Online Ads",
          description:
            "Customers might see online ads for Britvic beverages while browsing the internet or using social media.",
        },
        {
          title: "In-Store Displays",
          description:
            "Customers might notice Britvic beverages while shopping in supermarkets and convenience stores.",
        },
        {
          title: "Sponsorships",
          description:
            "Britvic might sponsor events, sports teams, or TV shows, increasing brand visibility.",
        },
        {
          title: "Product Placement",
          description:
            "Britvic beverages might be featured in movies, TV shows, or influencer content, showcasing the products in a natural setting.",
        },
        {
          title: "Social Media Influencers",
          description:
            "Influencers might promote Britvic beverages on social media, reaching a large and engaged audience.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers who have subscribed to Britvic's email newsletter might receive information about new beverages.",
        },
        {
          title: "Word of Mouth",
          description:
            "Customers might hear about Britvic beverages from friends, family, or colleagues.",
        },
        {
          title: "Public Relations",
          description:
            "Positive news stories about Britvic or its beverages can raise brand awareness.",
        },
        {
          title: "Search Engine Optimization (SEO)",
          description:
            "Customers might find Britvic beverages while searching for related keywords on search engines.",
        },
        {
          title: "Recommendation Engine Optimization (REO)",
          description:
            "Britvic can optimize product recommendations on e-commerce platforms to increase visibility and sales.",
        },
      ],
    },
    {
      stage: "Consideration",
      touchpoints: [
        {
          title: "Product Reviews",
          description:
            "Customers might read online reviews about Britvic beverages to learn more about their taste and quality.",
        },
        {
          title: "Product Comparisons",
          description:
            "Customers might compare Britvic beverages with those of competitors to decide which product best meets their preferences.",
        },
        {
          title: "Company Website",
          description:
            "Customers might visit Britvic's website to learn more about the company and its range of beverages.",
        },
        {
          title: "Social Media Pages",
          description:
            "Customers might visit Britvic's social media pages to see product announcements, read customer comments, and engage with the brand.",
        },
        {
          title: "Customer Service Interactions",
          description:
            "Customers might contact Britvic's customer service to ask questions about beverages.",
        },
        {
          title: "In-Store Interactions",
          description:
            "Customers might taste Britvic beverages in stores or at promotional events, experiencing their flavors firsthand.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers might receive emails from Britvic with detailed beverage information, special offers, or customer testimonials.",
        },
        {
          title: "Blogs and Articles",
          description:
            "Customers might read blogs or articles about Britvic beverages, providing them with more in-depth information.",
        },
        {
          title: "Product Demonstration Videos",
          description:
            "Customers might watch videos showcasing Britvic beverages, including mixing tutorials or taste tests.",
        },
        {
          title: "Forums and Discussion Boards",
          description:
            "Customers might participate in online forums or discussion boards where they can learn more about Britvic beverages from other customers.",
        },
      ],
    },
    {
      stage: "Purchase",
      touchpoints: [
        {
          title: "Online Store",
          description:
            "Customers might purchase Britvic beverages directly from the company's online store.",
        },
        {
          title: "Physical Stores",
          description:
            "Customers might purchase Britvic beverages from supermarkets, convenience stores, and specialty stores.",
        },
        {
          title: "E-commerce Websites",
          description:
            "Customers might purchase Britvic beverages from e-commerce websites like Amazon or direct from grocery delivery services.",
        },
        {
          title: "Mobile Apps",
          description:
            "Customers might purchase Britvic beverages through mobile shopping apps from grocery retailers.",
        },
        {
          title: "Customer Service Interactions",
          description:
            "Customers might purchase Britvic beverages through interactions with customer service representatives, either in-store, online, or over the phone.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers might purchase Britvic beverages after receiving a promotional email with a special offer or discount.",
        },
        {
          title: "Social Media Shopping",
          description:
            "Customers might purchase Britvic beverages directly through social media platforms that offer shopping features (e.g., Instagram Shopping).",
        },
        {
          title: "Subscription Services",
          description:
            "Customers might purchase Britvic beverages through a subscription service that delivers their favorite drinks on a regular basis.",
        },
        {
          title: "In-Store Promotions",
          description:
            "Customers might purchase Britvic beverages after seeing in-store promotions or discounts.",
        },
        {
          title: "Point of Sale Displays",
          description:
            "Customers might make impulse purchases of Britvic beverages after seeing them at the checkout area in a store.",
        },
      ],
    },
    {
      stage: "Use",
      touchpoints: [
        {
          title: "Product Use",
          description:
            "Customers interact with Britvic beverages directly as they enjoy the drinks.",
        },
        {
          title: "User Manuals",
          description:
            "Customers might refer to instructions or recipes provided by Britvic to enjoy their beverages in the best way possible.",
        },
        {
          title: "Customer Support",
          description:
            "Customers might contact customer support for assistance with product inquiries or to provide feedback on the beverages.",
        },
        {
          title: "Product Updates",
          description:
            "Customers might receive information about new flavors, limited editions, or product improvements.",
        },
        {
          title: "Online Tutorials",
          description:
            "Customers might watch online tutorials or instructional videos on how to mix cocktails or mocktails using Britvic products.",
        },
        {
          title: "Community Forums",
          description:
            "Customers might participate in community forums where they can share recipes, tips for beverage enjoyment, and connect with other Britvic enthusiasts.",
        },
        {
          title: "Social Media Engagement",
          description:
            "Customers might engage with Britvic on social media, sharing their experiences with the beverages, posting pictures, and interacting with the brand.",
        },
        {
          title: "Email Communications",
          description:
            "Customers might receive emails from Britvic with recipes, tips for enjoying the beverages, or suggestions for other products.",
        },
        {
          title: "Accessories and Add-Ons",
          description:
            "Customers might purchase accessories or related products, like branded glasses or cocktail kits, enhancing their beverage experience.",
        },
        {
          title: "Product Packaging",
          description:
            "Customers might interact with the product packaging, which can influence their experience and perception of the beverage.",
        },
      ],
    },
    {
      stage: "Loyalty",
      touchpoints: [
        {
          title: "Repeat Purchases",
          description:
            "Customers might make repeat purchases of Britvic beverages, demonstrating their loyalty to the brand.",
        },
        {
          title: "Customer Reviews",
          description:
            "Loyal customers might write positive reviews of Britvic beverages, sharing their experiences with other potential customers.",
        },
        {
          title: "Word of Mouth",
          description:
            "Loyal customers might recommend Britvic beverages to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.",
        },
        {
          title: "Social Media Advocacy",
          description:
            "Loyal customers might share their positive experiences with Britvic beverages on social media, acting as brand advocates.",
        },
        {
          title: "Customer Loyalty Programs",
          description:
            "Customers might participate in Britvic's customer loyalty programs, earning rewards for their purchases and engagement with the brand.",
        },
        {
          title: "Email Marketing",
          description:
            "Loyal customers might engage with Britvic's email marketing campaigns, staying informed about new products, promotions, and events.",
        },
        {
          title: "Community Engagement",
          description:
            "Loyal customers might participate in Britvic's online communities, engaging with other customers and the brand.",
        },
        {
          title: "Customer Surveys",
          description:
            "Loyal customers might participate in customer surveys, providing valuable feedback to help Britvic improve its products and services.",
        },
        {
          title: "Brand Events",
          description:
            "Loyal customers might attend Britvic's brand events, such as product launches, tastings, or workshops.",
        },
        {
          title: "Personalized Marketing",
          description:
            "Loyal customers might receive personalized marketing communications from Britvic, recognizing their loyalty and providing tailored offers and recommendations.",
        },
      ],
    },
    {
      stage: "Advocacy",
      touchpoints: [
        {
          title: "Word of Mouth",
          description:
            "Customers who have become advocates might actively recommend Britvic beverages to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.",
        },
        {
          title: "Social Media Advocacy",
          description:
            "Advocates might share their positive experiences with Britvic beverages on social media, acting as brand ambassadors and influencing their followers.",
        },
        {
          title: "Customer Reviews",
          description:
            "Advocates might write glowing reviews of Britvic beverages on e-commerce websites, review sites, and forums, helping to build trust and credibility forthe brand.",
        },
        {
          title: "Customer Testimonials",
          description:
            "Advocates might provide testimonials for Britvic, sharing their stories and experiences with the brand's beverages.",
        },
        {
          title: "Brand Events",
          description:
            "Advocates might attend and participate in Britvic's brand events, such as product launches, tastings, or workshops, and share their experiences on social media.",
        },
        {
          title: "User-Generated Content",
          description:
            "Advocates might create and share their own content related to Britvic beverages, such as cocktail recipes, taste tests, or unboxing videos, on social media and other platforms.",
        },
        {
          title: "Customer Referral Programs",
          description:
            "Advocates might participate in Britvic's customer referral programs, referring new customers to the brand in exchange for rewards.",
        },
        {
          title: "Community Engagement",
          description:
            "Advocates might actively participate in Britvic's online communities, helping to answer questions, share tips and tricks, and engage with other customers.",
        },
        {
          title: "Brand Partnerships",
          description:
            "Advocates might partner with Britvic on collaborations, such as sponsored posts, product collaborations, or co-branded campaigns.",
        },
        {
          title: "Customer Surveys and Feedback",
          description:
            "Advocates might provide valuable feedback to Britvic through customer surveys, focus groups, or other feedback channels, helping the brand to improve its products and services.",
        },
      ],
    },
  ],

  genAiUseCases: [
    {
      title: "Rapid Packaging Design Prototyping & A/B Testing",
      description:
        "Use text-to-image AI to rapidly generate new product packaging designs and labels for Britvic's 30+ soft drink brands, allowing faster prototyping and A/B testing of designs.",
      desirability: 4,
      feasibility: 8,
      imageUrl: Concept1,
    },
    {
      title:
        "Daily inspirational podcast for Britvic employees created automatically by GenAI",
      description:
        "A daily podcast generator that uses webscraping tools, text-to-text, and text-to-speech AI capabilities to create inspirational content for employees based on their interests and function, including industry news, company updates, and recent research papers.",
      desirability: 6,
      feasibility: 8,
      imageUrl: Concept2,
    },
    {
      title: "Hyper-personalized beverages @ point-of-sale",
      description:
        "A vending machine capable of creating hyper-personalized beverages at the point-of-sale (e.g. at fitness centers) by using generative AI to customize flavors, ingredients, nutrients, packaging artwork based on an individual's available data (e.g. workout plan or workout performance).",
      desirability: 7,
      feasibility: 3,
      imageUrl: Concept3,
    },
    {
      title: "AI-powered Drink Recipe Suggestion & Food Pairing Tool",
      description:
        "Create an AI-powered drink recipe suggestion and food pairing web tool, where users input ingredients or occasions and get tailored drink,mocktail, and food-pairing ideas using Britvic products.",
      desirability: 3,
      feasibility: 9,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Sample distribution via AI-powered Vending Machines",
      description:
        "Vending Machine which distributes free samples when people take a picture at the vending machine. The vending machine uses AI for face recognition and fun AI image filters and stylings to share on socials.",
      desirability: 4,
      feasibility: 3,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Digital consumer twins for rapid concept validation",
      description:
        "Use GenAI synthetic consumer twins to quickly test new product, packaging concepts, flavors, and marketing campaigns with a diverse range of consumer personas.",
      desirability: 5,
      feasibility: 6,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "AI-Competitor Intelligence Briefs for Brand Strategy",
      description:
        "Use AI to monitor news and market reports to automatically produce executive-ready competitive intelligence briefs and summarize implications for Britvic's brands and strategy.",
      desirability: 4,
      feasibility: 9,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 5,
      feasibility: 5,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 2,
      feasibility: 3,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 2,
      feasibility: 6,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 5,
      feasibility: 3,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 1,
      feasibility: 8,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 2,
      feasibility: 9,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 2,
      feasibility: 1,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Analyze social media images at scale using AI to identify Britvic products, usage occasions, and consumer sentiment to inform marketing and product development.",
      desirability: 1,
      feasibility: 1,
      imageUrl:
        "https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=",
    },
  ],
  pocFeatures: [
    {
      title: "Web scraping and data extraction",
      description:
        "Ability to scrape the web and extract relevant data of news articles, research papers, and industry reports.",
      prioritization: "Must-Have",
      techStack: [Serper, BeautifulSoup],
    },
    {
      title: "Data summarization and insights generation (text-to-text)",
      description:
        "Use a fine-tuned large language model with capability to generate contextually relevant, engaging, an dinspiring podcast stories based on the extracted data and employees function and preferences.",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
    {
      title: "Podcast Voice (Text-to-Speech)",
      description:
        "Model that can produce a Natural-sounding, friendly voice modulation to provide the latest news and insights in a captivating, vocal way.",
      prioritization: "Must-Have",
      techStack: [Whisper, ElevenLabs],
    },
    {
      title: "Content Moderation and Safety",
      description:
        "System to filter inappropriate content, compliant with Britvic's values and company guidelines. ",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
  ],
  userValidationResults: {
    imageUrl: Concept2,
    introText: `The proof-of-concept testing for the Daily Podcast Generator was conducted with a group of 50 employees across various departments within Britvic UK. The primary objectives were to evaluate user engagement, satisfaction, and overall experience with the generated content, tailored to their interests and job functions. The content included industry news, company updates, and summaries of recent research papers.`,
    methodologyTitle: "Methodology",
    methodologyDescription: `The testing was carried out over a two-week period, where each participant received a daily podcast every morning. The podcasts were generated using the latest web scraping tools, text-to-text, and text-to-speech AI technologies to ensure relevance and quality. Observational data was collected through digital engagement metrics, and a post-experiment survey was administered to gather feedback on the content's relevance, presentation quality, and usefulness.`,
    engagementTitle: "User Engagement",
    engagementDescription: [
      `42 out of 50 employees consistently listened to the daily podcasts, with many reporting it became a part of their morning routine.`,
      `The remaining 8 employees listened sporadically, citing preferences for text updates or the lack of time as the main reasons for lower engagement.`,
    ],
    participantsTitle: "Participants",
    participantsDescription: [
      `Introduction: Employees were briefed about the purpose of the podcast and how it was tailored to their interests.`,
      `Daily Use: Participants received podcasts via email or a preferred communication tool, designed to seamlessly integrate into their morning routine.`,
      `Feedback Collection: Throughout the testing period, participants were encouraged to provide immediate feedback on each podcast's relevance and quality.`,
      `Survey: At the end of the two weeks, a comprehensive survey collected detailed feedback on their experience.`,
    ],
    satisfactionTitle: "User Satisfaction",
    satisfactionDescription: [
      `47 out of 50 employees reported enjoying the personalized content and found the podcasts to be a valuable source of information.`,
      `Many employees appreciated the concise format and relevance to their specific job functions, with feedback highlighting the blend of industry news and company updates.`,
      `A small group suggested improvements for the way sources were cited and an improved functionality to deepdive into some topics if wanted.`,
    ],
    recommendationTitle: "Recommendations",
    recommendationIntro: "Based on the findings, it is recommended to:",
    recommendationDescription: [
      `Further personalize the podcasts by refining AI algorithms to better match employee interests and job functions.`,
      `Enhance the text-to-speech technology to improve voice quality and inflection, making the podcasts more engaging and pleasant to listen to.`,
      `Expand the content sources to include more diverse and comprehensive industry news, research summaries, and actionable insights.`,
      `Conduct further testing with a larger and more diverse group of employees to refine content delivery and engagement strategies.`,
      `Explore interactive features that allow employees to select topics of interest or skip segments, providing a more customizable experience.`,
    ],
    recommendationOutro: `Overall, the Daily Podcast Generator demonstrated significant potential in enhancing employee engagement and providing personalized, relevant content. With targeted improvements and expanded testing, this tool could become a valuable asset for companies looking to foster a more informed and connected workforce.`,
  },
};

export { britvic };
