import React, { useEffect } from "react";
import "./LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import heroImageDesktop from "../assets/heroImage_desktop_new_glow.png";
import heroImageMobile from "../assets/webp/heroImage_mobile.webp";
import bottleAnd3Boxes from "../assets/bottleAnd3Boxes.png";
import appAndAlgorithm from "../assets/appAndAlgorithm.png";
import bottleImage from "../assets/bottleImage.png";
import bottleAndBoxMobileHalf2 from "../assets/bottleAndBoxMobileHalf2.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className='landingPage HowToFlyWithAI'>
      <div className='landingPage-left'>
        <div className='landingPage-left-text'>
          <h1 className='landingPage-left-text-titleMobile'>
            <span
              style={{
                fontWeight: "600",
                position: "relative",
                top: "35px",
                left: "-30px",
              }}
            >
              Ready to
            </span>{" "}
            <br />
            <span
              style={{
                fontWeight: "800",
                position: "relative",
                top: "10px",
                left: "30px",
                position: "relative",
                zIndex: "1",
              }}
            >
              Fly with AI?
            </span>
            <div className='lineUnderMainTitleMobile'></div>
          </h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "86%",
              margin: "10px auto",
              gap: "10px",
            }}
          >
            <p className='landingPage-left-text-textMobile'>
              We help your organization discover, build, and validate impactful
              Gen-AI use cases to augment customer experiences and internal
              workflows.
            </p>
          </div>
          <div className='landingPage-right-scrollText-mobile'>
            <p>
              start <span>experimenting</span>
              <br /> start <span>innovating</span>
              <br /> start <span>flying</span>
            </p>
            <Link
              className='roundScrollButton'
              to={{ pathname: "/", hash: "#OurMethodology" }}
              aria-label='Scroll to next section'
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </div>
          <div style={{ position: "relative" }}>
            <h1 className='landingPage-left-text-titleDesktop'>
              Ready to Fly with AI?
            </h1>
            <div className='lineUnderMainTitle'></div>
          </div>
          <p className='landingPage-left-text-textDesktop'>
            We help your organization discover, build, and validate impactful
            Gen-AI use cases to augment customer experiences and internal
            workflows.
          </p>
          <a
            className='howToFlyMailLink'
            href='mailto:gilles.rossou@howtofly.ai'
            style={{
              background: "rgba(0,0,0,0)",
              color: "white",
              border: "1px solid white",
              padding: "10px 20px",
            }}
          >
            contact us
          </a>
        </div>
      </div>
      <div className='landingPage-right'>
        <div className='landingPage-right-images'>
          <img
            className='landingPage-right-images-heroImageMobile'
            src={heroImageDesktop}
            alt='HowToFly astronaut standing on piece of paper'
          />
          <img
            className='landingPage-right-images-heroImageDesktop'
            alt='HowToFly astronaut standing on piece of paper'
            src={heroImageDesktop}
          />
          <div className='OrangeRectangleBehindHeroImage'></div>
          <div className='landingPage-right-scrollText'>
            <p>
              start <span>ideating</span>
              <br /> start <span>experimenting</span>
              <br /> start <span>flying</span>
            </p>
            <Link
              className='roundScrollButton'
              to={{ pathname: "/", hash: "#OurMethodology" }}
              aria-label='Scroll to next section'
            >
              <FontAwesomeIcon icon={faArrowDown} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
