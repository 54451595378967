// import assets
import stage1Placeholder from "../../assets/Stage1Placeholder.jpg";
import stage2Placeholder from "../../assets/Stage2Placeholder.png";
import stage3Placeholder from "../../assets/Stage3Placeholder.png";
import stage4Placeholder from "../../assets/Stage4Placeholder.png";
import stage5Placeholder from "../../assets/Stage5Placeholder.png";
import stage1EmotionShift from "../../assets/Stage1EmotionShift.png";
import stage2EmotionShift from "../../assets/Stage2EmotionShift.png";
import stage3EmotionShift from "../../assets/Stage3EmotionShift.png";
import stage4EmotionShift from "../../assets/Stage4EmotionShift.png";
import stage5EmotionShift from "../../assets/Stage5EmotionShift.png";

import persona1 from "../../assets/webpKB/persona1.webp";
import persona2 from "../../assets/webpKB/persona2.webp";
import persona3 from "../../assets/webpKB/persona3.webp";
import persona4 from "../../assets/webpKB/persona4.webp";
import persona5 from "../../assets/webpKB/persona5.webp";
import persona6 from "../../assets/webpKB/persona6.webp";
import persona7 from "../../assets/webpKB/persona7.webp";
import persona8 from "../../assets/webpKB/persona8.webp";
import persona9 from "../../assets/webpKB/persona9.webp";

const consumerPersonasIntro = {
  title: "End User Personas",
  description: [
    "End user personas are fictional characters created to represent the different types of users who might interact with a product or service. These personas help in understanding the needs, goals, and behavior of the target audience, enabling businesses to design products and services that cater to their specific requirements.",
  ],
};

const consumerPersonas = [
  {
    cardTitle: "Lukas (DE)",
    cardType: "Persona",
    cardSubTitle: "Board Game Enthusiast",
    consumerBio: `Lukas Müller, a board game enthusiast from Frankfurt, loves exploring new and challenging games to play with friends and family. His background in systems analysis helps him appreciate the complexities and mechanics of board games.`,
    imageUrl: persona1, // Placeholder for an image URL
    country: "DE",
    timeFrame: "2023",
    name: "Lukas Müller",
    age: 36,
    gender: "Male",
    relationshipStatus: "Married, 3 kids",
    address: "Urban, Frankfurt, Germany",
    occupation: "Systems Analyst",
    income: "€40,000 annually",
    hobbies: ["Board games", "Watching Movies", "Tech Gadgets"],
    gamingPreferences: [
      "Strategic and complex games",
      "Family-friendly games",
      "Competitive multiplayer games",
    ],
    favoriteGameGenres: ["Strategy", "Adventure", "Family"],
    mainReasonsForPurchasingGames: [
      "To enhance family time",
      "To host game nights with friends",
      "To challenge his strategic thinking",
    ],
    commonPlacesToPlayGames: [
      "At home with family",
      "At friends' houses during game nights",
      "Occasionally at local board game cafes",
    ],
    favoriteBoardGames: ["Catan", "Ticket to Ride", "Codenames"],
  },
];
export { consumerPersonasIntro, consumerPersonas };
