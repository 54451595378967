import OpenAIGPT4 from "../../assets/OpenAIGPT4.png";
import LangChain from "../../assets/LangChain.png";
import Whisper from "../../assets/webp/WhisperLogo.webp";
import Serper from "../../assets/SerperLogo.png";
import ElevenLabs from "../../assets/ElevenLabsLogo.png";
import BeautifulSoup from "../../assets/webp/BeautifulSoupLogo.webp";
import Concept1 from "../../assets/webp/Concept1.webp";
import Concept2 from "../../assets/webp/Concept2.webp";
import Concept3 from "../../assets/webp/Concept3.webp";
import ImagePlaceholder from "../../assets/webp/ImagePlaceholder.webp";
import ToyStore from "../../assets/ToyStore.jpg";
import Operations from "../../assets/webp/BritvicOperations.webp";

const mattel = {
  companyName: "Mattel",
  challengeDefinition: {
    title: "Challenge Definition",
    description: `Explore and identify groundbreaking applications of generative AI that can enhance Mattel’s product lines, customer experiences, and operational efficiencies.`,
    focusAreas: [
      {
        title: "Elevating the Fan Experience",
        description: `How might we deploy generative AI to craft adaptable, dynamic, and personalized toys, games, and experiences that resonate with all fans — children, parents, and gift-givers — fostering a deeper, more meaningful connection with Mattel’s brands and transforming our products into an interconnected ecosystem of Mattel experiences.`,
        imageUrl:
          "https://pplx-res.cloudinary.com/image/upload/v1710231628/ai_generated_images/jcfejaauozbcphserapd.png",
      },
      {
        title: "Strengthening Collaboration across the value chain",
        description: `How might we utilize generative AI to enhance collaboration with partners across the value chain through precise, real-time insights, optimizing inventory levels and marketing approaches to precisely cater to the diverse preferences of Mattel's fans.`,
        imageUrl: ToyStore,
      },
      {
        title: "Boosting Employees Creativity and Efficiency",
        description: `How might we harness generative AI to enrich Mattel's creative environment and operational efficiency, empowering all employees to further elevate the Mattel Fan experience across the spectrum.`,
        imageUrl: Operations,
      },
    ],
  },
  evaluationCriteria: [
    {
      title: "Technology Readiness Level (TRL)",
      description: `Ideas should leverage current or near-future AI capabilities, should be scalable and should hold the potential for integration into existing systems.`,
    },
    {
      title: "Positive Net Margin Impact",
      description: `This assesses how the idea could positively impact Mattel's financial health by either allowing Mattel to increase its sales prices or reduce its costs.`,
    },
    {
      title: "Positive Turnover Impact",
      description: `This measures the expected effect of the idea on increasing Mattel's sales volume. It examines market expansion potential, customer engagement strategies, and the capacity of the idea to attract and retain customers, thereby positively influencing the company's sales turnover.`,
    },
  ],
  quiz: {
    question:
      "How could Mattel potentially leverage RAG in their product development process?",
    answers: [
      {
        answer:
          "By using it to optimize their manufacturing processes and reduce waste.",
        correct: false,
      },
      {
        answer:
          "By using it to predict customer preferences and tailor their marketing strategies.",
        correct: false,
      },
      {
        answer:
          "By using it to generate new product ideas based on market trends and customer feedback.",
        correct: true,
      },
      {
        answer:
          "By using it to create personalized play experiences for children.",
        correct: false,
      },
    ],
  },
  journeyTouchpoints: [
    {
      stage: "Awareness",
      touchpoints: [
        {
          title: "TV Commercials",
          description:
            "Customers might see commercials for Mattel products while watching their favorite shows.",
        },
        {
          title: "Online Ads",
          description:
            "Customers might see online ads for Mattel products while browsing the internet or using social media.",
        },
        {
          title: "In-Store Displays",
          description:
            "Customers might notice Mattel products while shopping in physical stores.",
        },
        {
          title: "Sponsorships",
          description:
            "Mattel might sponsor events or TV shows, increasing brand visibility.",
        },
        {
          title: "Product Placement",
          description:
            "Mattel products might be featured in movies or TV shows, introducing customers to the products in a natural or fantasy world setting.",
        },
        {
          title: "Social Media Influencers",
          description:
            "Influencers might promote Mattel products on social media, reaching a large and engaged audience.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers who have subscribed to Mattel's email newsletter might receive information about new products.",
        },
        {
          title: "Word of Mouth",
          description:
            "Customers might hear about Mattel products from friends, family, or colleagues.",
        },
        {
          title: "Public Relations",
          description:
            "Positive news stories about Mattel or its products can raise brand awareness.",
        },
        {
          title: "Search Engine Optimization (SEO)",
          description:
            "Customers might find Mattel products while searching for related keywords on search engines.",
        },
        {
          title: "Recommendation Engine Optimization (REO)",
          description:
            "Mattel can optimize product recommendations on e-commerce platforms to increase visibility and sales.",
        },
      ],
    },
    {
      stage: "Consideration",
      touchpoints: [
        {
          title: "Product Reviews",
          description:
            "Customers might read online reviews about Mattel products to learn more about their features and quality.",
        },
        {
          title: "Product Comparisons",
          description:
            " Customers might compare Mattel products with those of competitors to decide which product best meets their needs.",
        },
        {
          title: "Company Website",
          description:
            "Customers might visit Mattel's website to learn more about the company and its products.",
        },
        {
          title: "Social Media Pages",
          description:
            "Customers might visit Mattel's social media pages to see product demonstrations, read customer comments, and engage with the brand.",
        },
        {
          title: "Customer Service Interactions",
          description:
            "Customers might contact Mattel's customer service to ask questions about products.",
        },
        {
          title: "In-Store Interactions",
          description:
            "Customers might interact with Mattel products in physical stores, testing their features and quality.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers might receive emails from Mattel with detailed product information, special offers, or customer testimonials.",
        },
        {
          title: "Blogs and Articles",
          description:
            "Customers might read blogs or articles about Mattel products, providing them with more in-depth information.",
        },
        {
          title: "Product Demonstration Videos",
          description:
            "Customers might watch product demonstration videos on platforms like YouTube to see the product in action.",
        },
        {
          title: "Forums and Discussion Boards",
          description:
            "Customers might participate in online forums or discussion boards where they can learn more about Mattel products from other customers.",
        },
      ],
    },
    {
      stage: "Purchase",
      touchpoints: [
        {
          title: "Online Store",
          description:
            "Customers might purchase Mattel products directly from the company's online store.",
        },
        {
          title: "Physical Stores",
          description:
            "Customers might purchase Mattel products from physical retail stores.",
        },
        {
          title: "E-commerce Websites",
          description:
            "Customers might purchase Mattel products from e-commerce websites like Amazon or eBay.",
        },
        {
          title: "Mobile Apps",
          description:
            "Customers might purchase Mattel products through mobile shopping apps.",
        },
        {
          title: "Customer Service Interactions",
          description:
            "Customers might purchase Mattel products through interactions with customer service representatives, either in-store, online, or over the phone.",
        },
        {
          title: "Email Marketing",
          description:
            "Customers might purchase Mattel products after receiving a promotional email with a special offer or discount.",
        },
        {
          title: "Social Media Shopping",
          description:
            "Customers might purchase Mattel products directly through social media platforms that offer shopping features.",
        },
        {
          title: "Subscription Services",
          description:
            "Customers might purchase Mattel products through a subscription service that delivers products on a regular basis.",
        },
        {
          title: "In-Store Promotions",
          description:
            "Customers might purchase Mattel products after seeing in-store promotions or discounts.",
        },
        {
          title: "Point of Sale Displays",
          description:
            "Customers might make impulse purchases of Mattel products after seeing them at the point of sale in a store.",
        },
      ],
    },
    {
      stage: "Use",
      touchpoints: [
        {
          title: "Product Use",
          description:
            "Customers interact with Mattel products directly as they play with the toys or use the products.",
        },
        {
          title: "User Manuals",
          description:
            "Customers might refer to user manuals to understand how to use the product effectively.",
        },
        {
          title: "Customer Support",
          description:
            "Customers might contact customer support for assistance with product use, troubleshooting, or to ask questions.",
        },
        {
          title: "Product Updates",
          description:
            "Customers might receive product updates or new features for products that have digital components.",
        },
        {
          title: "Online Tutorials",
          description:
            "Customers might watch online tutorials or instructional videos to learn how to use the product in different ways.",
        },
        {
          title: "Community Forums",
          description:
            "Customers might participate in community forums where they can share tips and tricks for using the product, ask questions, and connect with other users.",
        },
        {
          title: "Social Media Engagement",
          description:
            "Customers might engage with Mattel on social media, sharing their experiences with the product, posting pictures or videos, and interacting with the brand.",
        },
        {
          title: "Email Communications",
          description:
            "Customers might receive emails from Mattel with tips for using the product, information about new features, or suggestions for other products that might enhance their experience.",
        },
        {
          title: "Accessories and Add-Ons",
          description:
            "Customers might purchase accessories or add-ons for the product, enhancing their use and enjoyment of it.",
        },
        {
          title: "Product Packaging",
          description:
            "Customers might interact with the product packaging, which can influence their experience and perception of the product.",
        },
      ],
    },
    {
      stage: "Loyalty",
      touchpoints: [
        {
          title: "Repeat Purchases",
          description:
            "Customers might make repeat purchases of Mattel products, demonstrating their loyalty to the brand.",
        },
        {
          title: "Customer Reviews",
          description:
            "Loyal customers might write positive reviews of Mattel products, sharing their experiences with other potential customers.",
        },
        {
          title: "Word of Mouth",
          description:
            "Loyal customers might recommend Mattel products to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.",
        },
        {
          title: "Social Media Advocacy",
          description:
            "Loyal customers might share their positive experiences with Mattel products on social media, acting as brand advocates.",
        },
        {
          title: "Customer Loyalty Programs",
          description:
            "Customers might participate in Mattel's customer loyalty programs, earning rewards for their purchases and engagement with the brand.",
        },
        {
          title: "Email Marketing",
          description:
            "Loyal customers might engage with Mattel's email marketing campaigns, staying informed about new products, promotions, and events.",
        },
        {
          title: "Community Engagement",
          description:
            "Loyal customers might participate in Mattel's online communities, engaging with other customers and the brand.",
        },
        {
          title: "Customer Surveys",
          description:
            "Loyal customers might participate in customer surveys, providing valuable feedback to help Mattel improve its products and services.",
        },
        {
          title: "Brand Events",
          description:
            "Loyal customers might attend Mattel's brand events, such as product launches, demonstrations, or workshops.",
        },
        {
          title: "Personalized Marketing",
          description:
            "Loyal customers might receive personalized marketing communications from Mattel, recognizing their loyalty and providing tailored offers and recommendations.",
        },
      ],
    },
    {
      stage: "Advocacy",
      touchpoints: [
        {
          title: "Word of Mouth",
          description:
            "Customers who have become advocates might actively recommend Mattel products to their friends, family, and colleagues, helping to spread brand awareness and attract new customers.",
        },
        {
          title: "Social Media Advocacy",
          description:
            "Advocates might share their positive experiences with Mattel products on social media, acting as brand ambassadors and influencing their followers.",
        },
        {
          title: "Customer Reviews",
          description:
            "Advocates might write glowing reviews of Mattel products on e-commerce websites, review sites, and forums, helping to build trust and credibility for the brand.",
        },
        {
          title: "Customer Testimonials",
          description:
            "Advocates might provide testimonials for Mattel, sharing their stories and experiences with the brand's products.",
        },
        {
          title: "Brand Events",
          description:
            "Advocates might attend and participate in Mattel's brand events, such as product launches, demonstrations, or workshops, and share their experiences on social media.",
        },
        {
          title: "User-Generated Content",
          description:
            "Advocates might create and share their own content related to Mattel products, such as unboxing videos, tutorials, or product reviews, on social media and other platforms.",
        },
        {
          title: "Customer Referral Programs",
          description:
            "Advocates might participate in Mattel's customer referral programs, referring new customers to the brand in exchange for rewards.",
        },
        {
          title: "Community Engagement",
          description:
            "Advocates might actively participate in Mattel's online communities, helping to answer questions, share tips and tricks, and engage with other customers.",
        },
        {
          title: "Brand Partnerships",
          description:
            "Advocates might partner with Mattel on collaborations, such as sponsored posts, product collaborations, or co-branded campaigns.",
        },
        {
          title: "Customer Surveys and Feedback",
          description:
            "Advocates might provide valuable feedback to Mattel through customer surveys, focus groups, or other feedback channels, helping the brand to improve its products and services.",
        },
      ],
    },
  ],
  genAiUseCases: [
    {
      title: "Interactive Storytelling Toys",
      description:
        "Utilizing text-to-speech and speech-to-text capabilities, Mattel can develop toys that not only listen but also respond to children's stories, encouraging imaginative play. For example, Hot Wheels cars that narrate their own adventures as they are played with.",
      desirability: 8,
      feasibility: 7,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
    {
      title: "Customized Dolls through Text-to-Image",
      description:
        "Customers can describe their ideal doll or action figure (e.g., outfit, hair color, accessories) using text, and AI can generate an image of this doll / action figure. This image can then be used to manufacture personalized dolls, enhancing customer engagement.",
      desirability: 6,
      feasibility: 4,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710247247/ai_generated_images/urgmpvsd7uy2cbsorybd.png",
    },
    {
      title: "Educational Toys with RAG",
      description:
        "Toys that use Retrieval Augmented Generation can provide educational content, answer kids' questions, and even assist with homework, making learning more interactive and fun.",
      desirability: 3,
      feasibility: 2,
      imageUrl:
        "https://files.oaiusercontent.com/file-ixQvj7lX2C1TLBzDbVOO9dKY?se=2024-03-12T13%3A16%3A37Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dc8a8e73a-4879-44f8-af7b-7fd8d8d03930.webp&sig=M7/k%2BkH9eL2huOT2HjE1YN25KbtGD1VsyG4cRK4XUmQ%3D",
    },
    {
      title: "Virtual Toy Testing",
      description:
        "Before physical production, Mattel can use image-to-video technology to create virtual simulations of how a toy operates, helping in product testing and demonstration.",
      desirability: 6,
      feasibility: 6,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
    {
      title: "Music-Creating Toys",
      description:
        "Toys that compose music based on textual descriptions or emotions expressed by children, offering a unique way to explore creativity and emotions through music.",
      desirability: 4,
      feasibility: 3,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
    {
      title: "Personalized Marketing",
      description:
        "Using generative AI, Mattel can create personalized marketing content that resonates with specific customer segments, improving engagement and brand loyalty.",
      desirability: 2,
      feasibility: 8,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
    {
      title: "Supply Chain Optimization",
      description:
        "AI can predict market trends and optimize supply chain decisions, from production to distribution, ensuring that popular products are adequately stocked and reducing waste.",
      desirability: 1,
      feasibility: 5,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
    {
      title: "Enhanced Customer Service (Speech-to-Text, Text-to-Text)",
      description:
        "Implementing AI in customer service, where speech-to-text transcribes customer queries and text-to-text generates responses, can improve efficiency and customer satisfaction.",
      desirability: 5,
      feasibility: 5,
      imageUrl:
        "https://pplx-res.cloudinary.com/image/upload/v1710233400/ai_generated_images/euancedmjaqfc0he0dvg.png",
    },
  ],
  pocFeatures: [
    {
      title: "Environment context creation (data-to-Text)",
      description:
        "Ability to take data from the environment, how fast is the car moving, are there other Hot Wheels surrounding the car, what time is it, what weather is it outside, etc.",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
    {
      title: "Age-appropriate language processing (text-to-text)",
      description:
        "Use a fine-tuned large language model with capability to generate age-appropriate, contextually relevant, engaging responses. with thread memory capabilities.",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
    {
      title: "Responsive Dialogue System (Text-to-Speech)",
      description:
        "Model that can produce Natural-sounding, child-friendly voice modulation based on specific actions of the child.",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4],
    },
    {
      title: "Content Moderation and Safety",
      description:
        "System to filter inappropriate content or responses, compliance with child safety and privacy laws (e.g., COPPA), and parental control options for monitoring and restricting interaction. ",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
    {
      title: "Interactive Storytelling Engine",
      description:
        "Ability to craft coherent and imaginative stories based on the child's input, educational input, parental settings, and child's interests and responses. Settings data should be safely stored.",
      prioritization: "Must-Have",
      techStack: [OpenAIGPT4, LangChain],
    },
  ],
  userValidationResults: {
    imageUrl:
      "https://pplx-res.cloudinary.com/image/upload/v1710231628/ai_generated_images/jcfejaauozbcphserapd.png",
    introText: `The proof-of-concept testing of the Interactive Storytelling
      Toys was conducted with a group of 10 children aged 5-9
      years old. The primary objectives were to evaluate user
      engagement, satisfaction, and overall experience with the
      toys. The testing involved two prototypes: a Hot Wheels car
      with speech capabilities and a Barbie doll with
      vision-to-text technology.`,
    methodologyTitle: "Methodology",
    methodologyDescription: `The testing was carried out in a controlled
      environment, with each child participating in a
      30-minute play session. Observational data was
      collected by two researchers, who noted the
      children's interactions, reactions, and comments.
      Additionally, a post-session survey was administered
      to gather feedback on enjoyment, ease of use, and
      desired improvements.`,
    engagementTitle: "User Engagement",
    engagementDescription: [
      `8 out of 10 children were highly engaged
      throughout the play session, exhibiting sustained
      focus and enthusiasm.`,
      `The remaining 2 children lost interest after
      approximately 15 minutes, citing a too long delay
      in the toy's responses.`,
    ],
    participantsTitle: "Participants",
    participantsDescription: [
      `Introduction: Children were introduced to the toy
        and given basic instructions.`,
      `Free Play: Children interacted with the toy,
        encouraged to speak and respond freely.`,
      `Structured Tasks: Specific tasks to test
        particular features (e.g., story creation, voice
        recognition accuracy).`,
      ` Feedback Session: Post-interaction feedback
        collected through child-friendly surveys and
        guardian interviews.`,
    ],
    satisfactionTitle: "User Satisfaction",
    satisfactionDescription: [
      ` 9 out of 10 children reported enjoying the
        experience and wanting to play with the toys
        again.`,
      `The speech capabilities of the Hot Wheels car were
        particularly well-received, with children
        describing it as "cool" and "fun."`,
      `The vision-to-text feature of the Barbie doll
        received mixed reviews, with some children finding
        it confusing or unresponsive at times.`,
    ],
    recommendationTitle: "User Satisfaction",
    recommendationIntro: "Based on the findings, it is recommended to:",
    recommendationDescription: [
      `Expand the speech and vision-to-text capabilities
        to offer more variety and responsiveness.`,
      `Improve voice recognition and object detection
        algorithms for smoother interactions.`,
      `Explore options for user-customizable
        personalities or storylines to enhance engagement.`,
      `Conduct further testing with a larger sample size
        and in real-world play environments.`,
    ],
    recommendationOutro: `Overall, the Interactive Storytelling Toys
      demonstrated promising potential for fostering
      imaginative play and engaging children through
      innovative technology. With refinements based on
      user feedback, these toys could offer a unique and
      captivating play experience.`,
  },
};

export { mattel };
