import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Form from "react-bootstrap/Form";
import { auth, db, storage } from "../../firebase/config";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  query,
  where,
  onSnapshot,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import DOMPurify from "dompurify";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";

// import bootstrap components
import { Button, DropdownButton, Dropdown } from "react-bootstrap";

// import fontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faEye,
  faPen,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

export default function BBSolutionTile({
  item,
  index,
  solutionsData,
  setSolutionsData,
  handleSolutionVote,
  setSolutionModalData,
  solutionsVotesData,
  handleAddToBuildDeck,
}) {
  const currentUser = auth.currentUser.uid;
  const [userVote, setUserVote] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const [readMoreVisible, setReadMoreVisible] = useState({});
  const [totalGreenVotesForUser, setTotalGreenVotesForUser] = useState(0);
  const [totalRedVotesForUser, setTotalRedVotesForUser] = useState(0);
  const [totalGreenVotesForSolution, setTotalGreenVotesForSolution] =
    useState(0);
  const [totalRedVotesForSolution, setTotalRedVotesForSolution] = useState(0);
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);
  const [dateOptions, setDateOptions] = useState({
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [solutionCreationDate, setSolutionCreationDate] = useState(
    new Date().toLocaleDateString("en-US", dateOptions)
  );
  const [newArgument, setNewArgument] = useState({
    type: "Select an argument type...",
    argument: "",
    solutionId: item.solutionId,
    userId: auth.currentUser.uid,
    creationDate: new Date(),
  });
  const [proConArguments, setProConArguments] = useState([]);
  const [userVotesOnArguments, setUserVotesOnArguments] = useState([]);
  const [userLikedStatus, setUserLikedStatus] = useState({});
  const [allSolutionArgumentLikes, setAllSolutionArgumentLikes] = useState([]);
  const [likeCounter, setLikeCounter] = useState(0);

  const [isSolutionImageEditMode, setIsSolutionImageEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newSolutionImageURL, setNewSolutionImageURL] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [editSolutionImagePictureError, setEditSolutionImagePictureError] =
    useState("");
  const [ideaInEditMode, setIdeaInEditMode] = useState("");

  // trigger useEffect hook

  useEffect(() => {
    const currentUser = auth.currentUser;
    try {
      if (item.creationDate instanceof Timestamp) {
        setSolutionCreationDate(
          item.creationDate.toDate().toLocaleDateString("en-US", dateOptions)
        );
      } else {
        const firestoreTimestamp = Timestamp.fromDate(item.creationDate);
        setSolutionCreationDate(
          firestoreTimestamp.toDate().toLocaleDateString("en-US", dateOptions)
        );
        console.log("creationDate converted to firestore timestamp");
      }
    } catch (e) {
      console.log(
        "error message when trying to get solution creation date",
        e.message
      );
    }

    const totalGreenVoteCountForUserForSolution = solutionsVotesData.filter(
      (voteData) =>
        voteData.userId === currentUser.uid &&
        voteData.vote === "agree" &&
        voteData.solutionId === item.solutionId
    ).length;
    if (totalGreenVoteCountForUserForSolution > 0) {
      setUserVote("agree");
    }

    const totalCancelCountForUserForSolution = solutionsVotesData.filter(
      (voteData) =>
        voteData.userId === currentUser.uid &&
        voteData.vote === "cancel" &&
        voteData.solutionId === item.solutionId
    ).length;
    if (totalCancelCountForUserForSolution > 0) {
      setUserVote("cancel");
    }

    const totalRedVoteCountForUserForSolution = solutionsVotesData.filter(
      (voteData) =>
        voteData.userId === currentUser.uid &&
        voteData.vote === "disagree" &&
        voteData.solutionId === item.solutionId
    ).length;
    if (totalRedVoteCountForUserForSolution > 0) {
      setUserVote("disagree");
    }

    const totalGreenVoteCountForUser = solutionsVotesData.filter(
      (voteData) =>
        voteData.userId === currentUser.uid && voteData.vote === "agree"
    ).length;
    setTotalGreenVotesForUser(totalGreenVoteCountForUser);

    const totalRedVoteCountForUser = solutionsVotesData.filter(
      (voteData) =>
        voteData.userId === currentUser.uid && voteData.vote === "disagree"
    ).length;
    setTotalRedVotesForUser(totalRedVoteCountForUser);

    const totalGreenVoteCountForSolution = solutionsVotesData.filter(
      (voteData) =>
        voteData.vote === "agree" && voteData.solutionId === item.solutionId
    ).length;
    setTotalGreenVotesForSolution(totalGreenVoteCountForSolution);

    const totalRedVoteCountForSolution = solutionsVotesData.filter(
      (voteData) =>
        voteData.vote === "disagree" && voteData.solutionId === item.solutionId
    ).length;
    setTotalRedVotesForSolution(totalRedVoteCountForSolution);
  }, [solutionsVotesData, item.creationDate]);

  // useEffect to fetch arguments from firebase collection "solutionArguments"

  useEffect(() => {
    const fetchArguments = async () => {
      try {
        const collectioRef = await collection(db, "arguments");
        const snapshot = await getDocs(collectioRef);
        const argumentsData = snapshot.docs.map((doc) => ({
          argumentId: doc.id,
          ...doc.data(),
        }));
        setProConArguments(argumentsData);
      } catch (err) {
        console.error("Error fetching arguments:", err);
      }
    };

    fetchArguments();
  }, [newArgument]);

  // useEffect to fetch user likes on arguments from firebase collection "solutionArgumentsLikes"

  useEffect(() => {
    const fetchUserLikes = async () => {
      try {
        const collectionRef = await collection(db, "argumentsLikes");
        const q = query(
          collectionRef,
          where("userId", "==", auth.currentUser.uid)
        );
        const snapshot = await getDocs(q);
        const likesData = snapshot.docs.map((doc) => doc.data());
        let likesStatus = {};
        likesData.forEach((like) => {
          likesStatus[like.argumentId] = like.voteCount === 1;
        });
        setUserLikedStatus(likesStatus);
      } catch (err) {
        console.error("Error fetching user likes:", err);
      }
    };
    fetchUserLikes();
  }, [likeCounter]);

  // useEffect to fetch all likes on arguments from firebase collection "solutionArgumentsLikes"
  useEffect(() => {
    const solutionArgumentsLikesCollection = collection(db, "argumentsLikes");
    const solutionArgumentsLikesQuery = query(
      solutionArgumentsLikesCollection,
      where("solutionId", "==", item.solutionId)
    );

    const unsubscribe = onSnapshot(
      solutionArgumentsLikesQuery,
      (snapshot) => {
        try {
          const allLikesData = snapshot.docs.map((doc) => doc.data());
          setAllSolutionArgumentLikes(allLikesData);
        } catch (err) {
          console.error("Error fetching all likes:", err);
        }
      },
      (error) => {
        console.error("Error fetching all likes:", error);
      }
    );

    // Cleanup function: this will be executed if the component is unmounted
    return () => {
      unsubscribe();
    };
  }, [item.solutionId]);

  // handle functions

  const handleReadMore = (index) => {
    setReadMoreVisible((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleGreenButtonHover = (index, isHovering) => {
    if (isHovering) {
      setHoveredButtonIndex(index);
    } else {
      setHoveredButtonIndex(null);
    }
  };

  const handleArgumentTypeChange = (e) => {
    setNewArgument({
      ...newArgument,
      [e.currentTarget.name]: e.currentTarget.value,
      emoji: e.currentTarget.value === "🟢 Pro Argument" ? "🟢" : "🔴",
    });
  };

  const handleQuillArgumentContentChange = (content, fieldName) => {
    setNewArgument({
      ...newArgument,
      [fieldName]: content,
    });
  };

  const handleArgumentSubmit = async (e) => {
    e.preventDefault();
    const argumentWithVotes = {
      ...newArgument,
      upVotes: 0, // Initialize upVotes to 0
      solutionId: item.solutionId,
    };

    // Save to Firebase
    const argumentsCollection = collection(db, "arguments");
    const argumentRef = doc(argumentsCollection);
    await setDoc(argumentRef, argumentWithVotes);

    // Update local state
    setProConArguments([...proConArguments, argumentWithVotes]);
    setNewArgument({ type: "Select an argument type...", argument: "" });
  };

  const handleShowArguments = (index) => {
    setSolutionsData((prevData) => {
      const newData = [...prevData];
      newData[index].argumentsVisible = !newData[index].argumentsVisible;
      return newData;
    });
  };

  const handleLike = async (argumentId) => {
    const currentUserId = auth.currentUser.uid;
    const solutionId = item.solutionId;
    const currentTime = new Date();

    const argumentsLikesCollection = collection(db, "argumentsLikes");
    const likeDocRef = doc(
      argumentsLikesCollection,
      `${currentUserId}_${argumentId}`
    );

    // Check if the user has already liked this argument
    const likeDocSnapshot = await getDoc(likeDocRef);
    const userLikedStatus = likeDocSnapshot.exists();

    if (userLikedStatus) {
      // If yes, decrease the count and update Firestore
      await updateDoc(likeDocRef, {
        voteCount: 0,
      });
      setUserLikedStatus((prevState) => ({
        ...prevState,
        [argumentId]: false,
      }));
    } else {
      // If no, increase the count and update Firestore
      const likeData = {
        userId: currentUserId,
        solutionId,
        argumentId,
        timestamp: currentTime,
        voteCount: 1,
      };
      await setDoc(likeDocRef, likeData);
      setUserLikedStatus((prevState) => ({ ...prevState, [argumentId]: true }));
    }
    setLikeCounter((prevState) => prevState + 1);
  };

  const handleToggleReadStatus = async () => {
    const ideasReadCollection = collection(db, "ideasRead");
    const readDocRef = doc(
      ideasReadCollection,
      `${auth.currentUser.uid}_${item.solutionId}`
    );

    try {
      const docSnapshot = await getDoc(readDocRef);

      if (docSnapshot.exists()) {
        const currentStatus = docSnapshot.data().readStatus;
        await updateDoc(readDocRef, { readStatus: !currentStatus });
        setHasRead(!currentStatus);
      } else {
        await setDoc(readDocRef, {
          userId: auth.currentUser.uid,
          solutionId: item.solutionId,
          readStatus: true,
        });
        setHasRead(true);
      }
    } catch (err) {
      console.error("Error updating read status:", err);
    }
  };

  useEffect(() => {
    const fetchReadStatus = async () => {
      const ideasReadCollection = collection(db, "ideasRead");
      const readDocRef = doc(
        ideasReadCollection,
        `${auth.currentUser.uid}_${item.solutionId}`
      );

      try {
        const docSnapshot = await getDoc(readDocRef);
        setHasRead(docSnapshot.exists() && docSnapshot.data().readStatus);
      } catch (err) {
        console.error("Error fetching read status:", err);
      }
    };

    fetchReadStatus();
  }, [item, auth.currentUser]);

  const handleSolutionImageChange = () => {
    setIsSolutionImageEditMode(!isSolutionImageEditMode);
    setIdeaInEditMode(item.solutionId);
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSolutionImageChangeSubmit = async () => {
    let imageFileURL = item.image;

    try {
      if (selectedFile) {
        // Check if the file extension is either .jpg, .png, .jpeg, or .webp
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        const validExtensions = ["jpg", "png", "jpeg", "webp"];

        if (!validExtensions.includes(fileExtension)) {
          setEditSolutionImagePictureError(
            "Invalid file format. Please upload a .webp, .jpg, .jpeg, or .png file."
          );
          setIsSolutionImageEditMode(!isSolutionImageEditMode);
          return; // Exit the function to avoid further processing
        }

        const storageRef = ref(storage, `solutionsImages/${selectedFile.name}`);
        setImageUploading(true);

        const uploadTask = uploadBytesResumable(storageRef, selectedFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress updates if needed
          },
          (error) => {
            setImageUploading(false);
            console.error("Error uploading file:", error);
          },
          async () => {
            try {
              imageFileURL = await getDownloadURL(uploadTask.snapshot.ref);
              setNewSolutionImageURL(imageFileURL);

              // Update the current solution's image URL within the solutions collection of the Firestore database
              const solutionsCollection = collection(db, "solutions");
              const solutionDocRef = doc(solutionsCollection, item.solutionId);
              await updateDoc(solutionDocRef, {
                image: imageFileURL,
              });

              setImageUploading(false);
              setIsSolutionImageEditMode(!isSolutionImageEditMode);
            } catch (error) {
              setImageUploading(false);
              console.error("Error updating solution image:", error.message);
            }
          }
        );
      } else {
        setEditSolutionImagePictureError("Please select a file");
        setIsSolutionImageEditMode(!isSolutionImageEditMode);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className='BlackboxCISolutions-grid-tile' key={index}>
      <div
        className='BlackboxCISolutions-grid-tile-cardInfo'
        style={
          item.approved
            ? { background: "rgba(144,238,144,0.5)" }
            : { background: "rgba(0,0,0,0)" }
        }
      >
        <div className='BlackboxCISolutions-grid-tile-cardInfo-creator'>
          <Button
            onClick={() =>
              handleAddToBuildDeck({
                cardTitle: item.ideaTitle,
                cardSubTitle: "shared Solution",
                imageUrl: item.image,
                cardType: "Idea",
                cardContent: item.ideaDescription,
                cardtrl: item.trl,
              })
            }
            className='BlackboxCISolutions-rowForCollectionOfCards-card-contentContainer-readMoreButtonContainer-button addToDeckButton'
          >
            +
          </Button>
          <button
            onClick={handleToggleReadStatus}
            className={hasRead ? "IdeasReadButton Read" : "IdeasReadButton"}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
        <div className='BlackboxCISolutions-grid-tile-cardInfo-date'>
          {solutionCreationDate}
        </div>
      </div>
      <div className='BlackboxCISolutions-grid-tile-image'>
        {!isSolutionImageEditMode && (
          <>
            <img
              src={newSolutionImageURL ? newSolutionImageURL : item.image}
              alt={item.contentIWant}
            />
            {currentUser === item.userId && (
              <button
                className='changeImageButton'
                onClick={handleSolutionImageChange}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
          </>
        )}
        {item.solutionId === ideaInEditMode && isSolutionImageEditMode && (
          <div className='change-solutionImage-interface'>
            <div {...getRootProps()} className='image-dropperContainer'>
              <input {...getInputProps()} />
              <Button className='image-dropperContainer-chooseFileButton'>
                Choose File
              </Button>
              {selectedFile ? (
                <div className='image-dropperContainer-image-drop-zone'>
                  {selectedFile.path}
                </div>
              ) : (
                <div className='image-dropperContainer-image-drop-zone'>
                  or drop a file here...
                </div>
              )}
            </div>
            <Button
              onClick={handleSolutionImageChangeSubmit}
              className='image-dropperContainer-saveButton'
            >
              {!imageUploading && <FontAwesomeIcon icon={faSave} />}
              {imageUploading && <Spinner className='SpinnerSymbol' />}
            </Button>
          </div>
        )}
      </div>
      <div className='BlackboxCISolutions-grid-tile-title'>
        <h4
          style={{
            marginTop: "30px",
            marginBottom: "5px",
            fontSize: "18px",
          }}
        >
          <b>{item.ideaTitle}</b>
        </h4>
      </div>
      {item.shortRecap && (
        <div className='BlackboxCISolutions-grid-tile-shortRecap'>
          <p>
            {item.shortRecap}{" "}
            <button
              className='BlackboxCISolutions-grid-tile-shortRecap-readMore-button'
              onClick={() => handleReadMore(index, item)}
            >
              {readMoreVisible[index] ? "Read less..." : "Read more..."}
            </button>
          </p>
        </div>
      )}
      {(!item.shortRecap || readMoreVisible[index]) && (
        <div className='BlackboxCISolutions-grid-tile-solution'>
          {item.inspiration && (
            <div className='InspiredBySection'>
              <h5>Inspired by:</h5>
              <div className='InspiredBySection-items'>
                {item.inspiration.map(
                  (inspirationItem, inspirationItemIndex) => (
                    <div
                      className='InspiredBySection-items-item'
                      key={inspirationItemIndex}
                    >
                      <div className='InspiredBySection-items-item-imageContainer'>
                        <img src={inspirationItem.imageUrl} alt={item.name} />
                      </div>
                      <div
                        className='InspiredBySection-items-item-textContainer'
                        style={{ wordWrap: "break-word" }}
                      >
                        {inspirationItem.title}
                        <br />
                        {inspirationItem.type}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <h5>💡 Idea Description</h5>
          <div
            style={{ wordWrap: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.ideaDescription),
            }}
          ></div>
          <h5>🎯 Technology Readiness Level (TRL)</h5>
          <div
            style={{ wordWrap: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.trl),
            }}
          ></div>
        </div>
      )}
      <div className='BlackboxCISolutions-grid-tile-votingTitle'>
        <p>Should we prioritize this idea in the design sprint?</p>
      </div>
      <div
        className={
          item.userVote === ""
            ? "BlackboxCISolutions-grid-tile-votingButtons"
            : "BlackboxCISolutions-grid-tile-votingButtons inactive"
        }
      >
        {totalGreenVotesForUser < 5 && (
          <button
            className={userVote === "agree" ? "active" : "inactive"}
            title={totalGreenVotesForUser >= 5 ? "No green votes left!" : ""}
            onClick={() => handleSolutionVote(index, "agree", item)}
            onMouseEnter={() => handleGreenButtonHover(index, true)}
            onMouseLeave={() => handleGreenButtonHover(index, false)}
          >
            {hoveredButtonIndex === index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,255,255,0.8)",
                }}
              >
                🟢 votes left ({5 - totalGreenVotesForUser})
              </div>
            )}
            {hoveredButtonIndex !== index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                🟢 Yes
              </div>
            )}
          </button>
        )}
        {totalGreenVotesForUser === 5 && userVote !== "agree" && (
          <button
            disabled
            title={
              totalGreenVotesForUser >= 5
                ? "No green votes left! First remove a green vote somewhere else to green vote this idea."
                : ""
            }
            className='inactive'
          >
            🟢 0 votes left
          </button>
        )}
        {totalGreenVotesForUser === 5 && userVote === "agree" && (
          <button
            className={userVote === "agree" ? "active" : "inactive"}
            title={
              totalGreenVotesForUser >= 5
                ? "Reclick this button to remove the green vote"
                : ""
            }
            onClick={() => handleSolutionVote(index, "agree", item)}
            onMouseEnter={() => handleGreenButtonHover(index, true)}
            onMouseLeave={() => handleGreenButtonHover(index, false)}
          >
            {hoveredButtonIndex === index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,255,255,0.8)",
                }}
              >
                🟢 votes left ({5 - totalGreenVotesForUser})
              </div>
            )}
            {hoveredButtonIndex !== index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                🟢 Yes
              </div>
            )}
          </button>
        )}
        {totalRedVotesForUser < 10 && (
          <button
            className={userVote === "disagree" ? "active" : "inactive"}
            title={totalGreenVotesForUser >= 10 ? "No red votes left!" : ""}
            onClick={() => handleSolutionVote(index, "disagree", item)}
            onMouseEnter={() => handleGreenButtonHover(index, true)}
            onMouseLeave={() => handleGreenButtonHover(index, false)}
          >
            {hoveredButtonIndex === index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,255,255,0.8)",
                }}
              >
                🔴 votes left ({10 - totalRedVotesForUser})
              </div>
            )}
            {hoveredButtonIndex !== index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                🔴 No
              </div>
            )}
          </button>
        )}
        {totalRedVotesForUser === 10 && userVote !== "disagree" && (
          <button
            disabled
            title={
              totalRedVotesForUser >= 5
                ? "No red votes left! First remove a red vote somewhere else to red vote this idea."
                : ""
            }
            className='inactive'
          >
            🔴 0 votes left
          </button>
        )}
        {totalRedVotesForUser === 10 && userVote === "disagree" && (
          <button
            className={userVote === "disagree" ? "active" : "inactive"}
            title={
              totalRedVotesForUser >= 10
                ? "Reclick the button to remove the red vote"
                : ""
            }
            onClick={() => handleSolutionVote(index, "disagree", item)}
            onMouseEnter={() => handleGreenButtonHover(index, true)}
            onMouseLeave={() => handleGreenButtonHover(index, false)}
          >
            {hoveredButtonIndex === index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "rgba(255,255,255,0.8)",
                }}
              >
                🔴 votes left ({10 - totalRedVotesForUser})
              </div>
            )}
            {hoveredButtonIndex !== index && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                🔴 No
              </div>
            )}
          </button>
        )}
      </div>
      <div
        className={
          userVote || totalGreenVotesForUser >= 5
            ? "BlackboxCISolutions-grid-tile-score"
            : "BlackboxCISolutions-grid-tile-score inactive"
        }
      >
        <div className='BlackboxCISolutions-grid-tile-score-votes'>
          <b>{totalGreenVotesForSolution + totalRedVotesForSolution}</b> votes
        </div>
        <div className='BlackboxCISolutions-grid-tile-score-agree'>
          <b style={{ color: "lightGreen" }}>
            {totalGreenVotesForSolution
              ? Math.round(
                  (totalGreenVotesForSolution /
                    (totalGreenVotesForSolution + totalRedVotesForSolution)) *
                    100
                )
              : 0}
            %
          </b>{" "}
          Yes
        </div>
        <div className='BlackboxCISolutions-grid-tile-score-disagree'>
          <b style={{ color: "lightCoral" }}>
            {totalRedVotesForSolution
              ? Math.round(
                  (totalRedVotesForSolution /
                    (totalGreenVotesForSolution + totalRedVotesForSolution)) *
                    100
                )
              : 0}
            %
          </b>{" "}
          No
        </div>
      </div>
      <div className='BlackboxCISolutions-grid-tile-arguments'>
        <button
          className='BlackboxCISolutions-grid-tile-arguments-unfoldButton'
          onClick={() => handleShowArguments(index)}
        >
          See arguments Pro & Con{" ("}
          {
            proConArguments.filter((arg) => arg.solutionId === item.solutionId)
              .length
          }
          {") "}
          <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "10px" }} />
        </button>
        {item.argumentsVisible && (
          <div className='BlackboxCISolutions-grid-tile-arguments-pro'>
            {proConArguments
              .filter((arg) => arg.solutionId === item.solutionId)
              .map((item, index) => (
                <div
                  className='BlackboxCISolutions-grid-tile-arguments-pro-argument'
                  key={index}
                >
                  <div className='BlackboxCISolutions-grid-tile-arguments-pro-argument-icon'>
                    {item.emoji}
                  </div>
                  <div
                    style={{ wordWrap: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item.argument),
                    }}
                    className='BlackboxCISolutions-grid-tile-arguments-pro-argument-text'
                  ></div>
                  <div className='argument-likes'>
                    <button
                      onClick={() => handleLike(item.argumentId)}
                      className={
                        userLikedStatus[item.argumentId] ? "liked" : ""
                      }
                    >
                      👍{" "}
                      {
                        allSolutionArgumentLikes.filter(
                          (like) =>
                            like.argumentId === item.argumentId &&
                            like.voteCount === 1
                        ).length
                      }
                    </button>
                  </div>
                </div>
              ))}

            <Form>
              <Form.Group>
                <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                  + Add an argument
                </Form.Label>
                <Form.Control
                  as='select'
                  value={newArgument.type}
                  name='type'
                  onChange={handleArgumentTypeChange}
                  className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
                >
                  <option>Select an argument type...</option>
                  <option>🟢 Pro Argument</option>
                  <option>🔴 Con Argument</option>
                </Form.Control>
              </Form.Group>
              {newArgument.type !== "Select an argument type..." && (
                <Form.Group>
                  <Form.Label className='BlackboxCISolutions-shareSolutionsModal-body-inputLabel'>
                    Argument
                  </Form.Label>
                  <ReactQuill
                    theme='bubble'
                    as='textarea'
                    value={newArgument.argument}
                    rows={3}
                    name='argument'
                    onChange={(content) =>
                      handleQuillArgumentContentChange(content, "argument")
                    }
                    className='BlackboxCISolutions-shareSolutionsModal-body-inputField'
                  />
                </Form.Group>
              )}
              {newArgument.argument !== "" && (
                <button
                  onClick={handleArgumentSubmit}
                  className='BlackboxCISolutions-grid-tile-arguments-shareButton'
                >
                  + Add your argument
                </button>
              )}
            </Form>
          </div>
        )}
      </div>
      {item.approved && (
        <div
          className='BlackboxCISolutions-grid-tile-shareIdea'
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
        >
          <button
            className='BlackboxCISolutions-grid-tile-arguments-shareButton'
            style={{ background: "rgba(144,238,144,0.5)" }}
            onClick={() =>
              setSolutionModalData({
                visible: true,
                item: item,
              })
            }
          >
            + Share a solution
          </button>
        </div>
      )}
    </div>
  );
}
