import React from "react";
import "./WhoWeWorkFor.css";
import LogoCarousel from "../components/LogoCarousel";

function WhoWeWorkFor() {
  return (
    <div className='WhoWeWorkFor' id='OurCustomers'>
      <h1>Who Do We Serve?</h1>
      <p>
        We stand by all dreamers who dare to challenge the status quo.
        <br />
        Our clients range from ambitious entrepreneurs within small start-ups,
        dynamic scale-ups, and agile SMEs, to innovative intrapreneurs nestled
        within large multinationals. We serve those who are eager to make their
        organization fly with generative AI.
      </p>
      <LogoCarousel />
      <a href='mailto:gilles@howtofly.ai'>CONTACT US</a>
    </div>
  );
}

export default WhoWeWorkFor;
