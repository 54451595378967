import React from "react";
import "./Case.css";

const Case1 = () => {
  return (
    <div className='case case-container'>
      <h1 className='title'>HowToFly Workshop Case Study</h1>

      <section className='context'>
        <h2>The Context</h2>
        <p>
          A bespoke jewelry designer brand was looking for ways to improve their
          internal workflow and/or augment their customer experience by
          leevraging the power of generative AI.
        </p>
      </section>

      <section className='process'>
        <h2>The Process</h2>
        <p>
          During a one day workshop, we informed our customers about the current
          capabilities of generative AI, and helped them identify the most
          valuable use cases for their biggest challenges.
        </p>
        <img
          src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
          alt='Generative AI Briefing Slides'
        />
      </section>

      <section className='workshop'>
        <h2>The Workshop</h2>

        <div className='agenda'>
          <h3>A. The Workshop Agenda</h3>
          {/* Agenda items */}
          <p>
            <strong>10:00-11:00:</strong> Inform - A deep dive into generative
            AI.
          </p>
          <p>
            <strong>11:00-12:00:</strong> Inspire - AI use cases across
            industries.
          </p>
          <p>
            <strong>13:00-13:30:</strong> Brainstorm Alone - Identifying
            potential use cases.
          </p>
          <p>
            <strong>13:30-14:00:</strong> Lightning Rounds - Pitching and
            feedback.
          </p>
          <p>
            <strong>14:15-15:00:</strong> Ideas and Prioritisation -
            Collaboratively adding and prioritizing ideas.
          </p>
          <p>
            <strong>15:00-16:00:</strong> Building Spec Sheet - For a proof of
            concept.
          </p>
        </div>

        <div className='outcome'>
          <h3>B. The Workshop Outcome</h3>
          <p>- 86 potential AI use cases, ranked for impact and feasibility.</p>
          <img
            src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
            alt='86 AI Use Cases'
          />
          <p>- Detailed specs for a prioritized proof of concept.</p>
          <img
            src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
            alt='Detailed Specs'
          />
        </div>

        <img
          src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
          alt='The Workshop'
        />
      </section>

      <section className='proof-of-concept'>
        <h2>Building Proof-of-Concept (PoC)</h2>
        <p>
          Developed entirely by HowToFly, turning ideas into a testable PoC.
        </p>
        <img
          src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
          alt='PoC Code'
        />

        <div className='outcome'>
          <h3>The PoC Outcome</h3>
          <p>Explore the resulting PoC at the provided link.</p>
          <img
            src='https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png'
            alt='PoC Platform'
          />
        </div>
      </section>
    </div>
  );
};

export default Case1;
