import "./Website.css";

import React from "react";

import NavbarWebTop from "../components/NavbarWebTop";

import LandingPage from "./LandingPage";
import ChatbotPage from "./ChatbotPage";
import WhoWeWorkFor from "./WhoWeWorkFor";
import OurPromises from "./OurPromises";
import ExperimentPage from "./ExperimentPage";
import MobileExperimentPage from "./MobileExperimentPage";
import BlackboxMainWebsiteDescription from "./BlackboxMainWebsiteDescription";
import OurMethodology from "./OurMethodology";
import Footer from "./Footer";
import IntroductionPage from "./IntroductionPage";
import SevenStepPage from "./SevenStepPage";
import SevenStepPageMobile from "./SevenStepPageMobile";
import HowWeHelp from "./HowWeHelp";
import monthlyScent from "../assets/webp/SelectedForYou.webp";
import rosieDrinks from "../assets/webp/RosieDrinks.webp";
import songRqst from "../assets/webp/SongRqst.webp";
import blackboxVisual from "../assets/webp/BlackBoxVisual_noLogo.webp";
import JewelryDesigner from "../assets/JewelryDesigner.png";
import RebornGames from "../assets/RebornGames.png";
import MonthlyScent from "../assets/MonthlyScent.png";
import { v4 as uuidv4 } from "uuid";

/* Old code

const experiencePages = [{title:"Identify", text:"Through internal briefings, desk research, and consumer interviews, we carefully scope the project, help you understand your customer's needs, spark your imagination, outline hypotheses, set-up experiments, and identify the most promising opportunities to grow your brand.", image:identify}, {title:" Develop", text:"With tools and frameworks such as figma, photoshop, blender, stable diffusion, dreambooth, react.js, firebase, and many more, we rapidly turn your thoughts in concept mockups, prototypes and minimal viable products, ready to be tested by your target audience.", image:build}, {title:"Validate", text:"Through carefully designed experiments we fastforward you towards the future where you can experience customer reactions, before making any unnecessary and expensive commitments.", image:validate} ]*/

const blackboxDescription = [
  {
    title: "Our Platform",
    text: "We use our own AI-driven platform to inspire and engage your team in finding the best generative AI use cases to supercharge their creativity and efficiency, or augment customer experiences.",
    image: blackboxVisual,
  },
  {
    title: "Leverage internal creativity & expertise",
    text: "Blackbox generative AI, crowdsourcing, and gamification techniques help organiztions to engage employees within their organization and fully leverage internal creativity, experience, and knowledge.",
    image: blackboxVisual,
  },
];

const currentExperiments = [
  {
    title: `Jewelry Designer`,
    description: `Allowing customers to design their own bespoke jewelry through a custom fine-tuned text-to-image generator`,
    image: JewelryDesigner,
    endpoint: "/",
    id: uuidv4(),
  },
  {
    title: `Dynamic Board Games`,
    description: `A dynamic board game experience, where game elements change based on AI-generated events`,
    image: RebornGames,
    id: uuidv4(),
    endpoint: "/",
  },
  {
    title: `Perfume Recommender`,
    description: `A perfume recommendation tool proposing you perfumes based on your preferences`,
    id: uuidv4(),
    image: MonthlyScent,
    endpoint: "/",
  },
];

const futureExperiments = [
  {
    title: `SongRqst`,
    description: `Allowing party guests to request and upvote their favorite songs`,
    id: uuidv4(),
  },
  {
    title: `StandZero`,
    description: `Record & live-stream amateur sport games`,
    endpoint: "/standzero",
    id: uuidv4(),
  },
  {
    title: `KikoFood`,
    description: `Deliciously healthy snacks for kids with an engaging brand experience`,
    id: uuidv4(),
  },
  {
    title: `RebornGames`,
    description: `Augmenting the traditional board game experience`,
    id: uuidv4(),
  },
  {
    title: `RosieDrinks`,
    description: `A refreshing, cold rosé, popping-up whenever you need it the most`,
    id: uuidv4(),
  },
  {
    title: "Brilliants",
    description:
      "Collectively solving the biggest business challenges, one micro-challenge at a time.",
    id: uuidv4(),
  },
  {
    title: "LilaLinen",
    description: "100% duurzaam, 100% lokaal, 100% linnen slaapzakjes",
    id: uuidv4(),
  },
  {
    title: "Captic",
    description:
      "See more with an affordable, AI-driven food grade camera system",
    id: uuidv4(),
  },
];

const Website = () => {
  return (
    <div className='Website'>
      <nav>
        <NavbarWebTop />
      </nav>
      <header className='Website-header'>
        <LandingPage />
        <IntroductionPage />
        <OurPromises />
        <WhoWeWorkFor />
        <OurMethodology />
        <BlackboxMainWebsiteDescription
          title={blackboxDescription[0].title}
          text={blackboxDescription[0].text}
          image={blackboxDescription[0].image}
          normalOrder={2 % 2 === 0 ? true : false}
        />

        {/* adding subscription pricing page and passing subscription content array as prop
        <ExperimentPage
          currentExperiments={currentExperiments}
          futureExperiments={futureExperiments}
        />*/}

        {/* adding mobile subscription pricing page and passing subscription content array as prop
        <MobileExperimentPage
          currentExperiments={currentExperiments}
          futureExperiments={futureExperiments}
        />*/}
        {/*<ChatbotPage />*/}
        <Footer />
      </header>
    </div>
  );
};

export default Website;
