import React, { useState } from "react";
import BlackboxCIKnowledgeBaseBarChartCard from "./BBKnowledgeBaseBarChartCard";

export default function BlackboxCIKnowledgeBaseCountryItem({
  chosenKnowledgeBaseItem,
}) {
  const [groceryChannelData, setGroceryChannelData] = useState({
    groceryChannelMarketShareSaltySnacks2022:
      chosenKnowledgeBaseItem.groceryChannelMarketShareSaltySnacks2022,
    groceryChannelMarketShareAllGroceries2022:
      chosenKnowledgeBaseItem.groceryChannelMarketShareAllGroceries2022,
    groceryChannelMarketShareAllGroceries2027:
      chosenKnowledgeBaseItem.groceryChannelMarketShareAllGroceries2027,
  });

  const [groceryRetailerData, setGroceryRetailerData] = useState({
    groceryChannelMarketShareSaltySnacks2022:
      chosenKnowledgeBaseItem.groceryChannelMarketShareSaltySnacks2022,
    groceryChannelMarketShareAllGroceries2022:
      chosenKnowledgeBaseItem.groceryChannelMarketShareAllGroceries2022,
    groceryChannelMarketShareAllGroceries2027:
      chosenKnowledgeBaseItem.groceryChannelMarketShareAllGroceries2027,
  });

  // functions to prepare data to be used to create needs barchart
  const prepareDataForChannelBarChart = () => {
    let data = chosenKnowledgeBaseItem;
    return {
      labels: Object.keys(data.groceryChannelMarketShareSaltySnacks2022),
      datasets: [
        {
          label: "Market Share Salty Snacks 2022",
          data: Object.values(data.groceryChannelMarketShareSaltySnacks2022),
          backgroundColor: "rgba(247,168,0,1)",
          borderColor: "rgb(247,168,0,1)",
          borderWidth: 1,
        },
        {
          label: "Market Share All Groceries 2022",
          data: Object.values(data.groceryChannelMarketShareAllGroceries2022),
          backgroundColor: "rgba(192,68,111,1)",
          borderColor: "rgb(192,68,111,1)",
          borderWidth: 1,
        },
        {
          label: "Market Share All Groceries 2027",
          data: Object.values(data.groceryChannelMarketShareAllGroceries2027),
          backgroundColor: "rgba(255,209,203,1)",
          borderColor: "rgb(255,209,203)",
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareDataForOccasionBarChart = () => {
    let data = chosenKnowledgeBaseItem;
    return {
      labels: Object.keys(data.countryOccasionsTotalFood),
      datasets: [
        {
          label: "Distribution total food consumption",
          data: Object.values(data.countryOccasionsTotalFood),
          backgroundColor: "rgba(192,68,111,1)",
          borderColor: "rgb(192,68,111,1)",
          borderWidth: 1,
        },
        {
          label: "Distribution salty snack consumption",
          data: Object.values(data.countryOccasionsSaltySnacks),
          backgroundColor: "rgba(247,168,0,1)",
          borderColor: "rgb(247,168,0,1)",
          borderWidth: 1,
        },
        {
          label: "Distribution Pringles consumption",
          data: Object.values(data.countryOccasionsPringles),
          backgroundColor: "rgba(205,32,38,1)",
          borderColor: "rgb(205,32,38)",
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareDataForRetailerBarChart = () => {
    let data = chosenKnowledgeBaseItem;
    return {
      labels: data.top10PopularGroceryRetailers.map(
        (retailer) => retailer.name
      ),
      datasets: [
        {
          label: "Retailers",
          data: data.top10PopularGroceryRetailers.map(
            (retailer) => retailer.allGrocerySales2022Billion
          ),
          backgroundColor: "rgba(192,68,111,1)",
          borderColor: "rgb(192,68,111)",
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareDataForSKUBarChart = (chosenType) => {
    let data = chosenKnowledgeBaseItem;
    let chartData = [];
    if (chosenType === "manufacturer") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackManufacturers;
    }
    if (chosenType === "brand") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackBrands;
    }
    if (chosenType === "flavour") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackSubFlavours;
    }
    if (chosenType === "packFormat") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackPackFormat;
    }
    if (chosenType === "category") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackCategory;
    }
    if (chosenType === "baseIngredient") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackBaseIngredients;
    }
    if (chosenType === "bakingProcess") {
      chartData = data.MarketShareTop10CheeseFlavouredSaltySnackBakingProcess;
    }
    return {
      labels: chartData.map((item) => item.name),
      datasets: [
        {
          label: chartData[0].type,
          data: chartData.map((item) => item.marketShare),
          backgroundColor: "rgba(247,168,0,1)",
          borderColor: "rgb(247,168,0)",
          borderWidth: 1,
        },
      ],
    };
  };

  // rendering of the main component /////////////////////////////////////////////

  return (
    <div className='BlackboxCIKnowledgeBaseItem country'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow'>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={`flag ${chosenKnowledgeBaseItem.country}`}
            className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image'
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn'>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-title'>
            Salty Sanck Sales ('22):
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item'>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-title'>
              All
            </div>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-data'>
              € {chosenKnowledgeBaseItem.allSaltySnackSalesBillion} B
            </div>
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item'>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-title'>
              Cheese-Flavoured
            </div>
            <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-item-data'>
              €{" "}
              {chosenKnowledgeBaseItem.allCheeseFlavouredSaltySnackSalesBillion}{" "}
              B
            </div>
          </div>
        </div>
      </div>
      {chosenKnowledgeBaseItem.groceryChannelMarketShareInsightDescription && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Most popular Grocery Channels in ‘22 & Projected 5Y Growth"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.groceryChannelMarketShareInsightDescription
          }
          prepareDataForBarChart={prepareDataForChannelBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
        />
      )}
      {chosenKnowledgeBaseItem.top10PopularGroceryRetailers && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Most popular Grocery Retailers (Total Grocery Sales in Billion Euro)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.top10PopularGroceryRetailersInsight
          }
          prepareDataForBarChart={prepareDataForRetailerBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackManufacturers && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack Manufacturers (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackManufacturersInsight
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"manufacturer"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBrands && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack Brands (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBrandsInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"brand"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackPackFormat && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack PackFormats (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackPackFormatInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"packFormat"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackCategory && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack Sub-Categories (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackCategoryInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"category"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBaseIngredients && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack Base Ingredients (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBaseIngredientsInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"baseIngredient"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBakingProcess && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack BakingProcess (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackBakingProcessInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"bakingProcess"}
        />
      )}
      {chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackSubFlavours && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={
            "Top Cheese-Flavoured Salty Snack Flavour Types (Value Market Share)"
          }
          cardDescriptionText={
            chosenKnowledgeBaseItem.MarketShareTop10CheeseFlavouredSaltySnackSubFlavoursInsights
          }
          prepareDataForBarChart={prepareDataForSKUBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
          chosenType={"flavour"}
        />
      )}
      {chosenKnowledgeBaseItem.CountryFlavourMap && (
        <div className='BlackboxCIKnowledgeBaseItem-FlavourMap'>
          <div className='BlackboxCIKnowledgeBaseItem-barChartCard-title'>
            {`Most Popular ${chosenKnowledgeBaseItem.countryAbbreviation} Cheese Flavoured SKUs & Taste Profile`}
          </div>
          <img
            src={chosenKnowledgeBaseItem.CountryFlavourMap}
            alt={`${chosenKnowledgeBaseItem.countryAbbreviation} Flavour Map`}
          />
        </div>
      )}
      {chosenKnowledgeBaseItem.countryOccasionsTotalFood && (
        <BlackboxCIKnowledgeBaseBarChartCard
          cardTitle={"Distribution of eating occasions across the day (in %)"}
          cardDescriptionText={
            chosenKnowledgeBaseItem.countryOccasionsTotalFoodInsights
          }
          prepareDataForBarChart={prepareDataForOccasionBarChart}
          chosenKnowledgeBaseItem={chosenKnowledgeBaseItem}
        />
      )}
      {chosenKnowledgeBaseItem.sources && (
        <div className='BlackboxCIKnowledgeBaseItem-infoCard sources'>
          <div className='BlackboxCIKnowledgeBaseItem-infoCard-title'>
            Data Sources
          </div>
          <div className='BlackboxCIKnowledgeBaseItem-infoCard-content sources'>
            <ul>
              {chosenKnowledgeBaseItem.sources.map((source, index) => (
                <li key={index}>
                  <a href={source.url} target='_blank'>
                    {source.link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
