import React, { useState } from "react";

// Import styles
import "./BBToolMenu.css";

// Import assets
import GrowthHackLogo from "../../assets/GrowthHackLogo.png";

// Import icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRocket,
  faTimes,
  faDashboard,
  faBasketShopping,
  faShop,
  faLocationPin,
  faBottleWater,
  faEarthEurope,
  faCheese,
  faFlask,
  faQuestion,
  faHatWizard,
  faBullseye,
  faRankingStar,
  faGear,
  faTools,
  faPen,
  faRightFromBracket,
  faRobot,
  faHome,
  faBinoculars,
  faLightbulb,
  faUserCheck,
  faWandMagicSparkles,
  faRoute,
  faPeopleCarryBox,
} from "@fortawesome/free-solid-svg-icons";

// Menu items
const menuItems = [
  { id: "BlackboxHomePage", icon: faHome, text: "Home Page" },
  {
    id: "BlackboxFactsBelieves",
    icon: faLightbulb,
    text: "Share Ideas",
  },
  {
    id: "BBRoleDescription",
    icon: faUserCheck,
    text: "Internal Job Profiles",
  },
  {
    id: "BBCustomerJourney",
    icon: faRoute,
    text: "Customer Journey",
  },
  {
    id: "BlackboxConsumerPersonasAndJourneys",
    icon: faBasketShopping,
    text: "Customer Personas",
  },
  {
    id: "BBCompanySKUs",
    icon: faBottleWater,
    text: "Our Product Portfolio",
  },
  {
    id: "BBKnowledgeBaseAiCapabilities",
    icon: faRobot,
    text: "GenAI Capabilities",
  },
];

// Reusable components

const MenuItem = ({ id, visibleContent, onClick, icon, text }) => (
  <a id={id} onClick={onClick}>
    <div className={visibleContent === id ? "active" : ""}>
      <span className='BlackboxTool-menu-items-item-icon'>
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className='BlackboxTool-menu-items-item-text'>{text}</span>
    </div>
  </a>
);

const MenuCloseButton = ({ setMenuActive }) => (
  <button className='MenuCloseButton' onClick={() => setMenuActive(false)}>
    <FontAwesomeIcon icon={faTimes} />
  </button>
);

/* Start main component -----------------------------------------------------------------------------------*/
/* ---------------------------------------------------------------------------------------------------------*/
/* ---------------------------------------------------------------------------------------------------------*/

export default function BlackboxToolMenu({
  handleMenuClick,
  handleSignOut,
  visibleContent,
  menuActive,
  setMenuActive,
}) {
  return (
    <>
      {menuActive && (
        <div className='BlackboxTool-menu'>
          <div className='BlackboxTool-menu-header'>
            <MenuCloseButton setMenuActive={setMenuActive} />
            <img
              className='logo'
              alt='logo of the growthHack tool'
              src={GrowthHackLogo}
            />
          </div>
          <div className='BlackboxTool-menu-items'>
            <div className='BlackboxTool-menu-items-titleHolder'>
              <h3
                style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}
              >
                Menu
              </h3>
            </div>
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                visibleContent={visibleContent}
                onClick={handleMenuClick}
                icon={item.icon}
                text={item.text}
              />
            ))}
          </div>
          <hr
            style={{
              borderColor: "rgba(255,255,255,0.1)",
              width: "100%",
              marginTop: "50px",
            }}
          />
          <div className='BlackboxTool-menu-settings'>
            <MenuItem
              id='BlackboxPlayerPage'
              onClick={handleMenuClick}
              icon={faUser}
              text='Player Page'
              visibleContent={visibleContent}
            />
            <MenuItem
              id='BlackboxRankings'
              onClick={handleMenuClick}
              icon={faRankingStar}
              text='Rankings'
              visibleContent={visibleContent}
            />
          </div>
          <div className='BlackboxTool-menu-logout'>
            <MenuItem
              id='BlackboxSignOut'
              onClick={handleSignOut}
              icon={faRightFromBracket}
              text='Sign Out'
            />
          </div>
        </div>
      )}
    </>
  );
}
