import React, { useState } from "react";

// import bootstrap components
import { Button } from "react-bootstrap";

// import custom components
import PersonaChatbot from "./BBPersonaChatbot";

// import css files
import "./BBKnowledgeBasePersonaItem.css";

export default function BlackboxCIKnowledgeBasePersonaItem({
  chosenKnowledgeBaseItem,
}) {
  const [personaCardVisible, setPersonaCardVisible] = useState(true);
  const [chatPageVisible, setChatPageVisible] = useState(false);
  const [journeyPageVisible, setJourneyPageVisible] = useState(false);

  const journeyTopics = [
    "Stage",
    "Activities",
    "EmotionShift",
    "PainPoints",
    "CheezItTouchpoints",
  ];

  const formatTopicTitle = (key) => {
    if (key === "hobbies") {
      return "Hobbies";
    }
    if (key === "gamingPreferences") {
      return "Gaming Preferences";
    }
    if (key === "favoriteGameGenres") {
      return "Favorite Game Genres";
    }
    if (key === "mainReasonsForPurchasingGames") {
      return "Main Reasons For Purchasing Games";
    }
    if (key === "commonPlacesToPlayGames") {
      return "Common Places To Play Games";
    }
    if (key === "favoriteBoardGames") {
      return "Favorite Board Games";
    }
  };
  const handlePersonaCardButtonClick = () => {
    setPersonaCardVisible(true);
    setChatPageVisible(false);
    setJourneyPageVisible(false);
  };
  const handleChatButtonClick = () => {
    setPersonaCardVisible(false);
    setChatPageVisible(true);
    setJourneyPageVisible(false);
  };
  const handleJourneyButtonClick = () => {
    setPersonaCardVisible(false);
    setChatPageVisible(false);
    setJourneyPageVisible(true);
  };
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow'>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={`image of ${chosenKnowledgeBaseItem.cardTitle}`}
            className='BlackboxCIKnowledgeBaseItem-generalInfoRow-imageColumn-image'
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn'>
          <Button
            className={
              personaCardVisible
                ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button activeButton"
                : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button"
            }
            onClick={handlePersonaCardButtonClick}
          >
            Persona Card
          </Button>
          <Button
            className={
              chatPageVisible
                ? "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button activeButton"
                : "BlackboxCIKnowledgeBaseItem-generalInfoRow-dataColumn-button"
            }
            onClick={handleChatButtonClick}
          >
            Chat
          </Button>
        </div>
      </div>
      {personaCardVisible && (
        <div className='persona-card'>
          <div className='infoContainer'>
            <h5>General Info</h5>
            <div className='info'>
              <strong>Name:</strong> {chosenKnowledgeBaseItem.name}
            </div>
            <div className='info'>
              <strong>Age:</strong> {chosenKnowledgeBaseItem.age}
            </div>
            <div className='info'>
              <strong>Relationship Status:</strong>{" "}
              {chosenKnowledgeBaseItem.relationshipStatus}
            </div>
            <div className='info'>
              <strong>Address:</strong> {chosenKnowledgeBaseItem.address}
            </div>
            <div className='info'>
              <strong>Occupation:</strong> {chosenKnowledgeBaseItem.occupation}
            </div>
            <div className='info'>
              <strong>Income:</strong> {chosenKnowledgeBaseItem.income}
            </div>
          </div>
          {[
            "hobbies",
            "gamingPreferences",
            "favoriteGameGenres",
            "mainReasonsForPurchasingGames",
            "commonPlacesToPlayGames",
            "favoriteBoardGames",
          ].map((key) => (
            <div className='infoContainer'>
              <section key={key}>
                <h5>{formatTopicTitle(key)}</h5>
                <div className='info'>
                  <ul>
                    {chosenKnowledgeBaseItem[key].map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </section>
            </div>
          ))}
        </div>
      )}
      {chatPageVisible && (
        <div className='chatbotContainer'>
          <PersonaChatbot chosenKnowledeBaseItem={chosenKnowledgeBaseItem} />
        </div>
      )}
    </div>
  );
}

// special component to make text bold before colon

class BoldBeforeColon extends React.Component {
  formatText(text) {
    const parts = text.split(":");
    if (parts.length > 1) {
      return (
        <div className='itemInJourneyCell'>
          <strong>{parts[0]}:</strong>
          <br />
          {parts.slice(1).join(":")}
        </div>
      );
    }
    return text;
  }

  render() {
    return (
      <div className='contentOfJourneyCells'>
        {this.formatText(this.props.text)}
      </div>
    );
  }
}

class ColorBeforeColon extends React.Component {
  formatText(text) {
    const parts = text.split(":");
    if (parts.length > 1) {
      return (
        <div>
          <strong style={{ color: "rgb(247,168,0)" }}>{parts[0]}</strong>
          <br />
          {parts.slice(1).join(":")}
        </div>
      );
    }
    return text;
  }

  render() {
    return <div>{this.formatText(this.props.text)}</div>;
  }
}
