import React, { useEffect, useState } from "react";
import "./OurMethodology.css";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faLightbulb,
  faShield,
  faRobot,
  faFlaskVial,
  faCircleNodes,
} from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

const MobileTileComponent = ({ number, title, description }) => {
  return (
    <div className='OurMethodology-mobileSection-tile'>
      <div className='OurMethodology-mobileSection-tile-number'>{number}</div>
      <div className='OurMethodology-mobileSection-tile-title'>{title}</div>
      <div className='OurMethodology-mobileSection-tile-description'>
        {description}
      </div>
    </div>
  );
};

const OurMethodology = () => {
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    if (location.hash) {
      const anchor = document.querySelector(location.hash);
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [company, setCompany] = useState("your organization");
  const [flightPlanSteps1to3, setFlightPlanSteps1to3] = useState([
    {
      stepNumber: "1.",
      stepTitle: "Challenge Definition",
      stepDescription: `First, we set a clear project end-goal to assure the right focus throughout the entire project. Additionally, clear evaluation criteria are defined to prioritize the identified GenAI use cases and solutions.`,
    },
    {
      stepNumber: "2.",
      stepTitle: "Market Research",
      stepDescription: `Then, internal data is gathered and analyzed to avoid unnecessary double work. After an initial analysis, existing knowledge gaps are identified and closed through additional web research and expert interviews.`,
    },
    {
      stepNumber: "3.",
      stepTitle: "Inspiration Creation",
      stepDescription: `Once research is done, all collected data is translated into inspiring videos, personas, consumer journeys, market profiles, technology overviews, interactive quizzes, and custom LLMs capable of informing and inspiring the ideation target audience.`,
    },
  ]);
  const [flightPlanSteps4to6, setFlightPlanSteps4to6] = useState([
    {
      stepNumber: "4.",
      stepTitle: "Online Ideation",
      stepDescription: `Then, a 3-week online brainstorm is launched which engages a wide range of colleagues - each with their unique set of expertise across ${company}’s value chain - resulting in a relevant set of ideas, capable of the tackling the predefined challenge.`,
    },
    {
      stepNumber: "5.",
      stepTitle: "1-Day Design Sprint",
      stepDescription: `During a focused 1-day design sprint, 
      the number of ideas gets further extended and later reduced to the most relevant concepts, each with a clear plan to turn these concepts into a proof-of-concept.`,
    },
    {
      stepNumber: "6.",
      stepTitle: "POC Development & Testing",
      stepDescription: `After the 1-day design sprint, the created proof-of-concept plans get transformed into tangible proof-of-concepts of goldilocks quality, which can be tested with the relevant target users.`,
    },
  ]);
  const entireFlightPlanSteps = flightPlanSteps1to3.concat(flightPlanSteps4to6);
  return (
    <div id='OurMethodology'>
      <div className='ourMethodology desktop'>
        <div className='referenceCaseFlightPlan'>
          <div className='referenceCaseFlightPlan-titleBlock'>
            <div className='referenceCaseFlightPlan-titleBlock-content'>
              <h1>
                Our
                <br />
                Methodology
              </h1>
              <p>
                Let's walk you through our step-wise approach to unlock the full
                potential of generative AI within your entire organization.
              </p>
              <div className='titleBlockLineContainer'>
                <div className='horizontalLine'></div>
              </div>
            </div>
          </div>
          <div className='referenceCaseFlightPlan-steps step1to3'>
            <div className='verticalDottedLine step1to3 line1'></div>
            <div className='horizontalDottedLine step1to3 line2'></div>
            <div className='verticalDottedLine step1to3 line3'></div>
            <div className='horizontalDottedLine step1to3 line4'></div>
            <div className='verticalDottedLine step1to3 line5'></div>
            {flightPlanSteps1to3.map((step, index) => (
              <div
                className='referenceCaseFlightPlan-steps-stepContainer'
                key={index}
              >
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                  {step.stepNumber}
                </div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                  <h5>{step.stepTitle}</h5>
                  <p>{step.stepDescription}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='referenceCaseFlightPlan-steps step4to6'>
            <div className='horizontalDottedLine step1to3 line6'></div>
            <div className='verticalDottedLine step1to3 line7'></div>
            {flightPlanSteps4to6.map((step, index) => (
              <div
                className='referenceCaseFlightPlan-steps-stepContainer'
                key={index}
              >
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                  {step.stepNumber}
                </div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                  <h5>{step.stepTitle}</h5>
                  <p>{step.stepDescription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className='ourMethodology mobile'>
        <h2
          style={{ fontSize: "40px", color: "white", width: "86%" }}
          className='ourMethodology-mainTitle'
        >
          Our
          <br />
          Methodology
        </h2>
        <p
          style={{
            color: "white",
            width: "86%",
            margin: "10px auto",
            marginBottom: "30px",
            fontSize: "14px",
          }}
        >
          Let's walk you through our step-wise approach to unlock the full
          potential of generative AI within your entire organization.
        </p>
        <Swiper
          style={{ width: "100%" }}
          pagination={{ type: "bullets" }}
          index={index}
          onSlideChange={(swiper) => {
            handleChangeIndex(swiper.activeIndex);
          }}
          autoplay={{ delay: 10000 }}
        >
          {entireFlightPlanSteps.map((step, i) => (
            <SwiperSlide key={i}>
              <MobileTileComponent
                key={i}
                number={step.stepNumber}
                title={step.stepTitle}
                description={step.stepDescription}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default OurMethodology;
