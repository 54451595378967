import React, { useState } from "react";
import {
  faStore,
  faTruck,
  faBox,
  faPercent,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import css style sheets
import "./BBCardDeepDives.css";

export default function BBJobsToBeDone({ chosenKnowledgeBaseItem }) {
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <h5 className='BlackboxCIKnowledgeBaseItem-title'>
        {chosenKnowledgeBaseItem.cardTitle}
      </h5>
      <img src={chosenKnowledgeBaseItem.imageUrl} className='imageStyling' />

      <h2 className='cardDeepDiveTitle'>JTBD Description</h2>
      <div className='cardDeepDiveCard'>
        <p>{chosenKnowledgeBaseItem.cardDescription}</p>
        <div className='addToDeckPlusButton'>+</div>
      </div>
      <h2 className='cardDeepDiveTitle'>How Might We Questions (HMW)</h2>
      {chosenKnowledgeBaseItem.HMW.map((item) => (
        <div className='cardDeepDiveCard'>
          <p>{item}</p>
          <div className='addToDeckPlusButton'>+</div>
        </div>
      ))}
    </div>
  );
}
