// import visuals
import JourneyStep1 from "../../assets/Journey/JourneyStep1.png";
import JourneyStep2 from "../../assets/Journey/JourneyStep2.png";
import JourneyStep3 from "../../assets/Journey/JourneyStep3.png";
import JourneyStep4 from "../../assets/Journey/JourneyStep4.webp";
import JourneyStep5 from "../../assets/Journey/JourneyStep5.webp";
import JourneyStep6 from "../../assets/Journey/JourneyStep6.webp";
import JourneyStep7 from "../../assets/Journey/JourneyStep7.webp";
import JourneyStep8 from "../../assets/Journey/JourneyStep8.webp";

const customerJourneyIntro = {
  title: "Board-Game Journey",
  description: [
    "In order to augment our customer experience, we need to fuly understand the underlying needs or 'jobs' that customers are trying to accomplish. We should look at our customers as if they 'hire' our products or services to fulfill specific jobs. By identifying and understanding these jobs, we can design solutions that are precisely tailored to meet the customers' needs more effectively.",
  ],
};

const customerJourney = [
  {
    cardID: 1,
    cardTitle: "Step 1: Awareness",
    imageUrl: JourneyStep1,
    cardSubTitle: "Discover the Fun!",
    cardDescription:
      "This is the initial stage where potential customers become aware of a board game. This can happen through various channels such as social media, advertisements, word of mouth, board game reviews, blogs, or in-store displays. The key here is exposure to the product for the first time.",
    HMW: [
      "How might we use generative AI to create personalized advertising content that adapts to the interests and browsing habits of potential customers?",
      "How might we deploy AI to analyze trends and predict which types of board games might become popular?",
      "How might we utilize AI-generated content to enhance our presence on social media and other digital platforms?",
    ],
  },
  {
    cardID: 2,
    cardTitle: "Step 2: Consideration",
    imageUrl: JourneyStep2,
    cardSubTitle: "Imagine the Possibilities!",
    cardDescription:
      "Once aware, consumers begin to consider whether they might enjoy the game. They seek more information to decide if it fits their interests, budget, and the preferences of their playing group. They may read reviews, watch gameplay videos, or seek recommendations from trusted sources like friends or community forums.",
    HMW: [
      "How might we use generative AI to customize product presentations or game demonstrations to individual consumer preferences?",
      "How might we employ AI to gather and analyze consumer feedback from various online forums to better understand their considerations?",
      "How might we use AI to simulate different game scenarios or experiences for potential buyers?",
    ],
  },
  {
    cardID: 3,
    cardTitle: "Step 3: Purchase",
    imageUrl: JourneyStep3,
    cardSubTitle: "Make It Yours!",
    cardDescription:
      "This step involves the actual purchase of the board game. Consumers decide where to buy the game from—whether online, in a specialty store, or from a big box retailer—based on price, availability, and convenience.",
    HMW: [
      "How might we use generative AI to optimize our online store’s recommendations and user experience based on user behavior and preferences?",
      "How might we integrate AI to provide real-time assistance or chatbots that help answer buyer questions during the purchasing process?",
      "How might we employ AI to analyze purchasing patterns and optimize inventory and pricing strategies?",
    ],
  },
  {
    cardID: 4,
    cardTitle: "Step 4: Learning",
    imageUrl: JourneyStep4,
    cardSubTitle: "Learn & Explain!",
    cardDescription:
      "After purchasing, the next step is learning how to play the game. This involves reading the rulebook, watching tutorial videos, or even participating in demo games at board game cafes or conventions. Effective, easy-to-understand rules can greatly enhance the experience.",
    HMW: [
      "How might we use generative AI to create interactive tutorials that adapt to the learning pace and style of each player?",
      "How might we develop AI-driven apps or tools that assist in game setup and rule explanation?",
      "How might we utilize AI to translate and localize game instructions into multiple languages efficiently?",
    ],
  },
  {
    cardID: 5,
    cardTitle: "Step 5: Playing",
    imageUrl: JourneyStep5,
    cardSubTitle: "Play, Enjoy, Repeat!",
    cardDescription:
      "This is the core experience where the game is played with friends, family, or other enthusiasts. The enjoyment, replayability, and engagement during the game play are crucial in determining the game’s long-term success.",
    HMW: [
      "How might we use generative AI to enhance gameplay with dynamic events or challenges based on the players’ progress and preferences?",
      "How might we develop AI companions or virtual players that can participate in the game to enhance solo play or fill in for missing players?",
      "How might we employ AI to analyze game sessions and provide suggestions for improved strategies or balanced gameplay?",
    ],
  },
  {
    cardID: 6,
    cardTitle: "Step 6: Improving",
    imageUrl: JourneyStep6,
    cardSubTitle: "Sharpen Your Skills!",
    cardDescription:
      "As players become more familiar with the game, they may seek strategies to improve their play. This can involve deeper engagement with the community, watching strategy videos, reading forums, or playing the game repeatedly to develop skills.",
    HMW: [
      "How might we use generative AI to offer personalized coaching or feedback to players wanting to improve their skills?",
      "How might we develop AI systems that can model complex game strategies and provide training scenarios?",
      "How might we use AI to create a community platform where players can share strategies and AI analyzes and suggests improvements?",
    ],
  },
  {
    cardID: 7,
    cardTitle: "Step 7: Expanding",
    imageUrl: JourneyStep7,
    cardSubTitle: "Expand Your Horizons!",
    cardDescription:
      "For many games, especially those designed with modularity or expansion in mind, this stage involves buying expansions or additional content that enhances the original game. This can include new characters, maps, missions, or mechanics that renew interest and extend the gameplay.",
    HMW: [
      "How might we use generative AI to design new game expansions or variations based on player feedback and popular trends?",
      "How might we use AI to test and refine new game components before they are released?",
      "How might we utilize AI to predict which expansions or additional content will be most appealing to different segments of our audience?",
    ],
  },
  {
    cardID: 8,
    cardTitle: "Step 8: Endorsing",
    imageUrl: JourneyStep8,
    cardSubTitle: "Spread the Word!",
    cardDescription:
      "The final stage where satisfied players become advocates for the game. They might write positive reviews, recommend the game to potential new players, and influence purchases through their endorsement. This powerful form of marketing can significantly boost a game's visibility and sales.",
    HMW: [
      "How might we use generative AI to identify key influencers within the community who might act as brand ambassadors?",
      "How might we employ AI tools to help satisfied customers create and spread testimonials and reviews?",
      "How might we use AI to track the impact of endorsements and optimize engagement strategies accordingly?",
    ],
  },
];

export { customerJourneyIntro, customerJourney };
