import React, { useState } from "react";
import { Button } from "react-bootstrap";

// import css style sheets
import "./BBKnowledgeBaseCompanySKU.css";

export default function BBKnowledgeBaseAiCapabilityItem({
  chosenKnowledgeBaseItem,
}) {
  const [visibleDeepDiveContent, setVisibleDeepDiveContent] =
    useState("Technology Recap");
  return (
    <div className='BlackboxCIKnowledgeBaseItem'>
      <p>{chosenKnowledgeBaseItem.cardTitle}</p>
      <h1>{chosenKnowledgeBaseItem.cardSubTitle}</h1>
      <div className='BlackboxCIKnowledgeBaseItem-imageAndOptionButtonRow'>
        <div className='BlackboxCIKnowledgeBaseItem-imageContainer'>
          <img
            src={chosenKnowledgeBaseItem.imageUrl}
            alt={chosenKnowledgeBaseItem.cardSubTitle}
          />
        </div>
        <div className='BlackboxCIKnowledgeBaseItem-buttonContainer'>
          <Button
            className={
              visibleDeepDiveContent === "Technology Recap" ? "active" : ""
            }
            onClick={() => setVisibleDeepDiveContent("Technology Recap")}
          >
            Technology Recap
          </Button>
          <Button
            className={
              visibleDeepDiveContent === "Technology Tools" ? "active" : ""
            }
            onClick={() => setVisibleDeepDiveContent("Technology Tools")}
          >
            Technology Tools
          </Button>
          <Button
            className={
              visibleDeepDiveContent === "Technology Quiz" ? "active" : ""
            }
            onClick={() => setVisibleDeepDiveContent("Technology Quiz")}
          >
            Technology Quiz
          </Button>
          <Button
            className={
              visibleDeepDiveContent === "Technology News" ? "active" : ""
            }
            onClick={() => setVisibleDeepDiveContent("Technology News")}
          >
            Technology News
          </Button>
        </div>
      </div>
      <div className='targetCustomersCards'>
        {chosenKnowledgeBaseItem.deepDiveTopics.map((item, index) => (
          <div>
            {visibleDeepDiveContent === item.topic ? (
              <div className='targetCustomerCard' key={index}>
                <h1>{item.topic}</h1>
                {item.subTopics.map((subTopic, index) => (
                  <p key={index}>{subTopic}</p>
                ))}
                <div className='addToDeckPlusButton'>+</div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
