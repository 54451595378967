// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBvZC31OCHrMfxWJlRR4eEgRGMRhCEh7O4",
  authDomain: "howtofly-ai.firebaseapp.com",
  projectId: "howtofly-ai",
  storageBucket: "howtofly-ai.appspot.com",
  messagingSenderId: "587863637542",
  appId: "1:587863637542:web:c9d363212b893308b9b0e7",
  measurementId: "G-ZM2PMQ2MQ9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, analytics };
