import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import DonutChart from "../components/DonutChart.js";
import "./ReferenceCaseFramework.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MasonryLayout from "../components/MasonryLayout.js";
import {
  faCheck,
  faBullseye,
  faLayerGroup,
  faPlay,
  faInfoCircle,
  faAngleDown,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import TiltIcon from "../assets/TiltIcon.png";
import InspirationIcon from "../assets/InspirationIcon.png";
import ValidationIcon from "../assets/ValidationIcon.png";
import SearchIcon from "../assets/SearchIcon.png";
import PlanIcon from "../assets/PlanIcon.png";
import TargetIcon from "../assets/TargetIcon.png";
import TeamBrainstormIcon from "../assets/TeamBrainstormIcon.png";
import OverviewAILandscape from "../assets/OverviewAILandscape.png";
import BritvicRetailerProfiles from "../assets/BritvicRetailerProfiles.png";
import OpenAIGPT4 from "../assets/OpenAIGPT4.png";
import LangChain from "../assets/LangChain.png";

const ReferenceCaseFramework = ({
  companyNameData,
  challengeDefinitionData,
  evaluationCriteriaData,
  touchpointsData,
  genAiUseCasesData,
  quizData,
  userValidationResultsData,
  pocFeaturesData,
}) => {
  const [company, setCompany] = useState(companyNameData);
  const [cardInFront, setCardInFront] = useState(null);
  const [flightPlanSteps1to3, setFlightPlanSteps1to3] = useState([
    {
      stepNumber: "1.",
      stepTitle: "Challenge Definition",
      stepDescription: `First, we set a clear project end-goal to assure the right focus throughout the entire project. Additionally, clear evaluation criteria are defined to prioritize the identified generative AI Use Cases.`,
    },
    {
      stepNumber: "2.",
      stepTitle: "Market Research",
      stepDescription: `Then, internal data is gathered and analyzed to avoid unnecessary double work. After an initial analysis, existing knowledge gaps are identified and closed through additional web research and expert interviews.`,
    },
    {
      stepNumber: "3.",
      stepTitle: "Inspiration Creation",
      stepDescription: `Once research is done, all collected data is translated into inspiring videos, personas, consumer journeys, market profiles, technology overviews, interactive quizzes, and custom LLMs capable of informing and inspiring the ideation target audience.`,
    },
  ]);
  const [flightPlanSteps4to6, setFlightPlanSteps4to6] = useState([
    {
      stepNumber: "4.",
      stepTitle: "Online Ideation",
      stepDescription: `Then, a 3-week online brainstorm is launched which engages a wide range of pre-selected colleagues - each with their unique set of expertise across ${company}’s value chain - resulting in a relevant set of ideas, capable of the tackling the predefined challenge.`,
    },
    {
      stepNumber: "5.",
      stepTitle: "1-Day Design Sprint",
      stepDescription: `During a focused 1-day design sprint, 
      the number of ideas gets further extended and later reduced to the most relevant concepts, each with a clear plan to turn these concepts into a proof-of-concept.`,
    },
    {
      stepNumber: "6.",
      stepTitle: "POC Development & Testing",
      stepDescription: `After the 1-day design sprint, the created proof-of-concept plans get transformed into tangible proof-of-concepts of goldilocks quality, which can be tested with the relevant target users.`,
    },
  ]);
  const [challengeDefinition, setChallengeDefinition] = useState(
    challengeDefinitionData
  );
  const [evaluationCriteria, setEvaluationCriteria] = useState(
    evaluationCriteriaData
  );
  const [quiz, setQuiz] = useState(quizData);
  const [activeStage, setActiveStage] = useState("Awareness");
  const [journeyTouchpoints, setJourneyTouchpoints] = useState(touchpointsData);
  const [genAiUseCases, setGenAiUseCases] = useState(genAiUseCasesData);
  const [pocFeatures, setPocFeatures] = useState(pocFeaturesData);
  const [userValidationResults, setUserValidationResults] = useState(
    userValidationResultsData
  );
  const [touchpointDescriptionVisible, setTouchpointDescriptionVisible] =
    useState(null);
  const handleTouchpointMoreInfo = (index) => {
    return () => {
      if (touchpointDescriptionVisible === index) {
        setTouchpointDescriptionVisible(null);
      } else {
        setTouchpointDescriptionVisible(index);
      }
    };
  };
  const handleClick = (stage) => {
    setActiveStage(stage);
  };
  return (
    <div className='referenceCaseContainer'>
      <div className='desktopView'>
        <div className='referenceCaseFlightPlan'>
          <div className='referenceCaseFlightPlan-titleBlock'>
            <div className='referenceCaseFlightPlan-titleBlock-content'>
              <h1>
                {company}'s
                <br />
                GenAI Flight Plan
              </h1>
              <p>
                How to make <span style={{ color: "#F6C6AD" }}>{company}</span>{" "}
                fly with Generative AI?
                <br />
                <br /> Let's walk you through our step-wise approach and
                showcase how we can help you pick the brain of your entire
                organization and identify the most relevant use cases to augment{" "}
                {company}'s customer experiences and/or optimize {company}'s
                internal workflows with Generative AI.
              </p>
              <div className='titleBlockLineContainer'>
                <div className='horizontalLine'></div>
              </div>
            </div>
          </div>
          <div className='referenceCaseFlightPlan-steps step1to3'>
            <div className='verticalDottedLine step1to3 line1'></div>
            <div className='horizontalDottedLine step1to3 line2'></div>
            <div className='verticalDottedLine step1to3 line3'></div>
            <div className='horizontalDottedLine step1to3 line4'></div>
            <div className='verticalDottedLine step1to3 line5'></div>
            {flightPlanSteps1to3.map((step, index) => (
              <div
                className='referenceCaseFlightPlan-steps-stepContainer'
                key={index}
              >
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                  {step.stepNumber}
                </div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                  <h5>{step.stepTitle}</h5>
                  <p>{step.stepDescription}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='referenceCaseFlightPlan-steps step4to6'>
            <div className='horizontalDottedLine step1to3 line6'></div>
            <div className='verticalDottedLine step1to3 line7'></div>
            {flightPlanSteps4to6.map((step, index) => (
              <div
                className='referenceCaseFlightPlan-steps-stepContainer'
                key={index}
              >
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepNumber'>
                  {step.stepNumber}
                </div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepDeviderLine'></div>
                <div className='referenceCaseFlightPlan-steps-stepContainer-stepContent'>
                  <h5>{step.stepTitle}</h5>
                  <p>{step.stepDescription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='referenceCaseStepDeepDive challenge'>
          <div className='referenceCaseStepDeepDive-topDashedLine'></div>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>1.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>CHALLENGE DEFINITION</h5>
                <p>
                  Below you can find some fictional examples of potential
                  challenge definitions for {company}. These challenge
                  definition are{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as they were automatically generated via an automated web
                  search and a generative AI LLM. During real projects,
                  challenge definitions will always be augmented with {company}
                  ’s internal expertise to assure 100% relevance.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={TargetIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content'>
                <h5
                  style={{
                    color: "rgb(24,24,24)",
                    textAlign: "left",
                    position: "relative",
                    fontSize: "22px",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(24,24,24)",
                      textAlign: "left",
                      position: "relative",
                      zIndex: "2",
                    }}
                  >
                    {challengeDefinition.focusAreas
                      ? challengeDefinition.focusAreas.length
                      : ""}{" "}
                    Potential Challenges
                  </span>
                  <div className='underlineMarker'></div>
                </h5>

                <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-cardRow'>
                  {challengeDefinition.focusAreas.map((item, index) => (
                    <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-cardRow-card'>
                      <div
                        className='imageContainer'
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          background: `url(${item.imageUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          width: "96%",
                          aspectRatio: "1 / 1",
                          marginBottom: "15px",
                        }}
                      ></div>
                      <div className='textContainer'>
                        <h5>
                          {index + 1}. {item.title}
                        </h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={TiltIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content'>
                <p>
                  Ideally, we aim for the identified concepts to hold the
                  potential to deliver a triple win:
                </p>
                <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-bulletList'>
                  {evaluationCriteria.map((item, index) => (
                    <div
                      className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-bulletList-bulletPoint'
                      key={index}
                    >
                      <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-bulletList-bulletPoint-number'>
                        {index + 1}
                      </div>
                      <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-bulletList-bulletPoint-text'>
                        <p>
                          <b>{item.title}:</b> {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='referenceCaseStepDeepDive marketResearch'>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>2.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>MARKET RESEARCH</h5>
                <p>
                  Below you can find two fictional{" "}
                  <span style={{ color: "#F6C6AD" }}>
                    examples of some potential research
                  </span>{" "}
                  conducted related to the above challenge.These examples are{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as they were automatically generated via an automated web
                  search and a generative AI LLM. During real projects, research
                  will always be augmented with HowToFly’s manual research and{" "}
                  {company}’s internal documents & expertise to assure the best
                  possible outcome.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={SearchIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content marketResearch'>
                {/*<div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard card1 ${
                    cardInFront === "SWOT" ? "front" : ""
                  }`}
                  onClick={() => setCardInFront("SWOT")}
                >
                  <h5>
                    SWOT Analysis{" "}
                    <span style={{ fontWeight: "400", fontStyle: "italic" }}>
                      (Purely illustrative as 100% AI generated without data
                      curation)
                    </span>
                  </h5>
                  <p>
                    Below a SWOT analysis for britvic, focusing on the
                    implementation of Generative AI (GenAI)
                  </p>
                  {swotAnalysis && (
                    <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-swotContainer'>
                      {swotAnalysis.map((item, i) => (
                        <div
                          className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-swotContainer-quadrantCard'
                          key={i}
                        >
                          <div className='swotTitle'>{item.title}</div>
                          <ul className='swotBulletPointContainer'>
                            {item.points.map((point, index) => (
                              <li key={index}>
                                <b>{point.title}:</b> {point.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  )}
                            </div>*/}
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-card inspirationCreation card20 ${
                    cardInFront === "journey" ? "front" : ""
                  }`}
                  onClick={() => setCardInFront("journey")}
                  style={{ aspectRatio: "16 / 9" }}
                >
                  <div className='userJourneyDonut'>
                    <h4>
                      Customer Journey
                      <br />
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "400",
                          position: "relative",
                          top: "-10px",
                        }}
                      >
                        (Shopper, Consumer, ...)
                      </span>
                    </h4>
                    <DonutChart
                      journeyTouchpoints={journeyTouchpoints}
                      selectedStage={activeStage}
                      setSelectedStage={setActiveStage}
                    />
                  </div>
                  <div className='userJourneyTouchpoints'>
                    <h5>{activeStage} touchpoints</h5>
                    {journeyTouchpoints.map((stage, index) => (
                      <MasonryLayout>
                        {stage.stage === activeStage &&
                          stage.touchpoints.map((touchpoint, i) => (
                            <div className='touchpoint'>
                              <div className='touchpointContent'>
                                <div className='touchpointTitle'>
                                  <b>{touchpoint.title}</b>
                                </div>
                                <div className='touchpointDescription'>
                                  <span>{touchpoint.description}</span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </MasonryLayout>
                    ))}
                  </div>
                </div>
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard card2 ${
                    cardInFront === "landscape" ? "front" : ""
                  }`}
                  style={{ aspectRatio: "16 / 9" }}
                  onClick={() => setCardInFront("landscape")}
                >
                  <img src={OverviewAILandscape} style={{ width: "90%" }} />
                </div>
                <div className='remarkOfOtherExamples'>
                  * These are just two research examples to optimally prepare
                  the ideation. Many more research is typically done during
                  project preparation such as market trend-analysis, competitor
                  analysis, customer journey mapping, jobs-to-be done analysis,
                  value chain mapping, research papers, expert interviews, and
                  many more.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='referenceCaseStepDeepDive inspirationCreation'>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>3.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>INSPIRATION CREATION</h5>
                <p>
                  Below you can find two fictional examples of some potential{" "}
                  <span style={{ color: "#F6C6AD" }}>inspiring content</span>{" "}
                  related to the above challenge.These examples are{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as they were automatically generated via an automated web
                  search and a generative AI LLM. During real projects, research
                  will always be augmented with HowToFly’s manual expertise to
                  provide ideation participants with the most inspiring content
                  in the most engaging formats.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={InspirationIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content marketResearch'>
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard card1 ${
                    cardInFront === "quizzes" ? "front" : ""
                  }`}
                  style={{ aspectRatio: "16 / 9" }}
                  onClick={() => setCardInFront("quizzes")}
                >
                  <h5 style={{ fontFamily: "Poppins" }}>
                    EXPLAINER VIDEO & INTERACTIVE QUIZZES{" "}
                  </h5>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-contentContainer'>
                    <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-videoContainer'>
                      <h1
                        style={{
                          color: "#F6C6AD",
                          textAlign: "left",
                          position: "absolute",
                          top: "20px",
                          left: "30px",
                          zIndex: "1",
                          fontSize: "22px",
                        }}
                      >
                        Retrieval
                        <br />
                        Augmented
                        <br />
                        Generation (RAG)
                      </h1>
                      <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-videoContainer-playButton'>
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                      <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-videoContainer-video'></div>
                    </div>
                    <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-QuizContainer'>
                      <div
                        className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-QuizContainer-question'
                        style={{ fontSize: "11px" }}
                      >
                        How could {company} potentially leverage RAG in their
                        product development process?
                      </div>
                      {quiz.answers.map((item) => (
                        <div
                          className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-ContentContainer-QuizContainer-answer ${
                            item.correct ? "correctAnswer" : ""
                          }`}
                          style={{ fontSize: "11px" }}
                        >
                          {item.answer}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-explenationContainer'>
                    {quiz.correctAnswerDescription}
                  </div>
                </div>
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-card inspirationCreation card2 ${
                    cardInFront === "journey" ? "front" : ""
                  }`}
                  onClick={() => setCardInFront("journey")}
                  style={{
                    background: `url(${BritvicRetailerProfiles})`,
                    backgroundSize: "contain",
                    aspectRatio: "16 / 9",
                  }}
                ></div>
                <div className='remarkOfOtherExamples'>
                  * These are just two examples of inspiring content and formats
                  to inform and inspire participants. Many more formats are
                  typically used in our projects such as inspiring videos,
                  personas, market profiles, technology overviews, and
                  AI-chatbots, and many more.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='referenceCaseStepDeepDive onlineIdeation'>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>4.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>ONLINE IDEATION</h5>
                <p>
                  Below you can find a fictional glimpse of some potential use
                  case that could result from an online ideation. These examples
                  are{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as they were automatically generated via an automated web
                  search and a non-custom LLM. During real projects, generated
                  use cases will always be augmented with the expertise and
                  experience of your entire workforce and a custom LLM,
                  resulting in more relevant results.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={TeamBrainstormIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content onlineIdeation'>
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-deepDiveCard card3 ${
                    cardInFront === "ideation" ? "front" : ""
                  }`}
                  onClick={() => setCardInFront("ideation")}
                >
                  <h5 style={{ fontFamily: "Poppins" }}>Online Ideation </h5>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-onlineIdeation-contentContainer'>
                    <MasonryLayout columnProp={3}>
                      {genAiUseCases.map((item, index) => (
                        <div className='ideationCard'>
                          <div className='ideationCard-info'>
                            <div className='ideationCard-info-creator'>
                              {Math.floor(Math.random() * 80) + 20}XP
                            </div>
                            <div className='ideationCard-info-date'>
                              2 days ago
                            </div>
                          </div>
                          <div className='ideationCard-image'>
                            <img
                              src={item.imageUrl}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className='ideationCard-title'>
                            <h5>{item.title}</h5>
                          </div>
                          <div className='ideationCard-description'>
                            <p style={{ marginBottom: "30px" }}>
                              {item.description}
                            </p>
                          </div>
                          <div className='ideationCard-scoringRow'>
                            <div className='ideationCard-scoringRow-headerRow'>
                              <div className='ideationCard-scoringRow-headerRow-title'>
                                Impact Votes (
                                {index === 0
                                  ? 37
                                  : Math.floor(Math.random() * 40)}
                                )
                              </div>
                              <div className='ideationCard-scoringRow-headerRow-caret'>
                                <FontAwesomeIcon
                                  icon={
                                    index === 0 ? faAngleDown : faAngleRight
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={
                                index === 0
                                  ? "ideationCard-scoringButtons scoreActive"
                                  : "ideationCard-scoringButtons"
                              }
                            >
                              <div className='ideationCard-scoringButtons-row'>
                                <div className='ideationCard-scoringButtons-row-title'>
                                  Turnover
                                </div>
                                <div className='ideationCard-scoringButtons-row-buttons'>
                                  {["😔 6", "😐 10", "😊 23"].map(
                                    (score, j) => (
                                      <div
                                        className={
                                          j === 2
                                            ? "scoreButton green"
                                            : "scoreButton"
                                        }
                                      >
                                        {score}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className='ideationCard-scoringButtons-row'>
                                <div className='ideationCard-scoringButtons-row-title'>
                                  Margin
                                </div>
                                <div className='ideationCard-scoringButtons-row-buttons'>
                                  {["😔 17", "😐 14", "😊 6"].map(
                                    (score, j) => (
                                      <div
                                        className={
                                          j === 1
                                            ? "scoreButton orange"
                                            : "scoreButton"
                                        }
                                      >
                                        {score}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='ideationCard-scoringRow'>
                            <div className='ideationCard-scoringRow-headerRow'>
                              <div className='ideationCard-scoringRow-headerRow-title'>
                                Arguments Pro & Con (
                                {Math.floor(Math.random() * 20)})
                              </div>
                              <div className='ideationCard-scoringRow-headerRow-caret'>
                                <FontAwesomeIcon icon={faAngleRight} />
                              </div>
                            </div>
                          </div>
                          <div className='ideationCard-scoringRow'>
                            <div className='ideationCard-scoringRow-headerRow'>
                              <div className='ideationCard-scoringRow-headerRow-title'>
                                Insired by ({Math.floor(Math.random() * 5)})
                              </div>
                              <div className='ideationCard-scoringRow-headerRow-caret'>
                                <FontAwesomeIcon icon={faAngleRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </MasonryLayout>
                  </div>
                </div>
                <div
                  className={`referenceCaseStepDeepDive-bodyBlock-sectionRow-content-deepDiveCard card4 ${
                    cardInFront === "useCases" ? "front" : ""
                  }`}
                  onClick={() => setCardInFront("useCases")}
                >
                  <h5 style={{ fontFamily: "Poppins", color: "rgb(24,24,24)" }}>
                    GenAI Use Cases{" "}
                  </h5>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-contentContainer'>
                    <div className='useCaseContainer'>
                      <div className='useCaseContainer-useCase title'>
                        <div className='useCaseContainer-useCase-number title'></div>
                        <div className='useCaseContainer-useCase-description title'></div>
                        <div className='useCaseContainer-useCase-desirability title'>
                          Impact
                        </div>
                        <div className='useCaseContainer-useCase-feasibility title'>
                          TRL
                        </div>
                      </div>
                      {genAiUseCases.map((item, index) => (
                        <div className='useCaseContainer-useCase' key={index}>
                          <div className='useCaseContainer-useCase-number'>
                            {index + 1}
                          </div>
                          <div className='useCaseContainer-useCase-description'>
                            <p>
                              <b>{item.title}:</b> {item.description}
                            </p>
                          </div>
                          <div className='useCaseContainer-useCase-desirability'>
                            {item.desirability}
                          </div>
                          <div className='useCaseContainer-useCase-feasibility'>
                            {item.feasibility}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='matrixContainer'>
                      <div className='matrixContainer-background'></div>
                      <div className='matrixContainer-feasibilityTitle'>
                        Feasibility
                      </div>
                      <div className='matrixContainer-desirabilityTitle'>
                        Desirability
                      </div>
                      {genAiUseCases.map((item, index) => (
                        <div
                          className='matrixContainer-matrixItem'
                          style={{
                            position: "absolute",
                            bottom: `${item.desirability * 10}%`,
                            left: `${item.feasibility * 10}%`,
                          }}
                          key={index}
                        >
                          {index + 1}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='referenceCaseStepDeepDive designSprint'>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>5.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>1-DAY DESIGN SPRINT</h5>
                <p>
                  Below you can find a fictional glimpse of a Proof-Of-Concept
                  specification sheet, created during a 1-day design sprint.
                  This example is{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as it was automatically generated via an automated web search
                  and a generative AI LLM. During real projects, the POC specs
                  will always be augmented with the expertise and experience of
                  HowToFly, and some pre-selected employees within {company},
                  resulting in more relevant and concrete POC specs.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={PlanIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content onlineIdeation'>
                <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-deepDiveCard card3'>
                  <h5 style={{ fontFamily: "Poppins", marginBottom: "5px" }}>
                    PROOF-OF-CONCEPT SPECIFICATIONS{" "}
                  </h5>
                  <h5
                    style={{
                      fontFamily: "Poppins",
                      marginTop: "0px",
                      color: "#F6C6AD",
                      position: "relative",
                      top: "-5px",
                    }}
                  >
                    {genAiUseCases[1].title}
                  </h5>
                  <p
                    className='introTextPOC'
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      marginRight: "10px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {genAiUseCases[1].description}
                  </p>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-marketResearchCard-contentContainer'>
                    <div className='featureContainer'>
                      <div className='featureContainer-feature title'>
                        <div className='featureContainer-feature-description title'>
                          Features
                        </div>
                        <div className='featureContainer-feature-prioritization title'></div>
                        <div className='featureContainer-feature-techStack title'>
                          Technology Stack
                        </div>
                      </div>
                      {pocFeatures.map((item, index) => (
                        <div className='featureContainer-feature' key={index}>
                          <div className='featureContainer-feature-description'>
                            <p>
                              <b>{item.title}:</b> {item.description}
                            </p>
                          </div>
                          <div className='featureContainer-feature-prioritization'>
                            {item.prioritization}
                          </div>
                          <div className='featureContainer-feature-techStack'>
                            {item.techStack.map((image, imageIndex) => (
                              <img src={image} key={imageIndex} />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='referenceCaseStepDeepDive PocTesting'>
          <div className='referenceCaseStepDeepDive-headerBlock'>
            <div className='referenceCaseStepDeepDive-headerBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-verticalDevider'></div>
            <div className='referenceCaseStepDeepDive-headerBlock-contentContainer'>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-number'>
                <h1>6.</h1>
              </div>
              <div className='referenceCaseStepDeepDive-headerBlock-contentContainer-content'>
                <h5>PROOF-OF-CONCEPT TESTING</h5>
                <p>
                  Below you can find a fictional glimpse of a validation report
                  for a potential use case that was generated during the online
                  brainstorm phase and turned into a proof-of-concept by the
                  HowToFly team. This example is{" "}
                  <span style={{ color: "#F6C6AD" }}>purely illustrative</span>{" "}
                  as it was automatically generated via an automated web search
                  and our generative AI LLM.
                </p>
              </div>
            </div>
          </div>
          <div className='referenceCaseStepDeepDive-bodyBlock'>
            <div className='referenceCaseStepDeepDive-bodyBlock-verticalDashedLine'></div>
            <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow'>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-icon'>
                <img src={ValidationIcon} />
              </div>
              <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content onlineIdeation'>
                <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-deepDiveCard card6'>
                  <h5 style={{ fontFamily: "Poppins", marginBottom: "5px" }}>
                    PROOF-OF-CONCEPT TESTING{" "}
                  </h5>
                  <h5
                    style={{
                      fontFamily: "Poppins",
                      marginTop: "0px",
                      color: "#F6C6AD",
                      position: "relative",
                      top: "-5px",
                    }}
                  >
                    {genAiUseCases[1].title}
                  </h5>
                  <p
                    className='introTextPOC'
                    style={{
                      color: "white",
                      marginLeft: "10px",
                      marginRight: "10px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {userValidationResults.introText}
                  </p>
                  <div className='referenceCaseStepDeepDive-bodyBlock-sectionRow-content-deepDiveCard-contentContainer'>
                    <MasonryLayout columnProp={2} gap={0}>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile picture'>
                          <img
                            style={{ width: "100%" }}
                            src={userValidationResults.imageUrl}
                          />
                        </div>
                      </div>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile methodology'>
                          <h5>{userValidationResults.methodologyTitle}</h5>
                          <p>{userValidationResults.methodologyDescription}</p>
                        </div>
                      </div>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile engagement'>
                          <h5>{userValidationResults.participantsTitle}</h5>
                          <ul>
                            {userValidationResults.participantsDescription.map(
                              (listItem, listIndex) => (
                                <li key={listIndex}>{listItem}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile participants'>
                          <h5>{userValidationResults.engagementTitle}</h5>
                          <ul>
                            {userValidationResults.engagementDescription.map(
                              (listItem, listIndex) => (
                                <li key={listIndex}>{listItem}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile participants'>
                          <h5>{userValidationResults.satisfactionTitle}</h5>
                          <ul>
                            {userValidationResults.satisfactionDescription.map(
                              (listItem, listIndex) => (
                                <li key={listIndex}>{listItem}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className='testingSetUpContainer-tile'>
                        <div className='testingSetUpContainer-tile-subtile recommendations'>
                          <h5>{userValidationResults.recommendationTitle}</h5>
                          <p>{userValidationResults.recommendationIntro}</p>
                          <ol>
                            {userValidationResults.recommendationDescription.map(
                              (listItem, listIndex) => (
                                <li key={listIndex}>{listItem}</li>
                              )
                            )}
                          </ol>
                          <p>
                            <p>{userValidationResults.recommendationOutro}</p>
                          </p>
                        </div>
                      </div>
                    </MasonryLayout>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mobileView' style={{ background: "black" }}>
        <div>Case only visible on desktop or laptop.</div>
      </div>
    </div>
  );
};

export default ReferenceCaseFramework;
